export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: GraphQL_Date;
  /** Notification emails scalar */
  NotificationEmailsScalar: GraphQL_NotificationEmailsScalar;
};

export enum AccessTokenError {
  EmailDoesNotExist = 'EmailDoesNotExist',
  EmailNotSent = 'EmailNotSent',
  EmailOrPasswordNotSent = 'EmailOrPasswordNotSent',
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  ErrorOnOrganizationCreation = 'ErrorOnOrganizationCreation',
  GoogleTokenInvalid = 'GoogleTokenInvalid',
  InvalidCharityNumber = 'InvalidCharityNumber',
  InvalidOrganizationName = 'InvalidOrganizationName',
  NoDonorAllowedOnMobileApp = 'NoDonorAllowedOnMobileApp',
  PasswordDoNotMatch = 'PasswordDoNotMatch',
  ResetTokenDoNotMatch = 'ResetTokenDoNotMatch',
  StytchOrganizationIdNotFound = 'StytchOrganizationIdNotFound',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  TwoFactorCodeDoesNotMatch = 'TwoFactorCodeDoesNotMatch',
  TwoFactorCodeExpired = 'TwoFactorCodeExpired',
  UnauthorizedToken = 'UnauthorizedToken',
  UrlInvalid = 'UrlInvalid',
  UserAlreadyExistsWithOrganization = 'UserAlreadyExistsWithOrganization',
  UserNotFoundWithOrganization = 'UserNotFoundWithOrganization'
}

export type AccessTokenObject = {
  __typename?: 'AccessTokenObject';
  accessToken?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  error?: Maybe<AccessTokenError>;
  id?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  redirectUrl?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoles>;
  shouldAuthenticateBySms?: Maybe<Scalars['Boolean']>;
  shouldAuthenticateWithTwoFactor?: Maybe<Scalars['Boolean']>;
  shouldRedirectToMFAConfig?: Maybe<Scalars['Boolean']>;
  stytchVerdict?: Maybe<Scalars['String']>;
};

export type AccessTokenObject2cvVeuxy = {
  __typename?: 'AccessTokenObject2cvVeuxy';
  error?: Maybe<AccessTokenObject2cvVeuxyErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject2cvVeuxyErrorObject = {
  __typename?: 'AccessTokenObject2cvVeuxyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObject3Y8Wgxzu = {
  __typename?: 'AccessTokenObject3Y8Wgxzu';
  error?: Maybe<AccessTokenObject3Y8WgxzuErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject3Y8WgxzuErrorObject = {
  __typename?: 'AccessTokenObject3Y8WgxzuErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObject6O99q3S5 = {
  __typename?: 'AccessTokenObject6O99q3S5';
  error?: Maybe<AccessTokenObject6O99q3S5ErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObject6O99q3S5ErrorObject = {
  __typename?: 'AccessTokenObject6O99q3S5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectKzry1eZi = {
  __typename?: 'AccessTokenObjectKzry1eZI';
  error?: Maybe<AccessTokenObjectKzry1eZiErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectKzry1eZiErrorObject = {
  __typename?: 'AccessTokenObjectKzry1eZIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectY09Gbq75 = {
  __typename?: 'AccessTokenObjectY09Gbq75';
  error?: Maybe<AccessTokenObjectY09Gbq75ErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectY09Gbq75ErrorObject = {
  __typename?: 'AccessTokenObjectY09Gbq75ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectfnKAopFi = {
  __typename?: 'AccessTokenObjectfnKAopFi';
  error?: Maybe<AccessTokenObjectfnKAopFiErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectfnKAopFiErrorObject = {
  __typename?: 'AccessTokenObjectfnKAopFiErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenObjectk0IhZEil = {
  __typename?: 'AccessTokenObjectk0IhZEil';
  error?: Maybe<AccessTokenObjectk0IhZEilErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenObjectk0IhZEilErrorObject = {
  __typename?: 'AccessTokenObjectk0IhZEilErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccessTokenResponseObject = {
  __typename?: 'AccessTokenResponseObject';
  error?: Maybe<AccessTokenResponseObjectErrorObject>;
  object?: Maybe<AccessTokenObject>;
};

export type AccessTokenResponseObjectErrorObject = {
  __typename?: 'AccessTokenResponseObjectErrorObject';
  code?: Maybe<AccessTokenError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AccountBalancesObject = {
  __typename?: 'AccountBalancesObject';
  available?: Maybe<Array<BalanceObject>>;
  issuing?: Maybe<AccountBalancesObject>;
  pending?: Maybe<Array<BalanceObject>>;
};

export type AccountBalancesObjectgnwwa6Ey = {
  __typename?: 'AccountBalancesObjectgnwwa6Ey';
  error?: Maybe<AccountBalancesObjectgnwwa6EyErrorObject>;
  object?: Maybe<AccountBalancesObject>;
};

export type AccountBalancesObjectgnwwa6EyErrorObject = {
  __typename?: 'AccountBalancesObjectgnwwa6EyErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ActivateDonationFormInput = {
  donationFormId: Scalars['String'];
  isOnboarding: Scalars['Boolean'];
};

export type ActivateTicketingInput = {
  isOnboarding: Scalars['Boolean'];
  ticketingId: Scalars['String'];
};

export type AggregateRateFieldsObject = {
  __typename?: 'AggregateRateFieldsObject';
  commandId: Scalars['String'];
  rateFields: Array<RateFieldObjectWithCount>;
};

export type AggregateRateFieldsObject1BPcp4bo = {
  __typename?: 'AggregateRateFieldsObject1BPcp4bo';
  error?: Maybe<AggregateRateFieldsObject1BPcp4boErrorObject>;
  items?: Maybe<Array<AggregateRateFieldsObject>>;
};

export type AggregateRateFieldsObject1BPcp4boErrorObject = {
  __typename?: 'AggregateRateFieldsObject1BPcp4boErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AmplitudeVariantValue {
  A = 'A',
  B = 'B',
  C = 'C',
  CONTROL = 'CONTROL',
  OFF = 'OFF'
}

export type AnswerInput = {
  answer?: InputMaybe<Scalars['String']>;
  choiceIds?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  questionId: Scalars['String'];
  rateId?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
};

export type AnswerObject = {
  __typename?: 'AnswerObject';
  answer?: Maybe<Scalars['String']>;
  choiceIds?: Maybe<Array<Scalars['String']>>;
  choices?: Maybe<Array<ChoiceObject>>;
  commandId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  orderId?: Maybe<Scalars['String']>;
  productTicketId?: Maybe<Scalars['String']>;
  question?: Maybe<QuestionObject>;
  questionId: Scalars['String'];
  ticketId?: Maybe<Scalars['String']>;
};

export type AnswerObjectWrDao3sL = {
  __typename?: 'AnswerObjectWrDAO3sL';
  error?: Maybe<AnswerObjectWrDao3sLErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectWrDao3sLErrorObject = {
  __typename?: 'AnswerObjectWrDAO3sLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectliR5yogE = {
  __typename?: 'AnswerObjectliR5yogE';
  error?: Maybe<AnswerObjectliR5yogEErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectliR5yogEErrorObject = {
  __typename?: 'AnswerObjectliR5yogEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerObjectvmdIiP4v = {
  __typename?: 'AnswerObjectvmdIiP4v';
  error?: Maybe<AnswerObjectvmdIiP4vErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerObjectvmdIiP4vErrorObject = {
  __typename?: 'AnswerObjectvmdIiP4vErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseListObject = {
  __typename?: 'AnswerResponseListObject';
  error?: Maybe<AnswerResponseListObjectErrorObject>;
  items?: Maybe<Array<AnswerObject>>;
};

export type AnswerResponseListObjectErrorObject = {
  __typename?: 'AnswerResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type AnswerResponseObject = {
  __typename?: 'AnswerResponseObject';
  error?: Maybe<AnswerResponseObjectErrorObject>;
  object?: Maybe<AnswerObject>;
};

export type AnswerResponseObjectErrorObject = {
  __typename?: 'AnswerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ApplicationInitialStatusObject = {
  __typename?: 'ApplicationInitialStatusObject';
  firstForm?: Maybe<FirstFormObject>;
  hasActiveDonationForm: Scalars['Boolean'];
  hasActiveTicketing: Scalars['Boolean'];
  hasArchivedDonationForm: Scalars['Boolean'];
  hasArchivedTicketing: Scalars['Boolean'];
  hasDonationForm: Scalars['Boolean'];
  hasTicketing: Scalars['Boolean'];
  latestForm?: Maybe<FrontendFormObject>;
};

export type ApplicationInitialStatusResponseObject = {
  __typename?: 'ApplicationInitialStatusResponseObject';
  error?: Maybe<ApplicationInitialStatusResponseObjectErrorObject>;
  object?: Maybe<ApplicationInitialStatusObject>;
};

export type ApplicationInitialStatusResponseObjectErrorObject = {
  __typename?: 'ApplicationInitialStatusResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum AvailablePaymentCurrency {
  Cad = 'Cad',
  Usd = 'Usd'
}

export type BalanceObject = {
  __typename?: 'BalanceObject';
  amount: Scalars['Float'];
  currency: Scalars['String'];
};

export type BalanceTransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
};

export enum BalanceTransactionType {
  issuing_authorization_hold = 'issuing_authorization_hold',
  issuing_dispute = 'issuing_dispute',
  issuing_transaction = 'issuing_transaction',
  payment = 'payment',
  payment_refund = 'payment_refund',
  payout = 'payout',
  pending_topup = 'pending_topup',
  scheduled_payout = 'scheduled_payout',
  topup = 'topup'
}

export enum BankAccountStatus {
  Errored = 'Errored',
  Verified = 'Verified'
}

export type BidRateObject = {
  __typename?: 'BidRateObject';
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type BidRateObjectychau0pn = {
  __typename?: 'BidRateObjectychau0pn';
  error?: Maybe<BidRateObjectychau0pnErrorObject>;
  items?: Maybe<Array<BidRateObject>>;
};

export type BidRateObjectychau0pnErrorObject = {
  __typename?: 'BidRateObjectychau0pnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0q9qEZh1 = {
  __typename?: 'Boolean0q9qEZh1';
  error?: Maybe<Boolean0q9qEZh1ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0q9qEZh1ErrorObject = {
  __typename?: 'Boolean0q9qEZh1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean0sRyUq5m = {
  __typename?: 'Boolean0sRyUQ5m';
  error?: Maybe<Boolean0sRyUq5mErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean0sRyUq5mErrorObject = {
  __typename?: 'Boolean0sRyUQ5mErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean1YehSjhm = {
  __typename?: 'Boolean1YehSJHM';
  error?: Maybe<Boolean1YehSjhmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean1YehSjhmErrorObject = {
  __typename?: 'Boolean1YehSJHMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean2SkSe3Op = {
  __typename?: 'Boolean2SKSe3OP';
  error?: Maybe<Boolean2SkSe3OpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean2SkSe3OpErrorObject = {
  __typename?: 'Boolean2SKSe3OPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean3a6siEmo = {
  __typename?: 'Boolean3a6siEMO';
  error?: Maybe<Boolean3a6siEmoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean3a6siEmoErrorObject = {
  __typename?: 'Boolean3a6siEMOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean5bNBbnQm = {
  __typename?: 'Boolean5bNBbnQm';
  error?: Maybe<Boolean5bNBbnQmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean5bNBbnQmErrorObject = {
  __typename?: 'Boolean5bNBbnQmErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7As6d6d8 = {
  __typename?: 'Boolean7AS6d6d8';
  error?: Maybe<Boolean7As6d6d8ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7As6d6d8ErrorObject = {
  __typename?: 'Boolean7AS6d6d8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7E0VjIma = {
  __typename?: 'Boolean7E0VjIMA';
  error?: Maybe<Boolean7E0VjImaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7E0VjImaErrorObject = {
  __typename?: 'Boolean7E0VjIMAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7Exs2dD5 = {
  __typename?: 'Boolean7EXS2dD5';
  error?: Maybe<Boolean7Exs2dD5ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7Exs2dD5ErrorObject = {
  __typename?: 'Boolean7EXS2dD5ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean7iIn3qUo = {
  __typename?: 'Boolean7iIN3qUo';
  error?: Maybe<Boolean7iIn3qUoErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean7iIn3qUoErrorObject = {
  __typename?: 'Boolean7iIN3qUoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean9J3TtTi7 = {
  __typename?: 'Boolean9J3TTTi7';
  error?: Maybe<Boolean9J3TtTi7ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean9J3TtTi7ErrorObject = {
  __typename?: 'Boolean9J3TTTi7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Boolean9RWi3rlD = {
  __typename?: 'Boolean9RWi3rlD';
  error?: Maybe<Boolean9RWi3rlDErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Boolean9RWi3rlDErrorObject = {
  __typename?: 'Boolean9RWi3rlDErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanAnG7gEyA = {
  __typename?: 'BooleanAnG7gEyA';
  error?: Maybe<BooleanAnG7gEyAErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanAnG7gEyAErrorObject = {
  __typename?: 'BooleanAnG7gEyAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanBqmdIsZs = {
  __typename?: 'BooleanBQMDIsZs';
  error?: Maybe<BooleanBqmdIsZsErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanBqmdIsZsErrorObject = {
  __typename?: 'BooleanBQMDIsZsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanC85jyPwS = {
  __typename?: 'BooleanC85jyPwS';
  error?: Maybe<BooleanC85jyPwSErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanC85jyPwSErrorObject = {
  __typename?: 'BooleanC85jyPwSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanCElyvdzW = {
  __typename?: 'BooleanCElyvdzW';
  error?: Maybe<BooleanCElyvdzWErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanCElyvdzWErrorObject = {
  __typename?: 'BooleanCElyvdzWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanH0OxArof = {
  __typename?: 'BooleanH0OxArof';
  error?: Maybe<BooleanH0OxArofErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanH0OxArofErrorObject = {
  __typename?: 'BooleanH0OxArofErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanIsrV0Dzd = {
  __typename?: 'BooleanIsrV0Dzd';
  error?: Maybe<BooleanIsrV0DzdErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanIsrV0DzdErrorObject = {
  __typename?: 'BooleanIsrV0DzdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanLksxJsdk = {
  __typename?: 'BooleanLksxJsdk';
  error?: Maybe<BooleanLksxJsdkErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanLksxJsdkErrorObject = {
  __typename?: 'BooleanLksxJsdkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanLqXzvFi3 = {
  __typename?: 'BooleanLqXZVFi3';
  error?: Maybe<BooleanLqXzvFi3ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanLqXzvFi3ErrorObject = {
  __typename?: 'BooleanLqXZVFi3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanLxMut2Wl = {
  __typename?: 'BooleanLxMUT2Wl';
  error?: Maybe<BooleanLxMut2WlErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanLxMut2WlErrorObject = {
  __typename?: 'BooleanLxMUT2WlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanMc9iwb7l = {
  __typename?: 'BooleanMc9iwb7l';
  error?: Maybe<BooleanMc9iwb7lErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanMc9iwb7lErrorObject = {
  __typename?: 'BooleanMc9iwb7lErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanN0nRllVn = {
  __typename?: 'BooleanN0nRLLVn';
  error?: Maybe<BooleanN0nRllVnErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanN0nRllVnErrorObject = {
  __typename?: 'BooleanN0nRLLVnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanOr09PgbC = {
  __typename?: 'BooleanOR09PgbC';
  error?: Maybe<BooleanOr09PgbCErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanOr09PgbCErrorObject = {
  __typename?: 'BooleanOR09PgbCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanOiNxs87M = {
  __typename?: 'BooleanOiNxs87M';
  error?: Maybe<BooleanOiNxs87MErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanOiNxs87MErrorObject = {
  __typename?: 'BooleanOiNxs87MErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanPweUhzGw = {
  __typename?: 'BooleanPWEUhzGw';
  error?: Maybe<BooleanPweUhzGwErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanPweUhzGwErrorObject = {
  __typename?: 'BooleanPWEUhzGwErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanQlq05Cx9 = {
  __typename?: 'BooleanQlq05CX9';
  error?: Maybe<BooleanQlq05Cx9ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanQlq05Cx9ErrorObject = {
  __typename?: 'BooleanQlq05CX9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanResponseObject = {
  __typename?: 'BooleanResponseObject';
  error?: Maybe<BooleanResponseObjectErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanResponseObjectErrorObject = {
  __typename?: 'BooleanResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWn40sBxr = {
  __typename?: 'BooleanWN40sBxr';
  error?: Maybe<BooleanWn40sBxrErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWn40sBxrErrorObject = {
  __typename?: 'BooleanWN40sBxrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWo8FwD6o = {
  __typename?: 'BooleanWO8FwD6o';
  error?: Maybe<BooleanWo8FwD6oErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWo8FwD6oErrorObject = {
  __typename?: 'BooleanWO8FwD6oErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWsrq7bpi = {
  __typename?: 'BooleanWsrq7bpi';
  error?: Maybe<BooleanWsrq7bpiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWsrq7bpiErrorObject = {
  __typename?: 'BooleanWsrq7bpiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanWyNt1Pq1 = {
  __typename?: 'BooleanWyNt1Pq1';
  error?: Maybe<BooleanWyNt1Pq1ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanWyNt1Pq1ErrorObject = {
  __typename?: 'BooleanWyNt1Pq1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanXs6OvL2l = {
  __typename?: 'BooleanXS6OvL2l';
  error?: Maybe<BooleanXs6OvL2lErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanXs6OvL2lErrorObject = {
  __typename?: 'BooleanXS6OvL2lErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanXZlgU2iP = {
  __typename?: 'BooleanXZlgU2iP';
  error?: Maybe<BooleanXZlgU2iPErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanXZlgU2iPErrorObject = {
  __typename?: 'BooleanXZlgU2iPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanXxUsgHwH = {
  __typename?: 'BooleanXxUsgHwH';
  error?: Maybe<BooleanXxUsgHwHErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanXxUsgHwHErrorObject = {
  __typename?: 'BooleanXxUsgHwHErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanZy7wUJrY = {
  __typename?: 'BooleanZy7wUJrY';
  error?: Maybe<BooleanZy7wUJrYErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanZy7wUJrYErrorObject = {
  __typename?: 'BooleanZy7wUJrYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanc0uSnYmn = {
  __typename?: 'Booleanc0uSNYmn';
  error?: Maybe<Booleanc0uSnYmnErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanc0uSnYmnErrorObject = {
  __typename?: 'Booleanc0uSNYmnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleancbEvThE9 = {
  __typename?: 'BooleancbEvThE9';
  error?: Maybe<BooleancbEvThE9ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleancbEvThE9ErrorObject = {
  __typename?: 'BooleancbEvThE9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleand1E5onMb = {
  __typename?: 'Booleand1E5onMB';
  error?: Maybe<Booleand1E5onMbErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleand1E5onMbErrorObject = {
  __typename?: 'Booleand1E5onMBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleandS6SiFSc = {
  __typename?: 'BooleandS6SiFSc';
  error?: Maybe<BooleandS6SiFScErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleandS6SiFScErrorObject = {
  __typename?: 'BooleandS6SiFScErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanfVa5Os8F = {
  __typename?: 'BooleanfVa5OS8F';
  error?: Maybe<BooleanfVa5Os8FErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanfVa5Os8FErrorObject = {
  __typename?: 'BooleanfVa5OS8FErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleang0yP2tSk = {
  __typename?: 'Booleang0yP2tSK';
  error?: Maybe<Booleang0yP2tSkErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleang0yP2tSkErrorObject = {
  __typename?: 'Booleang0yP2tSKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanh9df5pJ6 = {
  __typename?: 'Booleanh9df5pJ6';
  error?: Maybe<Booleanh9df5pJ6ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanh9df5pJ6ErrorObject = {
  __typename?: 'Booleanh9df5pJ6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleaniXo1NrsV = {
  __typename?: 'BooleaniXo1NrsV';
  error?: Maybe<BooleaniXo1NrsVErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleaniXo1NrsVErrorObject = {
  __typename?: 'BooleaniXo1NrsVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleankAanbEoe = {
  __typename?: 'BooleankAANBEoe';
  error?: Maybe<BooleankAanbEoeErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleankAanbEoeErrorObject = {
  __typename?: 'BooleankAANBEoeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleankTyp9K2Z = {
  __typename?: 'BooleankTYP9K2Z';
  error?: Maybe<BooleankTyp9K2ZErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleankTyp9K2ZErrorObject = {
  __typename?: 'BooleankTYP9K2ZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanmoVrDfbF = {
  __typename?: 'BooleanmoVrDfbF';
  error?: Maybe<BooleanmoVrDfbFErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanmoVrDfbFErrorObject = {
  __typename?: 'BooleanmoVrDfbFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleannSWzjc1s = {
  __typename?: 'BooleannSWzjc1s';
  error?: Maybe<BooleannSWzjc1sErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleannSWzjc1sErrorObject = {
  __typename?: 'BooleannSWzjc1sErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleannjd8Bwnz = {
  __typename?: 'Booleannjd8BWNZ';
  error?: Maybe<Booleannjd8BwnzErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleannjd8BwnzErrorObject = {
  __typename?: 'Booleannjd8BWNZErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpDvltcmj = {
  __typename?: 'BooleanpDvltcmj';
  error?: Maybe<BooleanpDvltcmjErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpDvltcmjErrorObject = {
  __typename?: 'BooleanpDvltcmjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanpdRavcui = {
  __typename?: 'BooleanpdRAVCUI';
  error?: Maybe<BooleanpdRavcuiErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanpdRavcuiErrorObject = {
  __typename?: 'BooleanpdRAVCUIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanrpq0qrAa = {
  __typename?: 'Booleanrpq0qrAa';
  error?: Maybe<Booleanrpq0qrAaErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanrpq0qrAaErrorObject = {
  __typename?: 'Booleanrpq0qrAaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleansfhS8031 = {
  __typename?: 'BooleansfhS8031';
  error?: Maybe<BooleansfhS8031ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleansfhS8031ErrorObject = {
  __typename?: 'BooleansfhS8031ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleantSWgztpv = {
  __typename?: 'BooleantSWgztpv';
  error?: Maybe<BooleantSWgztpvErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleantSWgztpvErrorObject = {
  __typename?: 'BooleantSWgztpvErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleantacDp2Xq = {
  __typename?: 'BooleantacDP2Xq';
  error?: Maybe<BooleantacDp2XqErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleantacDp2XqErrorObject = {
  __typename?: 'BooleantacDP2XqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleantjWOvzl8 = {
  __typename?: 'BooleantjWOvzl8';
  error?: Maybe<BooleantjWOvzl8ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleantjWOvzl8ErrorObject = {
  __typename?: 'BooleantjWOvzl8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanuLp37Rv4 = {
  __typename?: 'BooleanuLP37RV4';
  error?: Maybe<BooleanuLp37Rv4ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanuLp37Rv4ErrorObject = {
  __typename?: 'BooleanuLP37RV4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Booleanv7Y95wRh = {
  __typename?: 'Booleanv7Y95wRh';
  error?: Maybe<Booleanv7Y95wRhErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type Booleanv7Y95wRhErrorObject = {
  __typename?: 'Booleanv7Y95wRhErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanwGSdw7Mp = {
  __typename?: 'BooleanwGSdw7MP';
  error?: Maybe<BooleanwGSdw7MpErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanwGSdw7MpErrorObject = {
  __typename?: 'BooleanwGSdw7MPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanwSwnPcPl = {
  __typename?: 'BooleanwSWNPcPl';
  error?: Maybe<BooleanwSwnPcPlErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanwSwnPcPlErrorObject = {
  __typename?: 'BooleanwSWNPcPlErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanxXvRc5x6 = {
  __typename?: 'BooleanxXvRC5x6';
  error?: Maybe<BooleanxXvRc5x6ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanxXvRc5x6ErrorObject = {
  __typename?: 'BooleanxXvRC5x6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanyFpyr8mA = {
  __typename?: 'BooleanyFpyr8mA';
  error?: Maybe<BooleanyFpyr8mAErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanyFpyr8mAErrorObject = {
  __typename?: 'BooleanyFpyr8mAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanydqCt1dj = {
  __typename?: 'BooleanydqCT1dj';
  error?: Maybe<BooleanydqCt1djErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanydqCt1djErrorObject = {
  __typename?: 'BooleanydqCT1djErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanykGojcs0 = {
  __typename?: 'BooleanykGOJCS0';
  error?: Maybe<BooleanykGojcs0ErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanykGojcs0ErrorObject = {
  __typename?: 'BooleanykGOJCS0ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzPKle7mX = {
  __typename?: 'BooleanzPKle7mX';
  error?: Maybe<BooleanzPKle7mXErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzPKle7mXErrorObject = {
  __typename?: 'BooleanzPKle7mXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type BooleanzoA3mJcm = {
  __typename?: 'BooleanzoA3mJCM';
  error?: Maybe<BooleanzoA3mJcmErrorObject>;
  object?: Maybe<Scalars['Boolean']>;
};

export type BooleanzoA3mJcmErrorObject = {
  __typename?: 'BooleanzoA3mJCMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CardHolderStatus {
  Active = 'Active',
  Blocked = 'Blocked',
  Inactive = 'Inactive'
}

export type CardVolumeObject = {
  __typename?: 'CardVolumeObject';
  amount: Scalars['Float'];
  count: Scalars['Float'];
};

export type CardVolumeResponseObject = {
  __typename?: 'CardVolumeResponseObject';
  error?: Maybe<CardVolumeResponseObjectErrorObject>;
  object?: Maybe<CardVolumeObject>;
};

export type CardVolumeResponseObjectErrorObject = {
  __typename?: 'CardVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CheckIfResetPasswordIsValidInput = {
  email: Scalars['String'];
  resetPasswordToken: Scalars['String'];
};

export type CheckoutSessionObject = {
  __typename?: 'CheckoutSessionObject';
  cancelUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
};

export type CheckoutSessionResponseObject = {
  __typename?: 'CheckoutSessionResponseObject';
  error?: Maybe<CheckoutSessionResponseObjectErrorObject>;
  object?: Maybe<CheckoutSessionObject>;
};

export type CheckoutSessionResponseObjectErrorObject = {
  __typename?: 'CheckoutSessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ChoiceFieldInput = {
  body: Scalars['String'];
  choiceId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceFieldObject = {
  __typename?: 'ChoiceFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type ChoiceInput = {
  choiceFields: Array<ChoiceFieldInput>;
  id: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  maximum?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChoiceObject = {
  __typename?: 'ChoiceObject';
  choiceFields: Array<ChoiceFieldObject>;
  countAlreadyAnswered?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  maximum?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type ChunkImportObject = {
  __typename?: 'ChunkImportObject';
  chunkIndex: Scalars['Float'];
  zeffyImport: ZeffyImportObject;
};

export type ChunkImportObjectgGlTRkhA = {
  __typename?: 'ChunkImportObjectgGlTRkhA';
  error?: Maybe<ChunkImportObjectgGlTRkhAErrorObject>;
  object?: Maybe<ChunkImportObject>;
};

export type ChunkImportObjectgGlTRkhAErrorObject = {
  __typename?: 'ChunkImportObjectgGlTRkhAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ClusterObject = {
  __typename?: 'ClusterObject';
  coordinates: CoordinatesObject;
  count: Scalars['Float'];
  cursorType: CursorType;
  displayedLatitude: Scalars['Float'];
  displayedLongitude: Scalars['Float'];
  id: Scalars['String'];
};

export type ClusterResponseObject = {
  __typename?: 'ClusterResponseObject';
  error?: Maybe<ClusterResponseObjectErrorObject>;
  items?: Maybe<Array<ClusterObject>>;
};

export type ClusterResponseObjectErrorObject = {
  __typename?: 'ClusterResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandLang {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type CommandObject = {
  __typename?: 'CommandObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  createdAtUtc: Scalars['Date'];
  cs?: Maybe<Scalars['String']>;
  currency: AvailablePaymentCurrency;
  discount?: Maybe<DiscountObject>;
  discountAmount: Scalars['Int'];
  discountId?: Maybe<Scalars['String']>;
  donationId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  eligibleAmount: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Int'];
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formLang: CommandLang;
  formType: FormType;
  id: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  locale: Locales;
  organization: OrganizationObject;
  organizationCountry: Scalars['String'];
  organizationId: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  postalCode?: Maybe<Scalars['String']>;
  productBids?: Maybe<Array<ProductBidObject>>;
  productDonation?: Maybe<ProductDonationObject>;
  productTickets?: Maybe<Array<ProductTicketObject>>;
  productsAmount: Scalars['Int'];
  region?: Maybe<Scalars['String']>;
  source: CommandSource;
  status: CommandStatus;
  tipAmount: Scalars['Int'];
  tipPercentage: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type CommandObject3YbnCwb3 = {
  __typename?: 'CommandObject3YBNCwb3';
  error?: Maybe<CommandObject3YbnCwb3ErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObject3YbnCwb3ErrorObject = {
  __typename?: 'CommandObject3YBNCwb3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectFe1AeXcx = {
  __typename?: 'CommandObjectFe1AeXCX';
  error?: Maybe<CommandObjectFe1AeXcxErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectFe1AeXcxErrorObject = {
  __typename?: 'CommandObjectFe1AeXCXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectJtn7oZyc = {
  __typename?: 'CommandObjectJTN7oZYC';
  error?: Maybe<CommandObjectJtn7oZycErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectJtn7oZycErrorObject = {
  __typename?: 'CommandObjectJTN7oZYCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectf034Vree = {
  __typename?: 'CommandObjectf034VREE';
  error?: Maybe<CommandObjectf034VreeErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectf034VreeErrorObject = {
  __typename?: 'CommandObjectf034VREEErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectmpbqw6jM = {
  __typename?: 'CommandObjectmpbqw6jM';
  error?: Maybe<CommandObjectmpbqw6jMErrorObject>;
  items?: Maybe<Array<CommandObject>>;
};

export type CommandObjectmpbqw6jMErrorObject = {
  __typename?: 'CommandObjectmpbqw6jMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandObjectocQ2EhGu = {
  __typename?: 'CommandObjectocQ2EhGU';
  error?: Maybe<CommandObjectocQ2EhGuErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandObjectocQ2EhGuErrorObject = {
  __typename?: 'CommandObjectocQ2EhGUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseListObjectErrorObject = {
  __typename?: 'CommandResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CommandResponseObject = {
  __typename?: 'CommandResponseObject';
  error?: Maybe<CommandResponseObjectErrorObject>;
  object?: Maybe<CommandObject>;
};

export type CommandResponseObjectErrorObject = {
  __typename?: 'CommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CommandSource {
  FormSubmission = 'FormSubmission',
  Import = 'Import',
  ManualEntry = 'ManualEntry'
}

export enum CommandStatus {
  Archived = 'Archived',
  Complete = 'Complete',
  Expired = 'Expired',
  Open = 'Open',
  Submitted = 'Submitted'
}

export type CommandSubscriptionObject = {
  __typename?: 'CommandSubscriptionObject';
  id: Scalars['String'];
  recurrenceInterval: SubscriptionRecurrenceInterval;
  status?: Maybe<NewSubscriptionStatus>;
  stripeSubscriptionId: Scalars['String'];
};

export type CommandSubscriptionResponseObject = {
  __typename?: 'CommandSubscriptionResponseObject';
  error?: Maybe<CommandSubscriptionResponseObjectErrorObject>;
  object?: Maybe<CommandSubscriptionObject>;
};

export type CommandSubscriptionResponseObjectErrorObject = {
  __typename?: 'CommandSubscriptionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CompleteStripeAuthError {
  ImpossibleToCreateStripeAccount = 'ImpossibleToCreateStripeAccount'
}

export type CompleteStripeAuthResponseObjectErrorObject = {
  __typename?: 'CompleteStripeAuthResponseObjectErrorObject';
  code?: Maybe<CompleteStripeAuthError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ConfirmPaymentIntentInput = {
  paymentIntentId: Scalars['String'];
};

export type ConfirmPaymentIntentObject = {
  __typename?: 'ConfirmPaymentIntentObject';
  nextAction?: Maybe<NextActionObject>;
};

export type ConfirmPaymentIntentObjectResponseObjectErrorObject = {
  __typename?: 'ConfirmPaymentIntentObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactIdWithTagsObject = {
  __typename?: 'ContactIdWithTagsObject';
  id: Scalars['String'];
  tags: Array<TagObject>;
};

export type ContactIdWithTagsObjectFxLxt7Sm = {
  __typename?: 'ContactIdWithTagsObjectFxLxt7SM';
  error?: Maybe<ContactIdWithTagsObjectFxLxt7SmErrorObject>;
  items?: Maybe<Array<ContactIdWithTagsObject>>;
};

export type ContactIdWithTagsObjectFxLxt7SmErrorObject = {
  __typename?: 'ContactIdWithTagsObjectFxLxt7SMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactKeyInfoObject = {
  __typename?: 'ContactKeyInfoObject';
  contactSince?: Maybe<Scalars['Date']>;
  largestDonation?: Maybe<Scalars['Float']>;
  monthlyRecurringContribution?: Maybe<Scalars['Float']>;
  totalContribution?: Maybe<Scalars['Float']>;
  yearlyRecurringContribution?: Maybe<Scalars['Float']>;
};

export type ContactKeyInfoObjectaAxNuLFa = {
  __typename?: 'ContactKeyInfoObjectaAxNuLFa';
  error?: Maybe<ContactKeyInfoObjectaAxNuLFaErrorObject>;
  object?: Maybe<ContactKeyInfoObject>;
};

export type ContactKeyInfoObjectaAxNuLFaErrorObject = {
  __typename?: 'ContactKeyInfoObjectaAxNuLFaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObject = {
  __typename?: 'ContactObject';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  donorType?: Maybe<DonorType>;
  firstName?: Maybe<Scalars['String']>;
  hasUnsubscribed?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  unsubscribedEmailDeliveryId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  userId: Scalars['String'];
};

export type ContactObjectFgzwbId8 = {
  __typename?: 'ContactObjectFgzwbID8';
  error?: Maybe<ContactObjectFgzwbId8ErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectFgzwbId8ErrorObject = {
  __typename?: 'ContactObjectFgzwbID8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectMrhe41ss = {
  __typename?: 'ContactObjectMRHE41ss';
  error?: Maybe<ContactObjectMrhe41ssErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactObjectMrhe41ssErrorObject = {
  __typename?: 'ContactObjectMRHE41ssErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectfEaWvCdv = {
  __typename?: 'ContactObjectfEaWvCDV';
  error?: Maybe<ContactObjectfEaWvCdvErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectfEaWvCdvErrorObject = {
  __typename?: 'ContactObjectfEaWvCDVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactObjectnJCeKu6g = {
  __typename?: 'ContactObjectnJCeKu6g';
  error?: Maybe<ContactObjectnJCeKu6gErrorObject>;
  items?: Maybe<Array<ContactObject>>;
};

export type ContactObjectnJCeKu6gErrorObject = {
  __typename?: 'ContactObjectnJCeKu6gErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ContactResponseObject = {
  __typename?: 'ContactResponseObject';
  error?: Maybe<ContactResponseObjectErrorObject>;
  object?: Maybe<ContactObject>;
};

export type ContactResponseObjectErrorObject = {
  __typename?: 'ContactResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CoordinatesObject = {
  __typename?: 'CoordinatesObject';
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export type CreateCardHolderInput = {
  address: Scalars['String'];
  agreedToStripeCardIssuingTermsAt: Scalars['Date'];
  city: Scalars['String'];
  countryCode: Scalars['String'];
  dateOfBirth: DateOfBirthInput;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Scalars['String']>;
};

export type CreateDonationAmountInput = {
  amount?: InputMaybe<Scalars['Float']>;
  id?: InputMaybe<Scalars['String']>;
  isRecurrent?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval?: InputMaybe<ProductDonationRecurrenceInterval>;
  sortIndex?: InputMaybe<Scalars['Float']>;
};

export type CreateDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts: Array<CreateDonationAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  postDonationUrl?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CreateDraftDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  displayAddressQuestion?: InputMaybe<Scalars['Boolean']>;
  donationFormFields: Array<CreateDonationFormFieldInput>;
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  eventFollowupEmailContentId?: InputMaybe<Scalars['String']>;
  eventInvitationEmailContentId?: InputMaybe<Scalars['String']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  highlight?: InputMaybe<Scalars['Boolean']>;
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isOnboardingForm?: Scalars['Boolean'];
  isPrimaryCampaign?: InputMaybe<Scalars['Boolean']>;
  isPrimaryTeam?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<CreateLinkedTicketingInput>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  organizationId?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  redirectUrl?: InputMaybe<Scalars['String']>;
  registrationCommandId?: InputMaybe<Scalars['String']>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  registrationOrderId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<FormStatus>;
  stripeProductId?: InputMaybe<Scalars['String']>;
  target?: InputMaybe<Scalars['Float']>;
  teamId?: InputMaybe<Scalars['String']>;
  ticketingId?: InputMaybe<Scalars['String']>;
  volume?: InputMaybe<Scalars['Float']>;
};

export type CreateFrontendOrderResponseObjectErrorObject = {
  __typename?: 'CreateFrontendOrderResponseObjectErrorObject';
  code?: Maybe<CreateOrderResponseError>;
  data?: Maybe<CreateOrderErrorDataObject>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateIssuingTopupInput = {
  amount: Scalars['Int'];
  plaidAccountId?: InputMaybe<Scalars['String']>;
  plaidPublicToken?: InputMaybe<Scalars['String']>;
};

export type CreateLinkedTicketingInput = {
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  rates: Array<CreateRateInput>;
};

export type CreateMobileCommandInput = {
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  extraDonation: Scalars['Float'];
  formId: Scalars['String'];
  locale: Locales;
  paymentIntentId: Scalars['String'];
  productDonation?: InputMaybe<CreateMobileProductDonationInput>;
  productTickets?: InputMaybe<Array<CreateMobileTicketInput>>;
  productsAmount: Scalars['Int'];
  tipAmount: Scalars['Float'];
  totalAmount: Scalars['Float'];
};

export type CreateMobileCommandObject = {
  __typename?: 'CreateMobileCommandObject';
  id: Scalars['String'];
};

export type CreateMobileCommandResponseObject = {
  __typename?: 'CreateMobileCommandResponseObject';
  error?: Maybe<CreateMobileCommandResponseObjectErrorObject>;
  object?: Maybe<CreateMobileCommandObject>;
};

export type CreateMobileCommandResponseObjectErrorObject = {
  __typename?: 'CreateMobileCommandResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type CreateMobileProductDonationInput = {
  amount?: InputMaybe<Scalars['Int']>;
  inHonourEmailAddress?: InputMaybe<Scalars['String']>;
  inHonourEmailBody?: InputMaybe<Scalars['String']>;
  inHonourName?: InputMaybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: InputMaybe<Scalars['String']>;
};

export type CreateMobileTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal: Scalars['Boolean'];
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
};

export type CreateNewOrganizationInput = {
  country: OrganizationCountry;
  organizationName: Scalars['String'];
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOther: Scalars['String'];
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type CreateOrderErrorDataObject = {
  __typename?: 'CreateOrderErrorDataObject';
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateOrderInput = {
  address?: InputMaybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers: Array<AnswerInput>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  discountId?: InputMaybe<Scalars['String']>;
  eligibleAmount: Scalars['Float'];
  email?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Float'];
  firstName?: InputMaybe<Scalars['String']>;
  isTapToPay?: InputMaybe<Scalars['Boolean']>;
  isTipMessageStayFree?: InputMaybe<Scalars['Boolean']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  occurrenceId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  paymentIntentId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  tip: Scalars['Float'];
  userId?: InputMaybe<Scalars['String']>;
};

export enum CreateOrderResponseError {
  ChoiceIsFull = 'ChoiceIsFull',
  ExpiredEarlyBirdRate = 'ExpiredEarlyBirdRate',
  NoTicketNorExtraDonation = 'NoTicketNorExtraDonation',
  NoTicketSelected = 'NoTicketSelected',
  OverpassedTicketingSeats = 'OverpassedTicketingSeats',
  RateIsFull = 'RateIsFull',
  ShouldSpecifyATicketingOccurrence = 'ShouldSpecifyATicketingOccurrence',
  TicketingIsClosed = 'TicketingIsClosed',
  TicketingIsFull = 'TicketingIsFull',
  TicketingOccurrenceNotFound = 'TicketingOccurrenceNotFound',
  UnknownError = 'UnknownError'
}

export type CreateOrderResponseObject = {
  __typename?: 'CreateOrderResponseObject';
  error?: Maybe<CreateOrderResponseError>;
  expiredEarlyBirdRates?: Maybe<Array<ExpiredEarlyBirdErrorObject>>;
  fullChoices?: Maybe<Array<FullChoiceErrorObject>>;
  fullRates?: Maybe<Array<FullRateErrorObject>>;
  order?: Maybe<OrderObject>;
  remainingTicketingSeats?: Maybe<Scalars['Float']>;
};

export type CreateRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  locale: Locales;
  title: Scalars['String'];
};

export type CreateRateInput = {
  amount: Scalars['Float'];
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photoUrls?: InputMaybe<Array<Scalars['String']>>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields?: InputMaybe<Array<CreateRateFieldInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type CreateReceiptInput = {
  address?: InputMaybe<Scalars['String']>;
  advantageAmount?: InputMaybe<Scalars['Float']>;
  advantageDescription?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Float']>;
  annotation?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  commandId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  createdAtUtc?: InputMaybe<Scalars['Date']>;
  donationDate?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fullName?: InputMaybe<Scalars['String']>;
  isImported?: InputMaybe<Scalars['Boolean']>;
  isSample?: InputMaybe<Scalars['Boolean']>;
  issuingAtUtc?: InputMaybe<Scalars['Date']>;
  lastName?: InputMaybe<Scalars['String']>;
  locale: Locales;
  postalCode?: InputMaybe<Scalars['String']>;
  receiptNumber?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ReceiptStatus>;
  userId?: InputMaybe<Scalars['String']>;
};

export type CreateTagInput = {
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

export type CreateTicketInput = {
  answers?: InputMaybe<Array<AnswerInput>>;
  automaticRenewal?: InputMaybe<Scalars['Boolean']>;
  rateId: Scalars['String'];
};

export type CreateTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  postEventBody?: InputMaybe<Scalars['String']>;
  postEventSubject?: InputMaybe<Scalars['String']>;
  postTransactionUrl?: InputMaybe<Scalars['String']>;
  reminderBody?: InputMaybe<Scalars['String']>;
  reminderSubject?: InputMaybe<Scalars['String']>;
  sanitizedDescription?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type CreateTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  color: Scalars['String'];
  duplicateLogo?: InputMaybe<Scalars['Boolean']>;
  duplicatedFromId?: InputMaybe<Scalars['String']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isOnboardingForm?: InputMaybe<Scalars['Boolean']>;
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale: Locales;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder: Array<QuestionInput>;
  questionsPerParticipant: Array<QuestionInput>;
  rates: Array<CreateRateInput>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  reminderSendDateOffset?: InputMaybe<Scalars['Float']>;
  seats?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<FormStatus>;
  ticketingFields: Array<CreateTicketingFieldInput>;
};

export type CreatedCampaignsObject = {
  __typename?: 'CreatedCampaignsObject';
  individualCampaigns?: Maybe<Array<FundraiserDonationFormObject>>;
  team?: Maybe<FundraiserDonationFormObject>;
};

export type CreatedIssuingPayoutInput = {
  amount: Scalars['Float'];
};

export type CreatedIssuingPayoutObject = {
  __typename?: 'CreatedIssuingPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type CreatedIssuingPayoutObjectU4ZpCQbF = {
  __typename?: 'CreatedIssuingPayoutObjectU4ZpCQbF';
  error?: Maybe<CreatedIssuingPayoutObjectU4ZpCQbFErrorObject>;
  object?: Maybe<CreatedIssuingPayoutObject>;
};

export type CreatedIssuingPayoutObjectU4ZpCQbFErrorObject = {
  __typename?: 'CreatedIssuingPayoutObjectU4ZpCQbFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum CronQueues {
  AuctionsPaymentProcessor = 'AuctionsPaymentProcessor',
  CloseAuctions = 'CloseAuctions',
  GenerateBankStatement = 'GenerateBankStatement',
  GenerateSitemap = 'GenerateSitemap',
  HubspotNurturingDealCreation = 'HubspotNurturingDealCreation',
  InvitationReminder = 'InvitationReminder',
  MarkStaleTransactionsAsFailed = 'MarkStaleTransactionsAsFailed',
  MembershipReminders = 'MembershipReminders',
  NotifyActiveOrganizationsWithoutBankInfo = 'NotifyActiveOrganizationsWithoutBankInfo',
  ProcessBalanceTransfersToIssuing = 'ProcessBalanceTransfersToIssuing',
  ProcessIssuingRewards = 'ProcessIssuingRewards',
  ProcessReferralTransfers = 'ProcessReferralTransfers',
  ProcessScheduledPayouts = 'ProcessScheduledPayouts',
  Reminders = 'Reminders',
  RetryFailedTransfer = 'RetryFailedTransfer',
  SendDailyVisitorReport = 'SendDailyVisitorReport',
  SendEventManagementEmails = 'SendEventManagementEmails',
  SendMonthlyBalanceTransactionsExport = 'SendMonthlyBalanceTransactionsExport',
  SendMonthlyChurnedReport = 'SendMonthlyChurnedReport',
  SendPostEventEmailDeliveries = 'SendPostEventEmailDeliveries',
  SendScheduledEmailDeliveries = 'SendScheduledEmailDeliveries',
  SendSuggestDesktopCompletionEmail = 'SendSuggestDesktopCompletionEmail',
  SendWeeklyChurnedReport = 'SendWeeklyChurnedReport',
  TriggerAwaitingWithdrawalRefunds = 'TriggerAwaitingWithdrawalRefunds',
  UpdateFraudulentTransaction = 'UpdateFraudulentTransaction',
  UpdateMissingIrsInfo = 'UpdateMissingIrsInfo'
}

export type CurrentlyActiveOrganizationObject = {
  __typename?: 'CurrentlyActiveOrganizationObject';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CurrentlyActiveOrganizationResponseListObject = {
  __typename?: 'CurrentlyActiveOrganizationResponseListObject';
  error?: Maybe<OrganizationResponseListObjectErrorObject>;
  items?: Maybe<Array<CurrentlyActiveOrganizationObject>>;
};

export enum CursorType {
  Organization = 'Organization',
  Ticketing = 'Ticketing'
}

export type DashboardCommandFormListResponse = {
  __typename?: 'DashboardCommandFormListResponse';
  error?: Maybe<DashboardCommandFormListResponseErrorObject>;
  items?: Maybe<Array<FormObject>>;
};

export type DashboardCommandFormListResponseErrorObject = {
  __typename?: 'DashboardCommandFormListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRateObject = {
  __typename?: 'DashboardCommandRateObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
};

export type DashboardCommandRatesListResponseObject = {
  __typename?: 'DashboardCommandRatesListResponseObject';
  error?: Maybe<DashboardCommandRatesListResponseObjectErrorObject>;
  items?: Maybe<Array<DashboardCommandRatesObject>>;
};

export type DashboardCommandRatesListResponseObjectErrorObject = {
  __typename?: 'DashboardCommandRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCommandRatesObject = {
  __typename?: 'DashboardCommandRatesObject';
  commandId: Scalars['String'];
  rates: Array<DashboardCommandRateObject>;
};

export type DashboardCountObject = {
  __typename?: 'DashboardCountObject';
  count: Scalars['Float'];
};

export type DashboardCountObjecti1UpBczX = {
  __typename?: 'DashboardCountObjecti1UpBczX';
  error?: Maybe<DashboardCountObjecti1UpBczXErrorObject>;
  object?: Maybe<DashboardCountObject>;
};

export type DashboardCountObjecti1UpBczXErrorObject = {
  __typename?: 'DashboardCountObjecti1UpBczXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardCountResponseObjectErrorObject = {
  __typename?: 'DashboardCountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DashboardPaymentStatus {
  DisputeLost = 'DisputeLost',
  Disputed = 'Disputed',
  Failed = 'Failed',
  Incomplete = 'Incomplete',
  Monthly = 'Monthly',
  OnHold = 'OnHold',
  PastDue = 'PastDue',
  Processing = 'Processing',
  Stopped = 'Stopped',
  Succeeded = 'Succeeded',
  Unprocessed = 'Unprocessed',
  Yearly = 'Yearly'
}

export type DashboardTransactionListResponseObjectErrorObject = {
  __typename?: 'DashboardTransactionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObject = {
  __typename?: 'DashboardTransactionObject';
  commandId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  corporationName?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  displayStatus: DashboardPaymentStatus;
  email?: Maybe<Scalars['String']>;
  eventEndAt?: Maybe<Scalars['Date']>;
  eventStartAt?: Maybe<Scalars['Date']>;
  extraDonation?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  formId: Scalars['String'];
  formType: FormType;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isAnonymous?: Maybe<Scalars['Boolean']>;
  isNewRecurringDonation?: Maybe<Scalars['Boolean']>;
  isSample?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  nextRenewalAt?: Maybe<Scalars['Date']>;
  paymentMethod?: Maybe<PaymentMethod>;
  payoutDate?: Maybe<Scalars['Date']>;
  receiptId?: Maybe<Scalars['String']>;
  recurrenceInterval?: Maybe<SubscriptionRecurrenceInterval>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount: Scalars['Int'];
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status: PaymentStatus;
  stoppedAt?: Maybe<Scalars['Date']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<NewSubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
};

export type DashboardTransactionObjectGZmKbmr6 = {
  __typename?: 'DashboardTransactionObjectGZmKbmr6';
  error?: Maybe<DashboardTransactionObjectGZmKbmr6ErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjectGZmKbmr6ErrorObject = {
  __typename?: 'DashboardTransactionObjectGZmKbmr6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DashboardTransactionObjecta60KBo8K = {
  __typename?: 'DashboardTransactionObjecta60KBo8K';
  error?: Maybe<DashboardTransactionObjecta60KBo8KErrorObject>;
  object?: Maybe<DashboardTransactionObject>;
};

export type DashboardTransactionObjecta60KBo8KErrorObject = {
  __typename?: 'DashboardTransactionObjecta60KBo8KErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DateOfBirthInput = {
  day: Scalars['Float'];
  month: Scalars['Float'];
  year: Scalars['Float'];
};

export type DeactivateAccountInput = {
  reason: Scalars['String'];
};

export type DeactiveTwoFactorAuthInput = {
  password: Scalars['String'];
};

export type DeleteUserAccountInput = {
  reason: Scalars['String'];
};

export type DiscountObject = {
  __typename?: 'DiscountObject';
  amount: Scalars['Float'];
  body: Scalars['String'];
  id: Scalars['String'];
  limit?: Maybe<Scalars['Float']>;
  remainingUses?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  ticketingId: Scalars['String'];
  type: DiscountType;
};

export type DiscountResponseObject = {
  __typename?: 'DiscountResponseObject';
  error?: Maybe<DiscountResponseObjectErrorObject>;
  object?: Maybe<DiscountObject>;
};

export type DiscountResponseObjectErrorObject = {
  __typename?: 'DiscountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DiscountType {
  Dollars = 'Dollars',
  Percentage = 'Percentage'
}

export type DonationFormAmountInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormAmountObject = {
  __typename?: 'DonationFormAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type DonationFormCampaignFilterInput = {
  limit?: InputMaybe<Scalars['Int']>;
  page: Scalars['Int'];
  search: Scalars['String'];
};

export enum DonationFormCategory {
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  PeerToPeerCampaign = 'PeerToPeerCampaign'
}

export type DonationFormFieldFromCampaignObject = {
  __typename?: 'DonationFormFieldFromCampaignObject';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type DonationFormFieldObject = {
  __typename?: 'DonationFormFieldObject';
  chequeDescription?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  description: Scalars['String'];
  donationFormAmounts?: Maybe<Array<DonationFormAmountObject>>;
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<Scalars['String']>;
  emailObject?: Maybe<Scalars['String']>;
  fundraiserEmailBody?: Maybe<Scalars['String']>;
  fundraisingRegistrationWording?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  postDonationUrl?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  ticketingRegistrationWording?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DonationFormFromCampaignObject = {
  __typename?: 'DonationFormFromCampaignObject';
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  donationFormFields?: Maybe<Array<DonationFormFieldFromCampaignObject>>;
  id: Scalars['String'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  teamId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject = {
  __typename?: 'DonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  eventFollowupEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailContentId?: Maybe<Scalars['String']>;
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type DonationFormObject0ilPrdGq = {
  __typename?: 'DonationFormObject0ilPrdGq';
  error?: Maybe<DonationFormObject0ilPrdGqErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObject0ilPrdGqErrorObject = {
  __typename?: 'DonationFormObject0ilPrdGqErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectCk0yofJa = {
  __typename?: 'DonationFormObjectCk0yofJa';
  error?: Maybe<DonationFormObjectCk0yofJaErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectCk0yofJaErrorObject = {
  __typename?: 'DonationFormObjectCk0yofJaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectJyoSIyao = {
  __typename?: 'DonationFormObjectJyoSIyao';
  error?: Maybe<DonationFormObjectJyoSIyaoErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectJyoSIyaoErrorObject = {
  __typename?: 'DonationFormObjectJyoSIyaoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectMnocBrSk = {
  __typename?: 'DonationFormObjectMNOCBrSk';
  error?: Maybe<DonationFormObjectMnocBrSkErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectMnocBrSkErrorObject = {
  __typename?: 'DonationFormObjectMNOCBrSkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectQea1E4Co = {
  __typename?: 'DonationFormObjectQea1E4Co';
  error?: Maybe<DonationFormObjectQea1E4CoErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectQea1E4CoErrorObject = {
  __typename?: 'DonationFormObjectQea1E4CoErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectSepqsRs9 = {
  __typename?: 'DonationFormObjectSepqsRs9';
  error?: Maybe<DonationFormObjectSepqsRs9ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectSepqsRs9ErrorObject = {
  __typename?: 'DonationFormObjectSepqsRs9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectVv0hhd1g = {
  __typename?: 'DonationFormObjectVv0hhd1g';
  error?: Maybe<DonationFormObjectVv0hhd1gErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectVv0hhd1gErrorObject = {
  __typename?: 'DonationFormObjectVv0hhd1gErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectYtGzpB2l = {
  __typename?: 'DonationFormObjectYtGzpB2l';
  error?: Maybe<DonationFormObjectYtGzpB2lErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectYtGzpB2lErrorObject = {
  __typename?: 'DonationFormObjectYtGzpB2lErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectaUtkzfnq = {
  __typename?: 'DonationFormObjectaUTKZFNQ';
  error?: Maybe<DonationFormObjectaUtkzfnqErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectaUtkzfnqErrorObject = {
  __typename?: 'DonationFormObjectaUTKZFNQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectbWuXwwNh = {
  __typename?: 'DonationFormObjectbWuXWWNh';
  error?: Maybe<DonationFormObjectbWuXwwNhErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectbWuXwwNhErrorObject = {
  __typename?: 'DonationFormObjectbWuXWWNhErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjecteKuGuZpf = {
  __typename?: 'DonationFormObjecteKuGuZPF';
  error?: Maybe<DonationFormObjecteKuGuZpfErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObjecteKuGuZpfErrorObject = {
  __typename?: 'DonationFormObjecteKuGuZPFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectf8pxb0x8 = {
  __typename?: 'DonationFormObjectf8pxb0x8';
  error?: Maybe<DonationFormObjectf8pxb0x8ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectf8pxb0x8ErrorObject = {
  __typename?: 'DonationFormObjectf8pxb0x8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjecthuYuuJax = {
  __typename?: 'DonationFormObjecthuYuuJax';
  error?: Maybe<DonationFormObjecthuYuuJaxErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjecthuYuuJaxErrorObject = {
  __typename?: 'DonationFormObjecthuYuuJaxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectiRzO0pph = {
  __typename?: 'DonationFormObjectiRzO0pph';
  error?: Maybe<DonationFormObjectiRzO0pphErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectiRzO0pphErrorObject = {
  __typename?: 'DonationFormObjectiRzO0pphErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjecticQdf1B3 = {
  __typename?: 'DonationFormObjecticQdf1B3';
  error?: Maybe<DonationFormObjecticQdf1B3ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjecticQdf1B3ErrorObject = {
  __typename?: 'DonationFormObjecticQdf1B3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectjfwzhnna = {
  __typename?: 'DonationFormObjectjfwzhnna';
  error?: Maybe<DonationFormObjectjfwzhnnaErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectjfwzhnnaErrorObject = {
  __typename?: 'DonationFormObjectjfwzhnnaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectloXKm9Vn = {
  __typename?: 'DonationFormObjectloXKm9Vn';
  error?: Maybe<DonationFormObjectloXKm9VnErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectloXKm9VnErrorObject = {
  __typename?: 'DonationFormObjectloXKm9VnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectmS8VIsp7 = {
  __typename?: 'DonationFormObjectmS8VIsp7';
  error?: Maybe<DonationFormObjectmS8VIsp7ErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectmS8VIsp7ErrorObject = {
  __typename?: 'DonationFormObjectmS8VIsp7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectomSdd6Qx = {
  __typename?: 'DonationFormObjectomSDD6QX';
  error?: Maybe<DonationFormObjectomSdd6QxErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectomSdd6QxErrorObject = {
  __typename?: 'DonationFormObjectomSDD6QXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectrIhTciEi = {
  __typename?: 'DonationFormObjectrIhTciEi';
  error?: Maybe<DonationFormObjectrIhTciEiErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjectrIhTciEiErrorObject = {
  __typename?: 'DonationFormObjectrIhTciEiErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjects8wrx1lI = {
  __typename?: 'DonationFormObjects8wrx1lI';
  error?: Maybe<DonationFormObjects8wrx1lIErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormObjects8wrx1lIErrorObject = {
  __typename?: 'DonationFormObjects8wrx1lIErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormObjectzGBnMmSa = {
  __typename?: 'DonationFormObjectzGBnMmSa';
  error?: Maybe<DonationFormObjectzGBnMmSaErrorObject>;
  items?: Maybe<Array<DonationFormObject>>;
};

export type DonationFormObjectzGBnMmSaErrorObject = {
  __typename?: 'DonationFormObjectzGBnMmSaErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormRecurrence {
  monthly = 'monthly',
  weekly = 'weekly'
}

export type DonationFormResponseObject = {
  __typename?: 'DonationFormResponseObject';
  error?: Maybe<DonationFormResponseObjectErrorObject>;
  object?: Maybe<DonationFormObject>;
};

export type DonationFormResponseObjectErrorObject = {
  __typename?: 'DonationFormResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationFormSummaryObject = {
  __typename?: 'DonationFormSummaryObject';
  description: Scalars['String'];
  id: Scalars['String'];
};

export type DonationFormTableResponseListObjectErrorObject = {
  __typename?: 'DonationFormTableResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum DonationFormTemplateType {
  givingTuesday = 'givingTuesday',
  onboarding = 'onboarding'
}

export type DonationObject = {
  __typename?: 'DonationObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationForm: DonationFormObject;
  email?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isArchived: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  lastTransactionId?: Maybe<Scalars['String']>;
  nextPaymentDate?: Maybe<Scalars['Date']>;
  postalCode?: Maybe<Scalars['String']>;
  recurrence?: Maybe<DonationFormRecurrence>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  region?: Maybe<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
  thankYouComment?: Maybe<Scalars['String']>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type DonationResponseListObjectErrorObject = {
  __typename?: 'DonationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonationResponseObjectErrorObject = {
  __typename?: 'DonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationCountry: OrganizationCountry;
};

export enum DonorType {
  Member = 'Member',
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Prospect = 'Prospect',
  Returning = 'Returning',
  Yearly = 'Yearly'
}

export type DonorVolumeListResponseObject = {
  __typename?: 'DonorVolumeListResponseObject';
  error?: Maybe<DonorVolumeListResponseObjectErrorObject>;
  items?: Maybe<Array<DonorVolumeObject>>;
};

export type DonorVolumeListResponseObjectErrorObject = {
  __typename?: 'DonorVolumeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type DonorVolumeObject = {
  __typename?: 'DonorVolumeObject';
  amount: Scalars['Float'];
  currency: AvailablePaymentCurrency;
};

export type EditContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type EditDiscountInput = {
  amount: Scalars['Float'];
  body: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  type: DiscountType;
};

export type EditDonationFormAmountInput = {
  toDelete: Array<DonationFormAmountInput>;
  toSave: Array<UpdateDonationFormAmountInput>;
};

export type EditDonationFormAmountTranslationInput = {
  amount: Scalars['Float'];
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type EditDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditDonationFormInput = {
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  allowFundraiserCreation?: InputMaybe<Scalars['Boolean']>;
  allowInHonour?: InputMaybe<Scalars['Boolean']>;
  allowTeamCreation?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  donationFormAmounts?: InputMaybe<EditDonationFormAmountInput>;
  donationFormFields: Array<EditDonationFormFieldInput>;
  encourageMonthlyDonations?: InputMaybe<Scalars['Boolean']>;
  expectedAmountSize?: InputMaybe<ExpectedAmountSize>;
  hasReceipt?: InputMaybe<Scalars['Boolean']>;
  hideFundraiseButton?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: InputMaybe<Scalars['Boolean']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  linkedTicketingInput?: InputMaybe<EditLinkedTicketingInput>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questions?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrence?: InputMaybe<DonationFormRecurrence>;
  registrationIncreaseThermometer?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['Float']>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export type EditDonationFormTranslationsInput = {
  fields: TranslationsDonationFormFieldInput;
  translations: TranslationsDonationFormFieldInput;
};

export type EditLinkedTicketingInput = {
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates: Array<EditRateInput>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
};

export enum EditMyProfileError {
  EmailAlreadyUsed = 'EmailAlreadyUsed'
}

export type EditPasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type EditRateFieldInput = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditRateInput = {
  allowAutomaticRenewal?: InputMaybe<Scalars['Boolean']>;
  amount?: InputMaybe<Scalars['Float']>;
  attendeesByTicket?: InputMaybe<Scalars['Float']>;
  bidIncrement?: InputMaybe<Scalars['Float']>;
  earlyBirdCloseDate?: InputMaybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: InputMaybe<Scalars['String']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  id: Scalars['String'];
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: InputMaybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: InputMaybe<Scalars['Date']>;
  minimumToBuy?: InputMaybe<Scalars['Float']>;
  photos?: InputMaybe<Array<Scalars['String']>>;
  rateFields: Array<EditRateFieldInput>;
  seats?: InputMaybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
};

export type EditReminderDataInput = {
  id: Scalars['String'];
  reminderBody: Scalars['String'];
  reminderSubject: Scalars['String'];
};

export type EditSessionOnSubmission = {
  durationOnPage: Scalars['Float'];
  sessionId?: InputMaybe<Scalars['String']>;
};

export type EditTagInput = {
  tagId: Scalars['String'];
  value: Scalars['String'];
};

export type EditTicketingFieldInput = {
  bidLoserEmailContent?: InputMaybe<Scalars['String']>;
  bidLoserEmailObject?: InputMaybe<Scalars['String']>;
  chequeDescription?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  offlinePaymentWording?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type EditTicketingInput = {
  address?: InputMaybe<Scalars['String']>;
  allowCheque?: InputMaybe<Scalars['Boolean']>;
  askForAddress?: InputMaybe<Scalars['Boolean']>;
  banner?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerVideoUrl?: InputMaybe<Scalars['String']>;
  choicesToDelete?: InputMaybe<Array<Scalars['String']>>;
  color?: InputMaybe<Scalars['String']>;
  discounts?: InputMaybe<Array<EditDiscountInput>>;
  discountsToDelete?: InputMaybe<Array<Scalars['String']>>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  extraDonation?: InputMaybe<Scalars['Boolean']>;
  generateExtraDonationReceipt?: InputMaybe<Scalars['Boolean']>;
  generateQrCode?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  liveFormEditorStep?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Locales>;
  logo?: InputMaybe<Scalars['String']>;
  notificationEmails?: InputMaybe<Scalars['NotificationEmailsScalar']>;
  questionsPerOrder?: InputMaybe<Array<QuestionInput>>;
  questionsPerParticipant?: InputMaybe<Array<QuestionInput>>;
  questionsToDelete?: InputMaybe<Array<Scalars['String']>>;
  rates?: InputMaybe<Array<EditRateInput>>;
  ratesToDelete?: InputMaybe<Array<Scalars['String']>>;
  recurrences?: InputMaybe<Array<TicketingRecurrenceInput>>;
  seats?: InputMaybe<Scalars['Float']>;
  thermometerTarget?: InputMaybe<Scalars['Float']>;
  ticketingFields?: InputMaybe<Array<EditTicketingFieldInput>>;
  toDeleteBanner?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerPdf?: InputMaybe<Scalars['Boolean']>;
  toDeleteBannerVideo?: InputMaybe<Scalars['Boolean']>;
  toDeleteLogo?: InputMaybe<Scalars['Boolean']>;
};

export enum EmailContentFlow {
  Builder = 'Builder',
  Custom = 'Custom',
  Quick = 'Quick'
}

export type EmailContentObject = {
  __typename?: 'EmailContentObject';
  attachmentUrl?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  subject: Scalars['String'];
};

export type EmailDeliveryObject = {
  __typename?: 'EmailDeliveryObject';
  body: Scalars['String'];
  clickedRatio: Scalars['Float'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent?: Maybe<EmailContentObject>;
  emailContentId?: Maybe<Scalars['String']>;
  emailsCount: Scalars['Float'];
  id: Scalars['String'];
  isPrefill: Scalars['Boolean'];
  locale: Locales;
  openedRatio: Scalars['Float'];
  organization: OrganizationObject;
  recipientEmailStatus?: Maybe<EmailStatus>;
  recipientEmails?: Maybe<Array<Scalars['String']>>;
  recipients?: Maybe<Array<RecipientObject>>;
  scheduledFor?: Maybe<Scalars['Date']>;
  sender: UserObject;
  sentAt?: Maybe<Scalars['Date']>;
  status: EmailDeliveryStatus;
  subject: Scalars['String'];
};

export type EmailDeliveryObjectT9soTe6R = {
  __typename?: 'EmailDeliveryObjectT9soTE6R';
  error?: Maybe<EmailDeliveryObjectT9soTe6RErrorObject>;
  object?: Maybe<EmailDeliveryObject>;
};

export type EmailDeliveryObjectT9soTe6RErrorObject = {
  __typename?: 'EmailDeliveryObjectT9soTE6RErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailDeliveryResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailDeliveryStatus {
  Completed = 'Completed',
  Draft = 'Draft',
  Failed = 'Failed',
  Pending = 'Pending',
  Processing = 'Processing'
}

export type EmailDeliveryTableListResponseObjectErrorObject = {
  __typename?: 'EmailDeliveryTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailStatus {
  AwaitingProcessing = 'AwaitingProcessing',
  Bounced = 'Bounced',
  Clicked = 'Clicked',
  Delivered = 'Delivered',
  Invalid = 'Invalid',
  Opened = 'Opened',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum EmailTemplateErrorCode {
  NotFound = 'NotFound'
}

export type EmailTemplateObject = {
  __typename?: 'EmailTemplateObject';
  body: Scalars['String'];
  flow?: Maybe<EmailContentFlow>;
  id: Scalars['String'];
  subject: Scalars['String'];
  title: Scalars['String'];
};

export type EmailTemplateResponseListObject = {
  __typename?: 'EmailTemplateResponseListObject';
  error?: Maybe<EmailTemplateResponseListObjectErrorObject>;
  items?: Maybe<Array<EmailTemplateObject>>;
};

export type EmailTemplateResponseListObjectErrorObject = {
  __typename?: 'EmailTemplateResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EmailTemplateResponseObject = {
  __typename?: 'EmailTemplateResponseObject';
  error?: Maybe<EmailTemplateResponseObjectErrorObject>;
  object?: Maybe<EmailTemplateObject>;
};

export type EmailTemplateResponseObjectErrorObject = {
  __typename?: 'EmailTemplateResponseObjectErrorObject';
  code?: Maybe<EmailTemplateErrorCode>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum EmailType {
  VisitorDailyEmail = 'VisitorDailyEmail',
  VisitorMonthlyEmail = 'VisitorMonthlyEmail',
  VisitorWeeklyEmail = 'VisitorWeeklyEmail'
}

export type EmailsCountByStatusObject = {
  __typename?: 'EmailsCountByStatusObject';
  count: Scalars['Float'];
  status: Scalars['String'];
};

export type EmailsCountByStatusTableListResponseObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObject';
  error?: Maybe<EmailsCountByStatusTableListResponseObjectErrorObject>;
  filteredCount: Scalars['Float'];
  hasMore: Scalars['Boolean'];
  items?: Maybe<Array<EmailsCountByStatusObject>>;
  totalCount: Scalars['Float'];
};

export type EmailsCountByStatusTableListResponseObjectErrorObject = {
  __typename?: 'EmailsCountByStatusTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EncryptedPaymentIntentObject = {
  __typename?: 'EncryptedPaymentIntentObject';
  clientSecret: Scalars['String'];
  encryptedPaymentIntentId: Scalars['String'];
};

export type EncryptedPaymentIntentResponseObjectErrorObject = {
  __typename?: 'EncryptedPaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type EventDateRange = {
  __typename?: 'EventDateRange';
  endDateUtc: Scalars['Date'];
  startDateUtc: Scalars['Date'];
};

export enum ExpectedAmountSize {
  Big = 'Big',
  Medium = 'Medium',
  Small = 'Small'
}

export type ExpiredEarlyBirdErrorObject = {
  __typename?: 'ExpiredEarlyBirdErrorObject';
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type ExportObject = {
  __typename?: 'ExportObject';
  export?: Maybe<Scalars['String']>;
  isExportSentByEmail: Scalars['Boolean'];
};

export type ExportObjectk2tpMzaw = {
  __typename?: 'ExportObjectk2tpMZAW';
  error?: Maybe<ExportObjectk2tpMzawErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportObjectk2tpMzawErrorObject = {
  __typename?: 'ExportObjectk2tpMZAWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExportParametersInput = {
  locale: Locales;
  timezone: Scalars['String'];
};

export type ExportResponseObject = {
  __typename?: 'ExportResponseObject';
  error?: Maybe<ExportResponseObjectErrorObject>;
  object?: Maybe<ExportObject>;
};

export type ExportResponseObjectErrorObject = {
  __typename?: 'ExportResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ExternalAccountInput = {
  accountNumber: Scalars['String'];
  routingNumber: Scalars['String'];
};

export type ExternalAccountObject = {
  __typename?: 'ExternalAccountObject';
  accountName?: Maybe<Scalars['String']>;
  bankName?: Maybe<Scalars['String']>;
  country?: Maybe<CountryCode>;
  currency?: Maybe<AvailablePaymentCurrency>;
  holderName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4?: Maybe<Scalars['String']>;
  routingNumber?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};

export type ExternalAccountObjectjAlYjvPg = {
  __typename?: 'ExternalAccountObjectjAlYJVPg';
  error?: Maybe<ExternalAccountObjectjAlYjvPgErrorObject>;
  object?: Maybe<ExternalAccountObject>;
};

export type ExternalAccountObjectjAlYjvPgErrorObject = {
  __typename?: 'ExternalAccountObjectjAlYJVPgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FeatureFlagName {
  FeatureToggleBadTipperDisclaimer = 'FeatureToggleBadTipperDisclaimer',
  IsAlgoliaForFormSearch = 'IsAlgoliaForFormSearch',
  IsAuctionRebiddingDisabled = 'IsAuctionRebiddingDisabled',
  IsBookADemoDisplayedWebflow = 'IsBookADemoDisplayedWebflow',
  IsCloudflareApiEnabled = 'IsCloudflareApiEnabled',
  IsFundraiserFormEditDisabled = 'IsFundraiserFormEditDisabled',
  IsHubspotActiveInStage = 'IsHubspotActiveInStage',
  IsNewMobileFormCreationFlow = 'IsNewMobileFormCreationFlow',
  IsPaymentByPadAllowed = 'IsPaymentByPadAllowed',
  IsStripeIssuingAllowed = 'IsStripeIssuingAllowed',
  IsZeffyUnderAttackFromCardTesting = 'IsZeffyUnderAttackFromCardTesting',
  LearnMoreOnTipMessage = 'LearnMoreOnTipMessage',
  Test = 'Test',
  TipSuggestionBigAmountsLargerThan400 = 'TipSuggestionBigAmountsLargerThan400',
  TipSuggestionBigAmountsLargerThan1000 = 'TipSuggestionBigAmountsLargerThan1000',
  TipSuggestionExperience = 'TipSuggestionExperience',
  TipSuggestionSmallAmounts = 'TipSuggestionSmallAmounts'
}

export type FeatureFlagTreatmentObject = {
  __typename?: 'FeatureFlagTreatmentObject';
  name: FeatureFlagName;
  treatment: Scalars['Boolean'];
};

export enum FeatureFlagValue {
  A = 'A',
  B = 'B',
  C = 'C',
  Control = 'Control',
  D = 'D',
  E = 'E',
  F = 'F'
}

export type FirstFormObject = {
  __typename?: 'FirstFormObject';
  formCategory: FormCategory;
  formStatus: FormStatus;
  formType: FormType;
  id: Scalars['String'];
  path: Scalars['String'];
};

export enum FormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  DonationCampaign = 'DonationCampaign',
  DonationForm = 'DonationForm',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  PeerToPeerCampaign = 'PeerToPeerCampaign',
  Shop = 'Shop'
}

export type FormField = {
  __typename?: 'FormField';
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type FormInput = {
  formType: FormType;
  id: Scalars['String'];
};

export type FormObject = {
  __typename?: 'FormObject';
  fields: Array<FormField>;
  formLink: Scalars['String'];
  formType: FormType;
  id: Scalars['String'];
  volume: Scalars['Float'];
};

export enum FormStatus {
  Active = 'Active',
  Draft = 'Draft'
}

export type FormTitle = {
  __typename?: 'FormTitle';
  fields: Array<FormField>;
  id: Scalars['String'];
};

export type FormTitleListResponse = {
  __typename?: 'FormTitleListResponse';
  error?: Maybe<FormTitleListResponseErrorObject>;
  items?: Maybe<Array<FormTitle>>;
};

export type FormTitleListResponseErrorObject = {
  __typename?: 'FormTitleListResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum FormType {
  DonationForm = 'DonationForm',
  Ticketing = 'Ticketing'
}

export enum FrequencyFilter {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type FrontendFormObject = {
  __typename?: 'FrontendFormObject';
  auctionEndDate?: Maybe<Scalars['Date']>;
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerUrl?: Maybe<Scalars['String']>;
  campaignId?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Boolean']>;
  color?: Maybe<Scalars['String']>;
  fields: Array<FormField>;
  formCategory: FormCategory;
  formType: FormType;
  generateQrCode?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isPrimaryCampaign?: Maybe<Scalars['Boolean']>;
  isPrimaryTeam?: Maybe<Scalars['Boolean']>;
  isRegistration?: Maybe<Scalars['Boolean']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  rates?: Maybe<Array<TicketingDetailsRate>>;
  seats?: Maybe<Scalars['Int']>;
  status: FormStatus;
  teamId?: Maybe<Scalars['String']>;
};

export type FrontendFormObject5n0EsEKk = {
  __typename?: 'FrontendFormObject5n0EsEKk';
  error?: Maybe<FrontendFormObject5n0EsEKkErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObject5n0EsEKkErrorObject = {
  __typename?: 'FrontendFormObject5n0EsEKkErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectFormObjectResponseObjectErrorObject = {
  __typename?: 'FrontendFormObjectFormObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseListObject = {
  __typename?: 'FrontendFormObjectResponseListObject';
  error?: Maybe<FrontendFormObjectResponseListObjectErrorObject>;
  items?: Maybe<Array<FrontendFormObject>>;
};

export type FrontendFormObjectResponseListObjectErrorObject = {
  __typename?: 'FrontendFormObjectResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type FrontendFormObjectResponseObject = {
  __typename?: 'FrontendFormObjectResponseObject';
  error?: Maybe<FrontendFormObjectFormObjectResponseObjectErrorObject>;
  object?: Maybe<FrontendFormObject>;
};

export type FullChoiceErrorObject = {
  __typename?: 'FullChoiceErrorObject';
  id: Scalars['String'];
  remainingAnswers: Scalars['Float'];
};

export type FullRateErrorObject = {
  __typename?: 'FullRateErrorObject';
  id: Scalars['String'];
  remainingTickets: Scalars['Float'];
};

export type FundraiserDonationFormObject = {
  __typename?: 'FundraiserDonationFormObject';
  allowCheque: Scalars['Boolean'];
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowInHonour: Scalars['Boolean'];
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  askForAddress: Scalars['Boolean'];
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  campaign?: Maybe<DonationFormObject>;
  campaignId?: Maybe<Scalars['String']>;
  category: DonationFormCategory;
  color: Scalars['String'];
  contact?: Maybe<ContactObject>;
  createdAtUtc: Scalars['Date'];
  displayAddressQuestion?: Maybe<Scalars['Boolean']>;
  donationFormFields?: Maybe<Array<DonationFormFieldObject>>;
  donations?: Maybe<Array<DonationObject>>;
  encourageMonthlyDonations: Scalars['Boolean'];
  eventFollowupEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailContentId?: Maybe<Scalars['String']>;
  expectedAmountSize?: Maybe<ExpectedAmountSize>;
  featureFlagTreatments?: Maybe<Array<FeatureFlagTreatmentObject>>;
  formVolume?: Maybe<Scalars['Float']>;
  hasReceipt: Scalars['Boolean'];
  hideFundraiseButton: Scalars['Boolean'];
  highlight: Scalars['Boolean'];
  id: Scalars['String'];
  isAmountsAlreadySavedInCreation?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isFundraiserFormEditDisabled: Scalars['Boolean'];
  isPrimaryCampaign: Scalars['Boolean'];
  isPrimaryTeam: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  missingTranslations: Scalars['Boolean'];
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  questions?: Maybe<Array<QuestionObject>>;
  recurrence: DonationFormRecurrence;
  redirectUrl?: Maybe<Scalars['String']>;
  registrationCommandId?: Maybe<Scalars['String']>;
  registrationIncreaseThermometer: Scalars['Boolean'];
  registrationOrderId?: Maybe<Scalars['String']>;
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['Float']>;
  team?: Maybe<DonationFormObject>;
  teamId?: Maybe<Scalars['String']>;
  ticketing?: Maybe<TicketingObject>;
  ticketingId?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  user?: Maybe<UserObject>;
  volume?: Maybe<Scalars['Float']>;
};

export type GetBalanceTransactionDetailsObject = {
  __typename?: 'GetBalanceTransactionDetailsObject';
  id: Scalars['String'];
  sourceIssuingTransaction?: Maybe<GetBalanceTransactionIssuingTransactionObject>;
  sourcePayment?: Maybe<GetBalanceTransactionPaymentObject>;
  sourcePayout?: Maybe<GetBalanceTransactionPayoutObject>;
};

export type GetBalanceTransactionDetailsObjectTdVrCbju = {
  __typename?: 'GetBalanceTransactionDetailsObjectTdVRCbju';
  error?: Maybe<GetBalanceTransactionDetailsObjectTdVrCbjuErrorObject>;
  object?: Maybe<GetBalanceTransactionDetailsObject>;
};

export type GetBalanceTransactionDetailsObjectTdVrCbjuErrorObject = {
  __typename?: 'GetBalanceTransactionDetailsObjectTdVRCbjuErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetBalanceTransactionIssuingTransactionObject = {
  __typename?: 'GetBalanceTransactionIssuingTransactionObject';
  authorizationCreatedAtUtc: Scalars['Date'];
  city?: Maybe<Scalars['String']>;
  createdAtUtc: Scalars['Date'];
  merchantName: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type GetBalanceTransactionPaymentObject = {
  __typename?: 'GetBalanceTransactionPaymentObject';
  createdAtUtc: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  formId: Scalars['String'];
  formName: Scalars['String'];
  formType: FormType;
  lastName: Scalars['String'];
  paymentMethod: PaymentMethod;
};

export type GetBalanceTransactionPayoutObject = {
  __typename?: 'GetBalanceTransactionPayoutObject';
  arrivalDate: Scalars['Date'];
};

export type GetCurrentUserFrontendObject = {
  __typename?: 'GetCurrentUserFrontendObject';
  connectedAsAdministrator: Scalars['Boolean'];
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  currentOrganizationDetails?: Maybe<OrganizationDetailsObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasAnyBankPermission: Scalars['Boolean'];
  hasDismissedEmailInfobox: Scalars['Boolean'];
  hasDismissedEmailTemplatePrompt: Scalars['Boolean'];
  hasDismissedReferralPromptAtFirstPayout: Scalars['Boolean'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isBankChangeSmsMfaActivated: Scalars['Boolean'];
  isLoginSmsMfaActivated: Scalars['Boolean'];
  isMigratedOnStytch: Scalars['Boolean'];
  isOnboardedOnCheckinManager: Scalars['Boolean'];
  isTwoFactorActivated: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  organizationGroups: Array<OrganizationGroupObject>;
  phone?: Maybe<Scalars['String']>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  zeffyAdministrator: Scalars['Boolean'];
};

export type GetCurrentUserFrontendObjectNaucUn67 = {
  __typename?: 'GetCurrentUserFrontendObjectNaucUn67';
  error?: Maybe<GetCurrentUserFrontendObjectNaucUn67ErrorObject>;
  object?: Maybe<GetCurrentUserFrontendObject>;
};

export type GetCurrentUserFrontendObjectNaucUn67ErrorObject = {
  __typename?: 'GetCurrentUserFrontendObjectNaucUn67ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetCurrentUserObject = {
  __typename?: 'GetCurrentUserObject';
  createdAtUtc: Scalars['Date'];
  currentOrganization?: Maybe<OrganizationObject>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasSeenReferralPage: Scalars['Boolean'];
  id: Scalars['String'];
  isAdministrator: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  organizationGroups: Array<OrganizationGroupObject>;
  role: UserRoles;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
};

export type GetCurrentUserResponseObjectErrorObject = {
  __typename?: 'GetCurrentUserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetDonationFormsFromCampaignObject = {
  __typename?: 'GetDonationFormsFromCampaignObject';
  hasMore: Scalars['Boolean'];
  individual: Array<DonationFormFromCampaignObject>;
  team: Array<DonationFormFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectqMtYyH8r = {
  __typename?: 'GetDonationFormsFromCampaignObjectqMTYyH8r';
  error?: Maybe<GetDonationFormsFromCampaignObjectqMtYyH8rErrorObject>;
  object?: Maybe<GetDonationFormsFromCampaignObject>;
};

export type GetDonationFormsFromCampaignObjectqMtYyH8rErrorObject = {
  __typename?: 'GetDonationFormsFromCampaignObjectqMTYyH8rErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum GetEmailDeliveryError {
  BelongToMyOtherOrganization = 'BelongToMyOtherOrganization'
}

export type GetEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'GetEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<GetEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetEphemeralKeyInput = {
  nonce: Scalars['String'];
};

export type GetEphemeralKeyObject = {
  __typename?: 'GetEphemeralKeyObject';
  ephemeralKeySecret: Scalars['String'];
};

export type GetEphemeralKeyObjectd4Ei6dCn = {
  __typename?: 'GetEphemeralKeyObjectd4EI6dCn';
  error?: Maybe<GetEphemeralKeyObjectd4Ei6dCnErrorObject>;
  object?: Maybe<GetEphemeralKeyObject>;
};

export type GetEphemeralKeyObjectd4Ei6dCnErrorObject = {
  __typename?: 'GetEphemeralKeyObjectd4EI6dCnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetFirstTransactionCreatedAtUtcResponseObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObject';
  createdAtUtc?: Maybe<Scalars['Date']>;
  id?: Maybe<Scalars['String']>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjectMqkjiHyu = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjectMqkjiHYU';
  error?: Maybe<GetFirstTransactionCreatedAtUtcResponseObjectMqkjiHyuErrorObject>;
  object?: Maybe<GetFirstTransactionCreatedAtUtcResponseObject>;
};

export type GetFirstTransactionCreatedAtUtcResponseObjectMqkjiHyuErrorObject = {
  __typename?: 'GetFirstTransactionCreatedAtUtcResponseObjectMqkjiHYUErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingCardObject = {
  __typename?: 'GetIssuingCardObject';
  issuingCard?: Maybe<IssuingCardObject>;
};

export type GetIssuingCardObjectgPgLa36H = {
  __typename?: 'GetIssuingCardObjectgPGLa36H';
  error?: Maybe<GetIssuingCardObjectgPgLa36HErrorObject>;
  object?: Maybe<GetIssuingCardObject>;
};

export type GetIssuingCardObjectgPgLa36HErrorObject = {
  __typename?: 'GetIssuingCardObjectgPGLa36HErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetIssuingRewardTransactionsObject = {
  __typename?: 'GetIssuingRewardTransactionsObject';
  amount: Scalars['Float'];
  disputeCount: Scalars['Float'];
  transactionCount: Scalars['Float'];
};

export type GetIssuingRewardTransactionsObjectGmPrtm6A = {
  __typename?: 'GetIssuingRewardTransactionsObjectGmPrtm6A';
  error?: Maybe<GetIssuingRewardTransactionsObjectGmPrtm6AErrorObject>;
  object?: Maybe<GetIssuingRewardTransactionsObject>;
};

export type GetIssuingRewardTransactionsObjectGmPrtm6AErrorObject = {
  __typename?: 'GetIssuingRewardTransactionsObjectGmPrtm6AErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetMarkerHoverDetailsInput = {
  cursorType: CursorType;
  id: Scalars['String'];
};

export type GetMarkersInput = {
  lat: LatLngBoundariesInput;
  lng: LatLngBoundariesInput;
};

export type GetOrganizationSummaryObject = {
  __typename?: 'GetOrganizationSummaryObject';
  address: Scalars['String'];
  lastDonationForm?: Maybe<DonationFormSummaryObject>;
  lastTicketing?: Maybe<TicketingSummaryObject>;
  url?: Maybe<Scalars['String']>;
};

export type GetOrganizationSummaryResponseObject = {
  __typename?: 'GetOrganizationSummaryResponseObject';
  error?: Maybe<GetOrganizationSummaryResponseObjectErrorObject>;
  object?: Maybe<GetOrganizationSummaryObject>;
};

export type GetOrganizationSummaryResponseObjectErrorObject = {
  __typename?: 'GetOrganizationSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetReferredOrganizationInput = {
  organizationId: Scalars['String'];
};

export type GetScheduledPayoutObject = {
  __typename?: 'GetScheduledPayoutObject';
  payout?: Maybe<ScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectGJlROiOd = {
  __typename?: 'GetScheduledPayoutObjectGJlROiOD';
  error?: Maybe<GetScheduledPayoutObjectGJlROiOdErrorObject>;
  object?: Maybe<GetScheduledPayoutObject>;
};

export type GetScheduledPayoutObjectGJlROiOdErrorObject = {
  __typename?: 'GetScheduledPayoutObjectGJlROiODErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type GetTipSuggestionsInput = {
  amount: Scalars['Float'];
  tipModelExperience?: InputMaybe<TipModelExperience>;
};

export type GetTransactionsForIssuingBalanceTransferObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject';
  endDate?: Maybe<Scalars['Date']>;
  volume: Scalars['Float'];
};

export type GetTransactionsForIssuingBalanceTransferObject14WswDh4 = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject14WswDH4';
  error?: Maybe<GetTransactionsForIssuingBalanceTransferObject14WswDh4ErrorObject>;
  object?: Maybe<GetTransactionsForIssuingBalanceTransferObject>;
};

export type GetTransactionsForIssuingBalanceTransferObject14WswDh4ErrorObject = {
  __typename?: 'GetTransactionsForIssuingBalanceTransferObject14WswDH4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HasEmailDeliveryObject = {
  __typename?: 'HasEmailDeliveryObject';
  draft: Scalars['Boolean'];
  pending: Scalars['Boolean'];
  sent: Scalars['Boolean'];
};

export type HasEmailDeliveryObjecti2zjocQv = {
  __typename?: 'HasEmailDeliveryObjecti2zjocQV';
  error?: Maybe<HasEmailDeliveryObjecti2zjocQvErrorObject>;
  object?: Maybe<HasEmailDeliveryObject>;
};

export type HasEmailDeliveryObjecti2zjocQvErrorObject = {
  __typename?: 'HasEmailDeliveryObjecti2zjocQVErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  frequency: FrequencyFilter;
  startDate: Scalars['Date'];
};

export type HomeGraphObject = {
  __typename?: 'HomeGraphObject';
  date: Scalars['Date'];
  volume: Scalars['Int'];
};

export type HomeGraphObjectResponseObjectErrorObject = {
  __typename?: 'HomeGraphObjectResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeGraphResponseListObject = {
  __typename?: 'HomeGraphResponseListObject';
  error?: Maybe<HomeGraphResponseListObjectErrorObject>;
  items?: Maybe<Array<HomeGraphObject>>;
};

export type HomeGraphResponseListObjectErrorObject = {
  __typename?: 'HomeGraphResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type HomeMetricsInput = {
  endDate: Scalars['Date'];
  formIds: Array<Scalars['String']>;
  startDate: Scalars['Date'];
};

export type HubspotProperty = {
  propertyName: Scalars['String'];
  propertyValue: Scalars['String'];
};

export type ImportCheckErrorObject = {
  __typename?: 'ImportCheckErrorObject';
  emptyColumns: Array<Scalars['String']>;
  invalidColumns: Array<Scalars['String']>;
  row: Scalars['Float'];
};

export enum ImportStatus {
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum ImportType {
  Contact = 'Contact',
  Payment = 'Payment'
}

export type ImportUsersGoogleSheetObject = {
  __typename?: 'ImportUsersGoogleSheetObject';
  addedContactCount?: Maybe<Scalars['Float']>;
  addedPaymentCount?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<ImportCheckErrorObject>>;
  id?: Maybe<Scalars['String']>;
  isAsync?: Maybe<Scalars['Boolean']>;
  status?: Maybe<ImportStatus>;
};

export type ImportUsersGoogleSheetObjectR2iHpHe1 = {
  __typename?: 'ImportUsersGoogleSheetObjectR2iHpHe1';
  error?: Maybe<ImportUsersGoogleSheetObjectR2iHpHe1ErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectR2iHpHe1ErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectR2iHpHe1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetObjectluxgj0tL = {
  __typename?: 'ImportUsersGoogleSheetObjectluxgj0tL';
  error?: Maybe<ImportUsersGoogleSheetObjectluxgj0tLErrorObject>;
  object?: Maybe<ImportUsersGoogleSheetObject>;
};

export type ImportUsersGoogleSheetObjectluxgj0tLErrorObject = {
  __typename?: 'ImportUsersGoogleSheetObjectluxgj0tLErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ImportUsersGoogleSheetResponseObjectErrorObject = {
  __typename?: 'ImportUsersGoogleSheetResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IncreasePayoutAmountInput = {
  amount: Scalars['Float'];
  payoutId: Scalars['String'];
};

export type IncreasePayoutAmountObject = {
  __typename?: 'IncreasePayoutAmountObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type IncreasePayoutAmountObjectLuk6D1tQ = {
  __typename?: 'IncreasePayoutAmountObjectLuk6D1tQ';
  error?: Maybe<IncreasePayoutAmountObjectLuk6D1tQErrorObject>;
  object?: Maybe<IncreasePayoutAmountObject>;
};

export type IncreasePayoutAmountObjectLuk6D1tQErrorObject = {
  __typename?: 'IncreasePayoutAmountObjectLuk6D1tQErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type InsertTagInput = {
  tagId: Scalars['String'];
  userId: Scalars['String'];
};

export type IntResponseObject = {
  __typename?: 'IntResponseObject';
  error?: Maybe<IntegerResponseObjectErrorObject>;
  object?: Maybe<Scalars['Int']>;
};

export type IntegerResponseObjectErrorObject = {
  __typename?: 'IntegerResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IsEmailRegisteredInput = {
  email: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
  redirectionUrl?: InputMaybe<Scalars['String']>;
};

export type IsEmailRegisteredSsoInput = {
  googleToken: Scalars['String'];
  locale: Locales;
  recaptchaToken?: InputMaybe<Scalars['String']>;
};

export type IsUserEmailRegisteredObject = {
  __typename?: 'IsUserEmailRegisteredObject';
  fundraiserCreationToken?: Maybe<Scalars['String']>;
  hadPasswordInSimplykV1: Scalars['Boolean'];
  hasPassword: Scalars['Boolean'];
  hasResetPasswordToken: Scalars['Boolean'];
  shouldAuthenticateWithGoogle?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['String']>;
  userRegistrationStatus: UserRegistrationStatus;
};

export type IsUserEmailRegisteredResponseObject = {
  __typename?: 'IsUserEmailRegisteredResponseObject';
  error?: Maybe<IsUserEmailRegisteredResponseObjectErrorObject>;
  object?: Maybe<IsUserEmailRegisteredObject>;
};

export type IsUserEmailRegisteredResponseObjectErrorObject = {
  __typename?: 'IsUserEmailRegisteredResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type IssuingCardObject = {
  __typename?: 'IssuingCardObject';
  cardHolderStatus: CardHolderStatus;
  id: Scalars['String'];
  isCardHolder: Scalars['Boolean'];
  last4?: Maybe<Scalars['String']>;
  status: IssuingCardStatus;
  stripeIssuingCardId?: Maybe<Scalars['String']>;
  treasuryFinancialAccountId?: Maybe<Scalars['String']>;
};

export enum IssuingCardStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type LatLngBoundariesInput = {
  max: Scalars['Float'];
  min: Scalars['Float'];
};

export type ListTransactionTicketsObject = {
  __typename?: 'ListTransactionTicketsObject';
  allCanceled: Scalars['Boolean'];
  productTickets: Array<ProductTicketObject>;
};

export type ListTransactionTicketsObjectErrorObject = {
  __typename?: 'ListTransactionTicketsObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ListTransactionTicketsResponse = {
  __typename?: 'ListTransactionTicketsResponse';
  error?: Maybe<ListTransactionTicketsObjectErrorObject>;
  object?: Maybe<ListTransactionTicketsObject>;
};

export enum Locales {
  EN = 'EN',
  ES = 'ES',
  FR = 'FR'
}

export type ManualContactInput = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  corporationName?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  note?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  tagIds?: InputMaybe<Array<Scalars['String']>>;
};

export type MarkerHoverDetailsObject = {
  __typename?: 'MarkerHoverDetailsObject';
  color?: Maybe<Scalars['String']>;
  cursorType: CursorType;
  dates: Array<EventDateRange>;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type MarkerPositionObject = {
  __typename?: 'MarkerPositionObject';
  coordinates: CoordinatesObject;
  cursorType: CursorType;
  id: Scalars['String'];
};

export type MarkerPositionResponseObjectErrorObject = {
  __typename?: 'MarkerPositionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum MembershipValidityPeriod {
  AtDate = 'AtDate',
  NoExpiration = 'NoExpiration',
  OneMonthAfterPayment = 'OneMonthAfterPayment',
  OneYearAfterPayment = 'OneYearAfterPayment'
}

export enum MergeUsersError {
  ErrorCreatingAccessToken = 'ErrorCreatingAccessToken',
  TokenDoesNotMatch = 'TokenDoesNotMatch',
  UserNotFound = 'UserNotFound'
}

export type MergeUsersObject = {
  __typename?: 'MergeUsersObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type MergeUsersObjectnPosEuxo = {
  __typename?: 'MergeUsersObjectnPosEUXO';
  error?: Maybe<MergeUsersObjectnPosEuxoErrorObject>;
  object?: Maybe<MergeUsersObject>;
};

export type MergeUsersObjectnPosEuxoErrorObject = {
  __typename?: 'MergeUsersObjectnPosEUXOErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectLabelObject = {
  __typename?: 'MultiselectLabelObject';
  label: Scalars['String'];
  locale: Locales;
};

export type MultiselectOccurrenceValueObject = {
  __typename?: 'MultiselectOccurrenceValueObject';
  endUtc: Scalars['Date'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  value: Scalars['String'];
};

export type MultiselectOccurrenceValueObjectjzakKado = {
  __typename?: 'MultiselectOccurrenceValueObjectjzakKADO';
  error?: Maybe<MultiselectOccurrenceValueObjectjzakKadoErrorObject>;
  items?: Maybe<Array<MultiselectOccurrenceValueObject>>;
};

export type MultiselectOccurrenceValueObjectjzakKadoErrorObject = {
  __typename?: 'MultiselectOccurrenceValueObjectjzakKADOErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObject = {
  __typename?: 'MultiselectValueObject';
  labels: Array<MultiselectLabelObject>;
  value: Scalars['String'];
};

export type MultiselectValueObjectLf4Gy4Gf = {
  __typename?: 'MultiselectValueObjectLF4Gy4GF';
  error?: Maybe<MultiselectValueObjectLf4Gy4GfErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectLf4Gy4GfErrorObject = {
  __typename?: 'MultiselectValueObjectLF4Gy4GFErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueObjectM2EgmjFg = {
  __typename?: 'MultiselectValueObjectM2EGMJFg';
  error?: Maybe<MultiselectValueObjectM2EgmjFgErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueObjectM2EgmjFgErrorObject = {
  __typename?: 'MultiselectValueObjectM2EGMJFgErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MultiselectValueResponseListObject = {
  __typename?: 'MultiselectValueResponseListObject';
  error?: Maybe<MultiselectValueResponseListObjectErrorObject>;
  items?: Maybe<Array<MultiselectValueObject>>;
};

export type MultiselectValueResponseListObjectErrorObject = {
  __typename?: 'MultiselectValueResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCardIssuing: Boolean7E0VjIma;
  activateDonationForm: DonationFormObjectMnocBrSk;
  activateTicketing: TicketingObjectndLe5Xbm;
  activeTwoFactorAuth: BooleanzPKle7mX;
  addEngagementStepChecked: Booleannjd8Bwnz;
  addManualContact: StringNtAz8ud1;
  addRetroActiveReferral: Boolean9RWi3rlD;
  addTicketingOffset: BooleanWo8FwD6o;
  archiveCampaign: NumberW24AcHu3;
  archiveDonationForm: BooleaniXo1NrsV;
  archiveTeam: BooleanXs6OvL2l;
  archiveTicketing: BooleanN0nRllVn;
  cancelCommandSubscription: CommandObjectocQ2EhGu;
  cancelDonorCommandSubscription: CommandObjectFe1AeXcx;
  cancelReceipt: ReceiptObjectT3UqEUzT;
  capturePaymentIntent: BooleanResponseObject;
  changeContactEmailSubscription: BooleankTyp9K2Z;
  checkinProductTicket: BooleanyFpyr8mA;
  checkinProductTicketByCommandId: BooleanpdRavcui;
  closeTicketing: Boolean3a6siEmo;
  confirmPaymentIntent: CommandObjectJtn7oZyc;
  createBalanceTransferToIssuing: Boolean7As6d6d8;
  createDonationFormFromTemplate: StringqjmoC9hM;
  createDonorFormTranslations: DonationFormObjectomSdd6Qx;
  createDraftCampaign: DonationFormObjectVv0hhd1g;
  createDraftDonationForm: DonationFormObjectiRzO0pph;
  createDraftTicketing: String6RlPapQt;
  createFormTranslations: DonationFormObjectJyoSIyao;
  createFrontendDonor: UserResponseObject;
  createFundraiserFormTranslations: DonationFormObjecthuYuuJax;
  createIssuingPayout: CreatedIssuingPayoutObjectU4ZpCQbF;
  createIssuingTopup: BooleanykGojcs0;
  createNewOrganization: AccessTokenObject2cvVeuxy;
  createNote: NoteResponseObject;
  createPlaidLinkToken: StringtxiVhXd7;
  createPreviewReceipt: StringLdTQt2Sx;
  createReceipt: ReceiptResponseObject;
  createSampleContact: ContactObjectFgzwbId8;
  createSampleTransaction: TransactionObjecthEGc9Bs6;
  createStripeCheckoutSession: CheckoutSessionResponseObject;
  createStripeCustomExternalAccount: ExternalAccountObjectjAlYjvPg;
  createTags: StringListResponseObject;
  createTicketingCardPaymentIntent: PaymentIntentResponseObject;
  deactivateOrganization: BooleanResponseObject;
  deactiveTwoFactorAuth: Booleanrpq0qrAa;
  deleteCampaign: Number4qDclwzj;
  deleteContact: BooleanResponseObject;
  deleteDonationForm: BooleanLqXzvFi3;
  deleteEmailTemplate: EmailTemplateResponseObject;
  deleteMemberToOrganizationAdministrators: UserObjectmItv4Guk;
  deleteNote?: Maybe<StringResponseObject>;
  deleteSampleContact: BooleanXxUsgHwH;
  deleteSampleTransaction: Booleang0yP2tSk;
  deleteTagForAllUsers: BooleanResponseObject;
  deleteTags: BooleanResponseObject;
  deleteTeam: Boolean7iIn3qUo;
  deleteTicketing: BooleanxXvRc5x6;
  deleteUser: BooleanuLp37Rv4;
  disableGoogleSsoLogin: BooleanydqCt1dj;
  dismissActivationPayoutSuccessCard: Booleanv7Y95wRh;
  displayEngagementChecklist: Boolean1YehSjhm;
  editAnswer: AnswerResponseObject;
  editCommandSubscriptionAmount: CommandResponseObject;
  editContact: ContactResponseObject;
  editContactEmail: UserObjectQyOkCm40;
  editDonationCommandForm: CommandResponseObject;
  editDonorCommandSubscriptionAmount: CommandResponseObject;
  editDonorFormAdvancedParameters: DonationFormObjectrIhTciEi;
  editDonorFormCustomization: DonationFormObject0ilPrdGq;
  editDonorFormGeneralInformations: DonationFormObjectCk0yofJa;
  editFormAdvancedParameters: DonationFormObjectaUtkzfnq;
  editFormCustomization: DonationFormObjectjfwzhnna;
  editFormGeneralInformations: DonationFormObjecticQdf1B3;
  editFundraiserFormAdvancedParameters: DonationFormObjectYtGzpB2l;
  editFundraiserFormCustomization: DonationFormObjects8wrx1lI;
  editFundraiserFormGeneralInformations: DonationFormObjectmS8VIsp7;
  editNote?: Maybe<NoteResponseObject>;
  editPassword: UserObjectbNrxFwOf;
  editTag: BooleanResponseObject;
  editTicketingAdvancedParameters: TicketingObjecttbAv9Pi9;
  editTicketingEventCustomization: TicketingObjectBoVxggEn;
  editTicketingGeneralInformation: TicketingObjectgKfUdqtj;
  editTicketingRates: TicketingObjectPtVpH1Qj;
  editTicketingReminderData: TicketingObjectvKFvsdP4;
  editUnsubscribePreferences: BooleanWyNt1Pq1;
  exportBalanceTransactionsTable: ExportResponseObject;
  exportDonorReceiptTable: ExportResponseObject;
  exportFundraiserList: ExportObjectk2tpMzaw;
  exportPayoutsTable: ExportResponseObject;
  exportTransactionsTable: ExportResponseObject;
  generatePreviewETicket: StringResponseObject;
  getCannyToken: StringResponseObject;
  getDiscountCodeFromTicketingIdAndBody: DiscountResponseObject;
  increasePayoutAmount: IncreasePayoutAmountObjectLuk6D1tQ;
  insertTags: BooleanResponseObject;
  isUserEmailRegistered: IsUserEmailRegisteredResponseObject;
  isUserEmailRegisteredSSO: AccessTokenObjectY09Gbq75;
  launchImport: ImportUsersGoogleSheetObjectluxgj0tL;
  mergeTeams: BooleantjWOvzl8;
  mergeUsers: MergeUsersObjectnPosEuxo;
  moveParticipantToCampaign: BooleanXZlgU2iP;
  openTicketing: TicketingObjectDmLu9yBy;
  postSubmitCommand: PostSubmitCommandObjectD908PHmf;
  processIssuingRewards: BooleanwGSdw7Mp;
  processPayout: BooleanpDvltcmj;
  purgeAllCache: BooleancbEvThE9;
  reOpenCommand: BooleanBqmdIsZs;
  refundTip: TransactionObject4PaaEapc;
  refundTransaction: RefundResponseObject;
  removeEngagementChecklist: BooleansfhS8031;
  requestIssuingCard: BooleanOiNxs87M;
  resendTransactionReceipt?: Maybe<StringResponseObject>;
  resendTwoFactorCode: BooleanCElyvdzW;
  resetEngagementStepChecked: BooleanZy7wUJrY;
  resetPassword: AccessTokenObjectfnKAopFi;
  reverseDonationAnonymous: BooleanH0OxArof;
  saveCardOrder: CreateOrderResponseObject;
  saveDonationThankYouComment: CommandResponseObject;
  saveV1MobileCommand: CreateMobileCommandResponseObject;
  scanTicket: ProductTicketObjectgbhHErXs;
  sendMergeUserRequest: SendMergeUsersRequestResponseObject;
  sendMonthlyBalanceTransactionExport: BooleanmoVrDfbF;
  sendResetPasswordRequest: ResetPasswordLinkResponseObject;
  sendResetPasswordRequestMobile: ResetPasswordLinkResponseObject;
  sendTransactionReceiptForExistingCommand: CommandResponseObject;
  sendTwoFactorCode: BooleanzoA3mJcm;
  sendVerifyEmailNotification: BooleanIsrV0Dzd;
  setHasDismissedEmailInfobox: BooleanAnG7gEyA;
  setHasDismissedEmailTemplatePrompt: BooleanMc9iwb7l;
  setHasDismissedReferralPrompt: BooleanOr09PgbC;
  setHasSeenReferralPage: BooleanResponseObject;
  setUserAsOnboardedOnCheckinManager: Boolean0q9qEZh1;
  setUserCurrentOrganization: AccessTokenObjectk0IhZEil;
  setUserCurrentOrganizationMobile: AccessTokenObject;
  setVolumeThresholdReached: OrganizationResponseObject;
  signIn: AccessTokenObject6O99q3S5;
  signInAsAdministrator: AccessTokenObject3Y8Wgxzu;
  signInMobile?: Maybe<AccessTokenObject>;
  signInMobileWithGoogle?: Maybe<AccessTokenObject>;
  signOut: Boolean0sRyUq5m;
  signUp: AccessTokenObjectKzry1eZi;
  switchOrgAsAdministrator: AccessTokenResponseObject;
  testModeAutomateOnboardingToStripe: BooleanResponseObject;
  trackDownload?: Maybe<Scalars['Boolean']>;
  triggerManualCron: Boolean7Exs2dD5;
  unarchiveCampaign: NumberdJ5uAmux;
  unarchiveDonationForm: Booleand1E5onMb;
  unarchiveTicketing: Boolean9J3TtTi7;
  unsubscribe: ContactResponseObject;
  unsubscribeUserFromEmails: Boolean2SkSe3Op;
  updateAccessTokenWithTargetedOrganization: BooleantacDp2Xq;
  updateAdministrativeInformation: OrganizationObjecteIcmCyCg;
  updateAdministrativeMoreInformation: OrganizationObjectlQ8IvFzt;
  updateAmountsFromToken: StringVrhNiTsB;
  updateCompanyOnboardingQualification: BooleanResponseObject;
  updateHubspotContactProperty: BooleandS6SiFSc;
  updateOrganizationCategory: BooleanResponseObject;
  updateOrganizationEngagementFlags: BooleanResponseObject;
  updateOrganizationFlags: BooleanPweUhzGw;
  updateOrganizationHubspotProperty: BooleanResponseObject;
  updateOrganizationOnboardedFormCategory: BooleannSWzjc1s;
  updateOrganizationWithStripeCustomFlowHasStarted: BooleankAanbEoe;
  updatePaymentIntent: UpdatePaymentIntentResponse;
  updatePayoutInterval: StripeAccountResponseObject;
  updatePersonalInformation: UpdatePersonalInformationResponseObject;
  updateProductDonationAnnotation: BooleantSWgztpv;
  updateProductDonationAnonymous: Booleanc0uSnYmn;
  updateProductTicketAnnotation: BooleanResponseObject;
  uploadChunkForImport: ChunkImportObjectgGlTRkhA;
  upsertSession: SessionResponseObject;
  verifyEmail: VerifyEmailObjectTjjN5j3w;
  verifyTwoFactorCode: BooleanQlq05Cx9;
};


export type MutationActivateDonationFormArgs = {
  activateDonationFormInput: ActivateDonationFormInput;
};


export type MutationActivateTicketingArgs = {
  activateTicketingInput: ActivateTicketingInput;
};


export type MutationAddEngagementStepCheckedArgs = {
  activationStepId: Scalars['String'];
};


export type MutationAddManualContactArgs = {
  manualContactInput: ManualContactInput;
};


export type MutationAddRetroActiveReferralArgs = {
  retroActiveReferralInput: RetroActiveReferralInput;
};


export type MutationAddTicketingOffsetArgs = {
  offset: Scalars['Int'];
  ticketingIdOrPath: Scalars['String'];
};


export type MutationArchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationArchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationArchiveTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationArchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationCancelCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelDonorCommandSubscriptionArgs = {
  id: Scalars['String'];
};


export type MutationCancelReceiptArgs = {
  receiptId: Scalars['String'];
};


export type MutationCapturePaymentIntentArgs = {
  paymentIntentId: Scalars['String'];
};


export type MutationChangeContactEmailSubscriptionArgs = {
  unsubscribe: Scalars['Boolean'];
  userEmails: Array<Scalars['String']>;
};


export type MutationCheckinProductTicketArgs = {
  isCheckin: Scalars['Boolean'];
  productTicketId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheckinProductTicketByCommandIdArgs = {
  commandId: Scalars['String'];
  isCheckin: Scalars['Boolean'];
  token: Scalars['String'];
};


export type MutationCloseTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationConfirmPaymentIntentArgs = {
  confirmPaymentIntentInput: ConfirmPaymentIntentInput;
};


export type MutationCreateBalanceTransferToIssuingArgs = {
  input: ProcessBalanceTransferInput;
};


export type MutationCreateDonationFormFromTemplateArgs = {
  template: DonationFormTemplateType;
};


export type MutationCreateDonorFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateDraftCampaignArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftDonationFormArgs = {
  donationForm: CreateDraftDonationFormInput;
};


export type MutationCreateDraftTicketingArgs = {
  ticketing: CreateTicketingInput;
};


export type MutationCreateFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
};


export type MutationCreateFrontendDonorArgs = {
  donor: DonorInput;
};


export type MutationCreateFundraiserFormTranslationsArgs = {
  donationFormId: Scalars['String'];
  donationFormTranslations: EditDonationFormTranslationsInput;
  token: Scalars['String'];
};


export type MutationCreateIssuingPayoutArgs = {
  input: CreatedIssuingPayoutInput;
};


export type MutationCreateIssuingTopupArgs = {
  input: CreateIssuingTopupInput;
};


export type MutationCreateNewOrganizationArgs = {
  newOrganizationInput: CreateNewOrganizationInput;
};


export type MutationCreateNoteArgs = {
  content: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationCreatePreviewReceiptArgs = {
  previewReceiptParams: PreviewReceiptParams;
};


export type MutationCreateReceiptArgs = {
  receipt: CreateReceiptInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  customerId: Scalars['String'];
  subscriptionId: Scalars['String'];
};


export type MutationCreateStripeCustomExternalAccountArgs = {
  externalAccountInput: ExternalAccountInput;
};


export type MutationCreateTagsArgs = {
  tag: CreateTagInput;
};


export type MutationCreateTicketingCardPaymentIntentArgs = {
  paymentIntent: TicketingPaymentIntentInput;
};


export type MutationDeactivateOrganizationArgs = {
  input: DeactivateAccountInput;
};


export type MutationDeactiveTwoFactorAuthArgs = {
  param: DeactiveTwoFactorAuthInput;
};


export type MutationDeleteCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationDeleteContactArgs = {
  userId: Scalars['String'];
};


export type MutationDeleteDonationFormArgs = {
  donationFormId: Scalars['String'];
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
};


export type MutationDeleteEmailTemplateArgs = {
  id: Scalars['String'];
};


export type MutationDeleteMemberToOrganizationAdministratorsArgs = {
  email: Scalars['String'];
};


export type MutationDeleteNoteArgs = {
  noteId: Scalars['String'];
};


export type MutationDeleteSampleContactArgs = {
  contactId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteSampleTransactionArgs = {
  transactionId?: InputMaybe<Scalars['String']>;
};


export type MutationDeleteTagForAllUsersArgs = {
  tagId: Scalars['String'];
};


export type MutationDeleteTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationDeleteTeamArgs = {
  teamId: Scalars['String'];
};


export type MutationDeleteTicketingArgs = {
  onlyDraft?: InputMaybe<Scalars['Boolean']>;
  ticketingId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  input: DeleteUserAccountInput;
};


export type MutationDisableGoogleSsoLoginArgs = {
  userEmail: Scalars['String'];
};


export type MutationEditAnswerArgs = {
  answerInput: AnswerInput;
};


export type MutationEditCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditContactArgs = {
  editContactInput: EditContactInput;
  userId: Scalars['String'];
};


export type MutationEditContactEmailArgs = {
  email: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationEditDonationCommandFormArgs = {
  donationFormId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationEditDonorCommandSubscriptionAmountArgs = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationEditDonorFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditDonorFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
};


export type MutationEditFundraiserFormAdvancedParametersArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormCustomizationArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditFundraiserFormGeneralInformationsArgs = {
  donationForm: EditDonationFormInput;
  token: Scalars['String'];
};


export type MutationEditNoteArgs = {
  content: Scalars['String'];
  noteId: Scalars['String'];
};


export type MutationEditPasswordArgs = {
  editPasswordInput: EditPasswordInput;
};


export type MutationEditTagArgs = {
  tag: EditTagInput;
};


export type MutationEditTicketingAdvancedParametersArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingEventCustomizationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingGeneralInformationArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingRatesArgs = {
  ticketing: EditTicketingInput;
};


export type MutationEditTicketingReminderDataArgs = {
  data: EditReminderDataInput;
};


export type MutationEditUnsubscribePreferencesArgs = {
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
};


export type MutationExportBalanceTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<BalanceTransactionExportFilter>;
};


export type MutationExportDonorReceiptTableArgs = {
  exportParameters: ExportParametersInput;
  year: Scalars['Float'];
};


export type MutationExportFundraiserListArgs = {
  donationFormId: Scalars['String'];
  exportParameters: ExportParametersInput;
};


export type MutationExportPayoutsTableArgs = {
  exportParameters: ExportParametersInput;
};


export type MutationExportTransactionsTableArgs = {
  exportParameters: ExportParametersInput;
  parameters?: InputMaybe<TransactionExportFilter>;
};


export type MutationGeneratePreviewETicketArgs = {
  ticketPreviewParams: PreviewTicketParams;
};


export type MutationGetDiscountCodeFromTicketingIdAndBodyArgs = {
  discountCodeBody: Scalars['String'];
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type MutationIncreasePayoutAmountArgs = {
  input: IncreasePayoutAmountInput;
};


export type MutationInsertTagsArgs = {
  tags: Array<InsertTagInput>;
};


export type MutationIsUserEmailRegisteredArgs = {
  isEmailRegisteredInput: IsEmailRegisteredInput;
};


export type MutationIsUserEmailRegisteredSsoArgs = {
  isEmailRegisteredSSOInput: IsEmailRegisteredSsoInput;
};


export type MutationLaunchImportArgs = {
  base64: Scalars['String'];
  rowNum: Scalars['Float'];
  type: ImportType;
};


export type MutationMergeTeamsArgs = {
  teamToBeMergedIdOrPath: Scalars['String'];
  teamToMergeInIdOrPath: Scalars['String'];
};


export type MutationMergeUsersArgs = {
  encryptedToken: Scalars['String'];
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationMoveParticipantToCampaignArgs = {
  participantIdOrPath: Scalars['String'];
  targetedTeamIdOrPath: Scalars['String'];
};


export type MutationOpenTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationPostSubmitCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationProcessPayoutArgs = {
  payoutId: Scalars['String'];
};


export type MutationReOpenCommandArgs = {
  commandId: Scalars['String'];
};


export type MutationRefundTipArgs = {
  amountToRefund: Scalars['Float'];
  id: Scalars['String'];
};


export type MutationRefundTransactionArgs = {
  amountToRefund: Scalars['Float'];
  cancelTaxReceipt: Scalars['Boolean'];
  id: Scalars['String'];
};


export type MutationRequestIssuingCardArgs = {
  cardHolderInput: CreateCardHolderInput;
};


export type MutationResendTransactionReceiptArgs = {
  email: Scalars['String'];
  transactionId: Scalars['String'];
};


export type MutationResendTwoFactorCodeArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationResetPasswordArgs = {
  resetPasswordInput: ResetPasswordInput;
};


export type MutationReverseDonationAnonymousArgs = {
  donationFormIdOrPath: Scalars['String'];
  donorEmail: Scalars['String'];
};


export type MutationSaveCardOrderArgs = {
  editSessionInput: EditSessionOnSubmission;
  order: CreateOrderInput;
  ticketingId: Scalars['String'];
  tickets: Array<CreateTicketInput>;
};


export type MutationSaveDonationThankYouCommentArgs = {
  commandId: Scalars['String'];
  thankYouComment: Scalars['String'];
};


export type MutationSaveV1MobileCommandArgs = {
  createMobileCommandInput: CreateMobileCommandInput;
};


export type MutationScanTicketArgs = {
  ticketIdInput: UuidInput;
};


export type MutationSendMergeUserRequestArgs = {
  newEmail: Scalars['String'];
  oldEmail: Scalars['String'];
};


export type MutationSendResetPasswordRequestArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendResetPasswordRequestMobileArgs = {
  sendResetPasswordRequestInput: SendResetPasswordRequestInput;
};


export type MutationSendTransactionReceiptForExistingCommandArgs = {
  commandId: Scalars['String'];
  email: Scalars['String'];
};


export type MutationSendVerifyEmailNotificationArgs = {
  redirectionUrl?: InputMaybe<Scalars['String']>;
};


export type MutationSetUserCurrentOrganizationArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetUserCurrentOrganizationMobileArgs = {
  organizationId: Scalars['String'];
};


export type MutationSetVolumeThresholdReachedArgs = {
  volumeThresholdReachedInput: UpdateVolumeThresholdReached;
};


export type MutationSignInArgs = {
  signInInput: SignInFrontendInput;
};


export type MutationSignInAsAdministratorArgs = {
  signInAsAdministratorInput: SignInAsAdministratorInput;
};


export type MutationSignInMobileArgs = {
  signInInput: SignInInput;
};


export type MutationSignInMobileWithGoogleArgs = {
  googleToken: Scalars['String'];
};


export type MutationSignUpArgs = {
  signUpInput: SignUpInput;
};


export type MutationSwitchOrgAsAdministratorArgs = {
  switchOrgAsAdministratorInput: SwitchOrgAsAdministratorInput;
};


export type MutationTrackDownloadArgs = {
  location: Scalars['String'];
};


export type MutationTriggerManualCronArgs = {
  cronName: CronQueues;
  delay?: InputMaybe<Scalars['Int']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUnarchiveCampaignArgs = {
  campaignId: Scalars['String'];
};


export type MutationUnarchiveDonationFormArgs = {
  donationFormId: Scalars['String'];
};


export type MutationUnarchiveTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type MutationUnsubscribeArgs = {
  contactId: Scalars['String'];
  emailDeliveryId: Scalars['String'];
};


export type MutationUnsubscribeUserFromEmailsArgs = {
  organizationId: Scalars['String'];
  unsubscribePreferenceInput: UnsubscribePreferenceInput;
  userId: Scalars['String'];
};


export type MutationUpdateAccessTokenWithTargetedOrganizationArgs = {
  targetOrganizationId?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateAdministrativeInformationArgs = {
  updateAdministrativeInformationInput: UpdateAdministrativeInformationInput;
};


export type MutationUpdateAdministrativeMoreInformationArgs = {
  updateAdministrativeMoreInformationInput: UpdateAdministrativeMoreInformationInput;
};


export type MutationUpdateAmountsFromTokenArgs = {
  token: Scalars['String'];
};


export type MutationUpdateCompanyOnboardingQualificationArgs = {
  qualificationInput: OnboardingQualificationInput;
};


export type MutationUpdateHubspotContactPropertyArgs = {
  payload: HubspotProperty;
};


export type MutationUpdateOrganizationCategoryArgs = {
  category: OrganizationCategory;
};


export type MutationUpdateOrganizationEngagementFlagsArgs = {
  updateOrganizationEngagementFlagsInput: UpdateOrganizationEngagementFlagsInput;
};


export type MutationUpdateOrganizationFlagsArgs = {
  input: OrganizationFlagsInput;
};


export type MutationUpdateOrganizationHubspotPropertyArgs = {
  input: UpdateOrganizationHubspotPropertyInput;
};


export type MutationUpdateOrganizationOnboardedFormCategoryArgs = {
  onboardedFormCategory: FormCategory;
};


export type MutationUpdatePaymentIntentArgs = {
  params: UpdatePaymentIntentInput;
};


export type MutationUpdatePayoutIntervalArgs = {
  updatePayoutInterval: UpdatePayoutInterval;
};


export type MutationUpdatePersonalInformationArgs = {
  updatePersonalInformationInput: UpdatePersonalInformationInput;
};


export type MutationUpdateProductDonationAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productDonationId: Scalars['String'];
};


export type MutationUpdateProductDonationAnonymousArgs = {
  productDonationId: Scalars['String'];
  value?: InputMaybe<Scalars['Boolean']>;
};


export type MutationUpdateProductTicketAnnotationArgs = {
  annotation?: InputMaybe<Scalars['String']>;
  productTicketId: Scalars['String'];
};


export type MutationUploadChunkForImportArgs = {
  chunk: Scalars['String'];
  chunkIndex: Scalars['Float'];
  importId?: InputMaybe<Scalars['String']>;
  isLastChunk: Scalars['Boolean'];
  type: ImportType;
};


export type MutationUpsertSessionArgs = {
  sessionInput: UpsertSessionInput;
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};


export type MutationVerifyTwoFactorCodeArgs = {
  value: Scalars['String'];
};

export type MyReferralOrganizationObject = {
  __typename?: 'MyReferralOrganizationObject';
  count: Scalars['Float'];
  email: Scalars['String'];
  id: Scalars['String'];
  isStripeCustomAccountActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type MyReferralOrganizationObjectO2UbGxcu = {
  __typename?: 'MyReferralOrganizationObjectO2UbGxcu';
  error?: Maybe<MyReferralOrganizationObjectO2UbGxcuErrorObject>;
  items?: Maybe<Array<MyReferralOrganizationObject>>;
};

export type MyReferralOrganizationObjectO2UbGxcuErrorObject = {
  __typename?: 'MyReferralOrganizationObjectO2UbGxcuErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type MyReferrerOrganizationObject = {
  __typename?: 'MyReferrerOrganizationObject';
  moneyGathered: Scalars['Float'];
  organization: OrganizationObject;
};

export type MyReferrerOrganizationResponseObject = {
  __typename?: 'MyReferrerOrganizationResponseObject';
  error?: Maybe<MyReferrerOrganizationResponseObjectErrorObject>;
  object?: Maybe<MyReferrerOrganizationObject>;
};

export type MyReferrerOrganizationResponseObjectErrorObject = {
  __typename?: 'MyReferrerOrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NewNonprofitObject = {
  __typename?: 'NewNonprofitObject';
  city?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  einBn: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type NewNonprofitObjectWsYiwEJs = {
  __typename?: 'NewNonprofitObjectWSYiwEJs';
  error?: Maybe<NewNonprofitObjectWsYiwEJsErrorObject>;
  items?: Maybe<Array<NewNonprofitObject>>;
};

export type NewNonprofitObjectWsYiwEJsErrorObject = {
  __typename?: 'NewNonprofitObjectWSYiwEJsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum NewSubscriptionStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  Created = 'Created',
  PastDue = 'PastDue'
}

export type NextActionObject = {
  __typename?: 'NextActionObject';
  paymentIntentSecret?: Maybe<Scalars['String']>;
  redirectToUrl?: Maybe<RedirectToUrlObject>;
  type?: Maybe<NextActionType>;
};

export enum NextActionType {
  OpenAchModal = 'OpenAchModal',
  OpenPadModal = 'OpenPadModal',
  ThreeDSecureRedirect = 'ThreeDSecureRedirect'
}

export type NoteObject = {
  __typename?: 'NoteObject';
  contactId: Scalars['String'];
  content: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  writer?: Maybe<UserObject>;
  writerId: Scalars['String'];
};

export type NoteResponseListObject = {
  __typename?: 'NoteResponseListObject';
  error?: Maybe<NoteResponseListObjectErrorObject>;
  items?: Maybe<Array<NoteObject>>;
};

export type NoteResponseListObjectErrorObject = {
  __typename?: 'NoteResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NoteResponseObject = {
  __typename?: 'NoteResponseObject';
  error?: Maybe<NoteResponseObjectErrorObject>;
  object?: Maybe<NoteObject>;
};

export type NoteResponseObjectErrorObject = {
  __typename?: 'NoteResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Number4qDclwzj = {
  __typename?: 'Number4qDclwzj';
  error?: Maybe<Number4qDclwzjErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type Number4qDclwzjErrorObject = {
  __typename?: 'Number4qDclwzjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberJlWeqpBr = {
  __typename?: 'NumberJlWEQPBr';
  error?: Maybe<NumberJlWeqpBrErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberJlWeqpBrErrorObject = {
  __typename?: 'NumberJlWEQPBrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObject = {
  __typename?: 'NumberResponseObject';
  error?: Maybe<NumberResponseObjectErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberResponseObjectErrorObject = {
  __typename?: 'NumberResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberResponseObjectXpouhtUy = {
  __typename?: 'NumberResponseObjectXpouhtUY';
  error?: Maybe<NumberResponseObjectXpouhtUyErrorObject>;
  object?: Maybe<NumberResponseObject>;
};

export type NumberResponseObjectXpouhtUyErrorObject = {
  __typename?: 'NumberResponseObjectXpouhtUYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberUu07ugP9 = {
  __typename?: 'NumberUU07ugP9';
  error?: Maybe<NumberUu07ugP9ErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberUu07ugP9ErrorObject = {
  __typename?: 'NumberUU07ugP9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberW24AcHu3 = {
  __typename?: 'NumberW24AcHu3';
  error?: Maybe<NumberW24AcHu3ErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberW24AcHu3ErrorObject = {
  __typename?: 'NumberW24AcHu3ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type NumberdJ5uAmux = {
  __typename?: 'NumberdJ5uAMUX';
  error?: Maybe<NumberdJ5uAmuxErrorObject>;
  object?: Maybe<Scalars['Float']>;
};

export type NumberdJ5uAmuxErrorObject = {
  __typename?: 'NumberdJ5uAMUXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesListResponseObjectErrorObject = {
  __typename?: 'OccurrenceWithRatesListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OccurrenceWithRatesObject = {
  __typename?: 'OccurrenceWithRatesObject';
  occurrence?: Maybe<TicketingOccurrenceObject>;
  rates: Array<RateByOccurrenceObject>;
};

export enum OnboardingQualification {
  Large = 'Large',
  Medium = 'Medium',
  Other = 'Other',
  Small = 'Small'
}

export type OnboardingQualificationInput = {
  onboardingQualification: OnboardingQualification;
};

export type OrderObject = {
  __typename?: 'OrderObject';
  address?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  answers?: Maybe<Array<AnswerObject>>;
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  createdCampaigns?: Maybe<CreatedCampaignsObject>;
  description?: Maybe<Scalars['String']>;
  discount?: Maybe<DiscountObject>;
  discountId?: Maybe<Scalars['String']>;
  eTicketUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extraDonation?: Maybe<Scalars['Float']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  lastTransactionId?: Maybe<Scalars['String']>;
  locale: Locales;
  occurrence?: Maybe<TicketingOccurrenceObject>;
  occurrenceId?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  ticketing: TicketingObject;
  ticketingId: Scalars['String'];
  tickets?: Maybe<Array<TicketObject>>;
  tip: Scalars['Float'];
  transactions?: Maybe<Array<TransactionObject>>;
  user?: Maybe<UserObject>;
  userId?: Maybe<Scalars['String']>;
};

export type OrderResponseObjectErrorObject = {
  __typename?: 'OrderResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationAndOwnerObject = {
  __typename?: 'OrganizationAndOwnerObject';
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['String'];
  role: Scalars['String'];
  userId: Scalars['String'];
};

export type OrganizationAndOwnerObjectAKkkbKms = {
  __typename?: 'OrganizationAndOwnerObjectAKkkbKMS';
  error?: Maybe<OrganizationAndOwnerObjectAKkkbKmsErrorObject>;
  items?: Maybe<Array<OrganizationAndOwnerObject>>;
};

export type OrganizationAndOwnerObjectAKkkbKmsErrorObject = {
  __typename?: 'OrganizationAndOwnerObjectAKkkbKMSErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationCategory {
  AnimalWelfare = 'AnimalWelfare',
  CommunityService = 'CommunityService',
  Culture = 'Culture',
  Education = 'Education',
  Environment = 'Environment',
  Health = 'Health',
  Humanitarian = 'Humanitarian',
  Other = 'Other',
  Politic = 'Politic',
  Religion = 'Religion',
  SocialService = 'SocialService',
  SportsAndLeisure = 'SportsAndLeisure',
  StudentGroup = 'StudentGroup',
  Veterans = 'Veterans'
}

export enum OrganizationCause {
  Education = 'Education',
  Nature = 'Nature',
  Solidarity = 'Solidarity',
  SportAndCulture = 'SportAndCulture'
}

export enum OrganizationCountry {
  Canada = 'Canada',
  GreatBritain = 'GreatBritain',
  UnitedStates = 'UnitedStates'
}

export type OrganizationDetailsObject = {
  __typename?: 'OrganizationDetailsObject';
  closingDate?: Maybe<Scalars['Date']>;
  fundsRaised: Scalars['Float'];
  numberContacts: Scalars['Float'];
  numberForms: Scalars['Float'];
  numberOfReferrals: Scalars['Float'];
  numberPayments: Scalars['Float'];
  numberUserAccounts: Scalars['Float'];
};

export type OrganizationEngagementFlagsObject = {
  __typename?: 'OrganizationEngagementFlagsObject';
  engagementAddContactTour: Scalars['Boolean'];
  engagementContactNotesTour: Scalars['Boolean'];
  engagementContactPaymentsTour: Scalars['Boolean'];
  engagementContactTagsTour: Scalars['Boolean'];
  engagementContactsBulkTour: Scalars['Boolean'];
  engagementEmailRecipientsTour: Scalars['Boolean'];
  engagementEmailStatsTour: Scalars['Boolean'];
  engagementEmailTemplatesTour: Scalars['Boolean'];
  engagementPaymentDetailsTour: Scalars['Boolean'];
  engagementPaymentTour: Scalars['Boolean'];
  engagementPaymentsExportTour: Scalars['Boolean'];
  id: Scalars['String'];
};

export type OrganizationEngagementFlagsObjectPn8be3Zw = {
  __typename?: 'OrganizationEngagementFlagsObjectPN8be3ZW';
  error?: Maybe<OrganizationEngagementFlagsObjectPn8be3ZwErrorObject>;
  object?: Maybe<OrganizationEngagementFlagsObject>;
};

export type OrganizationEngagementFlagsObjectPn8be3ZwErrorObject = {
  __typename?: 'OrganizationEngagementFlagsObjectPN8be3ZWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationFlagsInput = {
  demotivateEmbeds?: InputMaybe<AmplitudeVariantValue>;
};

export type OrganizationGroupObject = {
  __typename?: 'OrganizationGroupObject';
  id: Scalars['String'];
  organization: OrganizationObject;
  organizationId: Scalars['String'];
  permissions: Array<UserPermissions>;
  role: UserRoles;
  user: UserObject;
};

export type OrganizationObject = {
  __typename?: 'OrganizationObject';
  activationChecklistExperiment?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  badTipperHubspotFlag?: Maybe<Scalars['Boolean']>;
  balanceTransferDelayDay: Scalars['Float'];
  bankDescriptor?: Maybe<Scalars['String']>;
  blockPayment: Scalars['Boolean'];
  canGenerateReceipt: Scalars['Boolean'];
  cardMaximumAmount?: Maybe<Scalars['Int']>;
  category?: Maybe<OrganizationCategory>;
  cause?: Maybe<OrganizationCause>;
  charityNumber?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  country: OrganizationCountry;
  createdAtUtc?: Maybe<Scalars['Date']>;
  demotivateEmbeds?: Maybe<AmplitudeVariantValue>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  dismissOnboardingOnFormPageAt?: Maybe<Scalars['Date']>;
  engagementCheckListStepChecked?: Maybe<Array<Scalars['String']>>;
  engagementChecklistExperiment?: Maybe<AmplitudeVariantValue>;
  firstPaymentDate?: Maybe<Scalars['Date']>;
  fourthPaymentDate?: Maybe<Scalars['Date']>;
  fraudulent: Scalars['Boolean'];
  fraudulentAtUtc?: Maybe<Scalars['Date']>;
  hasAdministrativeInformation: Scalars['Boolean'];
  hasAtLeastOnePayment?: Maybe<Scalars['Boolean']>;
  hasCharityNumber: Scalars['Boolean'];
  hasDismissActivationPayoutSuccessCard?: Maybe<Scalars['Boolean']>;
  hasFinishZeffyOnboarding: Scalars['Boolean'];
  hasImportGmailContacts?: Maybe<Scalars['Boolean']>;
  hasImportMailchimpContacts?: Maybe<Scalars['Boolean']>;
  hasStripeCustomFlowStarted: Scalars['Boolean'];
  hasVisitorReports?: Maybe<Scalars['Boolean']>;
  hideFromZeffyMap: Scalars['Boolean'];
  hubspotNpoStarterDealId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isOnboarded?: Maybe<Scalars['Boolean']>;
  isPaymentDisabled?: Maybe<Scalars['Boolean']>;
  isPoBoxAddress?: Maybe<Scalars['Boolean']>;
  isStripeAccountDeprecated?: Maybe<Scalars['Boolean']>;
  isStripeCustomAccountActive: Scalars['Boolean'];
  lastPaymentDate?: Maybe<Scalars['Date']>;
  legalName?: Maybe<Scalars['String']>;
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  npoStarterStateCertificateUrl?: Maybe<Scalars['String']>;
  npoStarterToDoListStepChecked?: Maybe<Array<Scalars['String']>>;
  onboardedFormCategory?: Maybe<FormCategory>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  owner?: Maybe<UserObject>;
  ownerEmail?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  previewFirstFormAt?: Maybe<Scalars['Date']>;
  propublicaCCode?: Maybe<Scalars['Float']>;
  referralAmountLimit: Scalars['Float'];
  referralQuestion?: Maybe<ReferralQuestionChoice>;
  referralQuestionOther?: Maybe<Scalars['String']>;
  referredByOrganizationId?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  signatureUrl?: Maybe<Scalars['String']>;
  stripeCustomAccountActiveAtUtc?: Maybe<Scalars['Date']>;
  stripeCustomAccountId?: Maybe<Scalars['String']>;
  stripeTopupSourceId?: Maybe<Scalars['String']>;
  stripeUserId?: Maybe<Scalars['String']>;
  stripeVerificationSucceededBannerShown?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<TagObject>>;
  type?: Maybe<OrganizationType>;
  volumeThresholdReached?: Maybe<Scalars['Float']>;
  website?: Maybe<Scalars['String']>;
};

export type OrganizationObjecteIcmCyCg = {
  __typename?: 'OrganizationObjecteICMCyCG';
  error?: Maybe<OrganizationObjecteIcmCyCgErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjecteIcmCyCgErrorObject = {
  __typename?: 'OrganizationObjecteICMCyCGErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationObjectlQ8IvFzt = {
  __typename?: 'OrganizationObjectlQ8IvFzt';
  error?: Maybe<OrganizationObjectlQ8IvFztErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationObjectlQ8IvFztErrorObject = {
  __typename?: 'OrganizationObjectlQ8IvFztErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicObject = {
  __typename?: 'OrganizationPublicObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<OwnerObject>;
  phoneNumber?: Maybe<Scalars['String']>;
};

export type OrganizationPublicResponseListObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationPublicResponseObject = {
  __typename?: 'OrganizationPublicResponseObject';
  error?: Maybe<OrganizationPublicResponseObjectErrorObject>;
  object?: Maybe<OrganizationPublicObject>;
};

export type OrganizationPublicResponseObjectErrorObject = {
  __typename?: 'OrganizationPublicResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseListObjectErrorObject = {
  __typename?: 'OrganizationResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type OrganizationResponseObject = {
  __typename?: 'OrganizationResponseObject';
  error?: Maybe<OrganizationResponseObjectErrorObject>;
  object?: Maybe<OrganizationObject>;
};

export type OrganizationResponseObjectErrorObject = {
  __typename?: 'OrganizationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum OrganizationType {
  Charity = 'Charity',
  Other = 'Other',
  Other501C = 'Other501C',
  Private = 'Private',
  Social = 'Social'
}

export type OverTimeFilterInput = {
  slot: OverTimeSlot;
};

export enum OverTimeSlot {
  fourteenDays = 'fourteenDays',
  oneMonth = 'oneMonth',
  sevenDays = 'sevenDays',
  seventyTwoHours = 'seventyTwoHours',
  twentyFourHours = 'twentyFourHours',
  twoHours = 'twoHours'
}

export type OwnerObject = {
  __typename?: 'OwnerObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
};

export type PageViewsSummaryItemObject = {
  __typename?: 'PageViewsSummaryItemObject';
  count: Scalars['Float'];
  time: Scalars['String'];
};

export type PageViewsSummaryObject = {
  __typename?: 'PageViewsSummaryObject';
  data?: Maybe<Array<PageViewsSummaryItemObject>>;
};

export type PageViewsSummaryResponseObject = {
  __typename?: 'PageViewsSummaryResponseObject';
  error?: Maybe<PageViewsSummaryResponseObjectErrorObject>;
  object?: Maybe<PageViewsSummaryObject>;
};

export type PageViewsSummaryResponseObjectErrorObject = {
  __typename?: 'PageViewsSummaryResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentIntentError {
  CardDeclined = 'CardDeclined',
  FormNotFound = 'FormNotFound',
  InsufficientCapabilitiesForTransfer = 'InsufficientCapabilitiesForTransfer',
  InsufficientFunds = 'InsufficientFunds',
  OwnerNotFound = 'OwnerNotFound',
  UnknownError = 'UnknownError'
}

export type PaymentIntentObject = {
  __typename?: 'PaymentIntentObject';
  clientSecret: Scalars['String'];
  paymentIntentId: Scalars['String'];
};

export type PaymentIntentResponseObject = {
  __typename?: 'PaymentIntentResponseObject';
  error?: Maybe<PaymentIntentResponseObjectErrorObject>;
  object?: Maybe<PaymentIntentObject>;
};

export type PaymentIntentResponseObjectErrorObject = {
  __typename?: 'PaymentIntentResponseObjectErrorObject';
  code?: Maybe<PaymentIntentError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum PaymentMethod {
  Ach = 'Ach',
  ApplePayOrGooglePay = 'ApplePayOrGooglePay',
  Card = 'Card',
  Cash = 'Cash',
  CashApp = 'CashApp',
  Cheque = 'Cheque',
  Free = 'Free',
  InKind = 'InKind',
  Manual = 'Manual',
  Other = 'Other',
  Pad = 'Pad',
  Stocks = 'Stocks',
  TapToPay = 'TapToPay',
  Transfer = 'Transfer',
  Unknown = 'Unknown'
}

export enum PaymentStatus {
  AwaitingTransfer = 'AwaitingTransfer',
  Created = 'Created',
  Failed = 'Failed',
  Processing = 'Processing',
  Succeeded = 'Succeeded'
}

export enum PayoutInterval {
  Daily = 'Daily',
  Manual = 'Manual',
  Monthly = 'Monthly',
  Weekly = 'Weekly'
}

export type PayoutObject = {
  __typename?: 'PayoutObject';
  amount: Scalars['Float'];
  arrivalDate: Scalars['Date'];
  createdAt: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  externalAccount: ExternalAccountObject;
  failureCode?: Maybe<Scalars['String']>;
  failureMessage?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  statementDescriptor?: Maybe<Scalars['String']>;
  status: StripePayoutStatus;
};

export type PayoutResponseObject = {
  __typename?: 'PayoutResponseObject';
  error?: Maybe<PayoutResponseObjectErrorObject>;
  object?: Maybe<PayoutObject>;
};

export type PayoutResponseObjectErrorObject = {
  __typename?: 'PayoutResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PayoutsInfiniteListResponseObjectErrorObject = {
  __typename?: 'PayoutsInfiniteListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PostSubmitCommandObject = {
  __typename?: 'PostSubmitCommandObject';
  createFundraisingHandler?: Maybe<CreatedCampaignsObject>;
};

export type PostSubmitCommandObjectD908PHmf = {
  __typename?: 'PostSubmitCommandObjectD908PHmf';
  error?: Maybe<PostSubmitCommandObjectD908PHmfErrorObject>;
  object?: Maybe<PostSubmitCommandObject>;
};

export type PostSubmitCommandObjectD908PHmfErrorObject = {
  __typename?: 'PostSubmitCommandObjectD908PHmfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type PreviewReceiptParams = {
  amount?: InputMaybe<Scalars['Float']>;
  eligibleAmount?: InputMaybe<Scalars['Float']>;
  formType: FormType;
  locale?: InputMaybe<Scalars['String']>;
  rateName?: InputMaybe<Scalars['String']>;
  ticketingName?: InputMaybe<Scalars['String']>;
};

export type PreviewTicketParams = {
  address?: InputMaybe<Scalars['String']>;
  bannerPdf?: InputMaybe<Scalars['String']>;
  bannerUrl?: InputMaybe<Scalars['String']>;
  endUtc?: InputMaybe<Scalars['Date']>;
  eventTimezone?: InputMaybe<Scalars['String']>;
  formTitle?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
  rateTitle?: InputMaybe<Scalars['String']>;
  startUtc?: InputMaybe<Scalars['Date']>;
  ticketingColor?: InputMaybe<Scalars['String']>;
};

export type ProcessBalanceTransferInput = {
  offsetDay: Scalars['Float'];
};

export type ProductBidObject = {
  __typename?: 'ProductBidObject';
  amount: Scalars['Int'];
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
  rate?: Maybe<RateObject>;
  rateId: Scalars['String'];
  status: ProductBidStatus;
};

export type ProductBidObjectrmIZf2rA = {
  __typename?: 'ProductBidObjectrmIZf2rA';
  error?: Maybe<ProductBidObjectrmIZf2rAErrorObject>;
  items?: Maybe<Array<ProductBidObject>>;
};

export type ProductBidObjectrmIZf2rAErrorObject = {
  __typename?: 'ProductBidObjectrmIZf2rAErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductBidStatus {
  Lost = 'Lost',
  Submitted = 'Submitted',
  Won = 'Won'
}

export type ProductDonationObject = {
  __typename?: 'ProductDonationObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  id: Scalars['String'];
  inHonourEmailAddress?: Maybe<Scalars['String']>;
  inHonourEmailBody?: Maybe<Scalars['String']>;
  inHonourName?: Maybe<Scalars['String']>;
  isAnonymous: Scalars['Boolean'];
  isInHonour: Scalars['Boolean'];
  isRecurrent: Scalars['Boolean'];
  organizationId: Scalars['String'];
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sendInHonourEmail: Scalars['Boolean'];
  thankYouComment?: Maybe<Scalars['String']>;
};

export enum ProductDonationRecurrenceInterval {
  Monthly = 'Monthly',
  OneTime = 'OneTime',
  Yearly = 'Yearly'
}

export type ProductDonationResponseObjectErrorObject = {
  __typename?: 'ProductDonationResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketCountsObject = {
  __typename?: 'ProductTicketCountsObject';
  id: Scalars['String'];
  productTicketCheckedIn: Scalars['Int'];
  productTicketTotal: Scalars['Int'];
};

export type ProductTicketCountsObjectKWk98xhR = {
  __typename?: 'ProductTicketCountsObjectKWk98xhR';
  error?: Maybe<ProductTicketCountsObjectKWk98xhRErrorObject>;
  object?: Maybe<ProductTicketCountsObject>;
};

export type ProductTicketCountsObjectKWk98xhRErrorObject = {
  __typename?: 'ProductTicketCountsObjectKWk98xhRErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketListResponseObject = {
  __typename?: 'ProductTicketListResponseObject';
  error?: Maybe<ProductTicketListResponseObjectErrorObject>;
  items?: Maybe<Array<ProductTicketObject>>;
};

export type ProductTicketListResponseObjectErrorObject = {
  __typename?: 'ProductTicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketObject = {
  __typename?: 'ProductTicketObject';
  amount: Scalars['Int'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal?: Maybe<Scalars['Boolean']>;
  command?: Maybe<CommandObject>;
  commandId: Scalars['String'];
  currency: AvailablePaymentCurrency;
  expirationDate?: Maybe<Scalars['Date']>;
  groupTicketId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  status: ProductTicketStatus;
  ticketingIndex?: Maybe<Scalars['Float']>;
  transactionId?: Maybe<Scalars['String']>;
};

export type ProductTicketObjectgbhHErXs = {
  __typename?: 'ProductTicketObjectgbhHErXs';
  error?: Maybe<ProductTicketObjectgbhHErXsErrorObject>;
  object?: Maybe<ProductTicketObject>;
};

export type ProductTicketObjectgbhHErXsErrorObject = {
  __typename?: 'ProductTicketObjectgbhHErXsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ProductTicketResponseObjectErrorObject = {
  __typename?: 'ProductTicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ProductTicketStatus {
  Active = 'Active',
  Canceled = 'Canceled',
  CheckedIn = 'CheckedIn',
  Pending = 'Pending'
}

export type ProductTicketStatusCountPerTicketingPerOccurrenceObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObject';
  cancelledCount: Scalars['String'];
  checkedInCount: Scalars['String'];
  filteredRowsEmails: Array<Scalars['String']>;
  notCheckedInCount: Scalars['String'];
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectwdKnBBsP = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectwdKnBBsP';
  error?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObjectwdKnBBsPErrorObject>;
  object?: Maybe<ProductTicketStatusCountPerTicketingPerOccurrenceObject>;
};

export type ProductTicketStatusCountPerTicketingPerOccurrenceObjectwdKnBBsPErrorObject = {
  __typename?: 'ProductTicketStatusCountPerTicketingPerOccurrenceObjectwdKnBBsPErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  checkIfResetPasswordIsValid: Boolean5bNBbnQm;
  defaultQuery: Scalars['Boolean'];
  doesOrganizationHaveProductBids: BooleanLksxJsdk;
  donationForm: DonationFormObjectSepqsRs9;
  donationFormWithRestrictedAccess: DonationFormObjectQea1E4Co;
  fetchAndSetSkipAIChat: BooleanLxMut2Wl;
  generateCheckinManagerUrl: StringZjBQhSn7;
  getActiveTicketingOccurrenceIds: String5tQrqfGp;
  getAggregateProductTicketStatusCountPerTicketingPerOccurrence: ProductTicketStatusCountPerTicketingPerOccurrenceObjectwdKnBBsP;
  getAggregateRateFieldsByCommand: AggregateRateFieldsObject1BPcp4bo;
  getApplicationInitialStatus: ApplicationInitialStatusResponseObject;
  getBalanceTransactionDetails: GetBalanceTransactionDetailsObjectTdVrCbju;
  getCampaignFormsCount: NumberUu07ugP9;
  getCampaignParticipantContacts: ContactObjectfEaWvCdv;
  getCampaignSubFormsCount: NumberResponseObject;
  getCampaignWithVolume?: Maybe<DonationFormResponseObject>;
  getCardVolume: CardVolumeResponseObject;
  getCommandAnswers: AnswerObjectvmdIiP4v;
  getContactCountByTag: NumberResponseObject;
  getContactDetails: ContactObjectMrhe41ss;
  getContactKeyInfo: ContactKeyInfoObjectaAxNuLFa;
  getContactsFormRecipientIds: ContactObjectnJCeKu6g;
  getCurrentUser?: Maybe<GetCurrentUserObject>;
  getCurrentlyActiveOrganizations: CurrentlyActiveOrganizationResponseListObject;
  getDiscount: DiscountResponseObject;
  getDonationFormForManualDonation: DonationFormObjectf8pxb0x8;
  getDonationFormQuestions: QuestionListResponseObject;
  getDonationFormWithFundraiserAccess: DonationFormObjectbWuXwwNh;
  getDonationFormsFromCampaign: GetDonationFormsFromCampaignObjectqMtYyH8r;
  getDonationFormsList: DonationFormObjectzGBnMmSa;
  getDonorVolume: DonorVolumeListResponseObject;
  getEmailDelivery: EmailDeliveryObjectT9soTe6R;
  getEmailTemplates: EmailTemplateResponseListObject;
  getEmailsCountByStatus: EmailsCountByStatusTableListResponseObject;
  getEphemeralKey: GetEphemeralKeyObjectd4Ei6dCn;
  getFirstTransactionCreatedAtUtc: GetFirstTransactionCreatedAtUtcResponseObjectMqkjiHyu;
  getFormVolume: NumberJlWeqpBr;
  getFrontendCurrentUser: GetCurrentUserFrontendObjectNaucUn67;
  getFundraisingOrganization: OrganizationResponseObject;
  getHomeGraphData: HomeGraphResponseListObject;
  getImportStatus: ImportUsersGoogleSheetObjectR2iHpHe1;
  getIsActivatedFeatureToggleBadTipperDisclaimer: Booleanh9df5pJ6;
  getIsNewMobileFormCreationFlow: BooleanwSwnPcPl;
  getIsStripeIssuingAllowed: BooleanResponseObject;
  getIssuingCard: GetIssuingCardObjectgPgLa36H;
  getIssuingRewardTransactions: GetIssuingRewardTransactionsObjectGmPrtm6A;
  getMarkerHoverDetails: MarkerHoverDetailsObject;
  getMarkerPositions: ClusterResponseObject;
  getMyReferralOrganizations: MyReferralOrganizationObjectO2UbGxcu;
  getMyReferrerInformation: MyReferrerOrganizationResponseObject;
  getNextReferralTransferAmount: NumberResponseObject;
  getNotes: NoteResponseListObject;
  getOrganizationAdmin: UserObject6cnkquHs;
  getOrganizationEngagementFlags: OrganizationEngagementFlagsObjectPn8be3Zw;
  getOrganizationForms: FrontendFormObjectResponseListObject;
  getOrganizationHasAnyTag: NumberResponseObject;
  getOrganizationListByInput: OrganizationAndOwnerObjectAKkkbKms;
  getOrganizationMembers: UserListResponseObject;
  getOrganizationOwner: UserResponseObject;
  getOrganizationSummary: GetOrganizationSummaryResponseObject;
  getOrganizationTags: TagListResponseObject;
  getPageViewsSummary: PageViewsSummaryResponseObject;
  getPayout: PayoutResponseObject;
  getProductTicketCountsForCheckin: ProductTicketCountsObjectKWk98xhR;
  getProductTicketsCount: NumberResponseObjectXpouhtUy;
  getReferredOrganization: OrganizationResponseObject;
  getScheduledPayout: GetScheduledPayoutObjectGJlROiOd;
  getSentryIssues: SentryIssueListResponseObject;
  getStripeAccountBalance: AccountBalancesObjectgnwwa6Ey;
  getStripeAccountLink: StringvJNh2EhY;
  getStripeCustomAccount: StripeAccountResponseObject;
  getTags: TagListResponseObject;
  getTagsByContactIds: ContactIdWithTagsObjectFxLxt7Sm;
  getTeamMembers: DonationFormObjecteKuGuZpf;
  getTeamWithVolume: DonationFormObjectloXKm9Vn;
  getTeamsFromCampaignId: Array<DonationFormObject>;
  getTicketing: TicketingObjectv87CScqe;
  getTicketingCategory: TicketingCategoryObjectUUgjHHrd;
  getTicketingForManualOrder: TicketingObjectFoRLfNa6;
  getTicketingInfoForCheckin: TicketingObjectoTDm4M9U;
  getTicketingList: TicketingObjectG3Zc1Qix;
  getTicketingReminderData: TicketingFieldObjectZmCfTdRr;
  getTipAndFeeAndRefundTipVolumeFromTransaction: TipNetVolumeResponseObject;
  getTipSuggestions: Array<TipSuggestionObject>;
  getTransaction: TransactionResponseObject;
  getTransactionCountOverTime: TransactionsOverTimeListResponseObject;
  getTransactionWithCommand: TransactionResponseObject;
  getTransactionsForIssuingBalanceTransfer: GetTransactionsForIssuingBalanceTransferObject14WswDh4;
  getUnsubscribePreferences: UnsubscribePreferencesObject0GqqFGw8;
  getVolumeAtDate: NumberResponseObject;
  getWebAnalytics: WebAnalyticsResponseObject;
  getWebhooksCountOverTime: WebhooksOverTimeListResponseObject;
  hasAnOccurrenceBefore: BooleanC85jyPwS;
  hasAnyReceipt: BooleanWn40sBxr;
  hasContacts: BooleanResponseObject;
  hasEmailDelivery: HasEmailDeliveryObjecti2zjocQv;
  isAnExistingUser: BooleanWsrq7bpi;
  listCampaignFormTitles: FormTitleListResponse;
  listCommandsFromByOccurrenceId: CommandObjectmpbqw6jM;
  listDashboardCommandBidRates: BidRateObjectychau0pn;
  listDashboardCommandForms: DashboardCommandFormListResponse;
  listDashboardCommandRates: DashboardCommandRatesListResponseObject;
  listDashboardContactTransactionsCount: DashboardCountObjecti1UpBczX;
  listDashboardTransactionsRates: TransactionRatesWithQuantityObjectkfnpXpcW;
  listDonorDashboardCommandForms: DashboardCommandFormListResponse;
  listDonorDashboardTransactionsCount: IntResponseObject;
  listExpiringTransactionTickets: ProductTicketListResponseObject;
  listFormsForManualCommand: FrontendFormObject5n0EsEKk;
  listOrganizationFormTitles: FormTitleListResponse;
  listQuestionsWithChoices: QuestionListResponseObject;
  listTransactionBids: ProductBidObjectrmIZf2rA;
  listTransactionTickets: ListTransactionTicketsResponse;
  listTransactionsWithFullyCanceledTickets: StringListResponseObject;
  organizationFormsAsMultiselectValues: MultiselectValueObjectM2EgmjFg;
  organizationOccurrencesAsMultiselectValues: MultiselectOccurrenceValueObjectjzakKado;
  organizationTagsAsMultiselectValues: MultiselectValueResponseListObject;
  retrieveCommand: CommandObjectf034Vree;
  retrieveCommandAnswers: AnswerResponseListObject;
  retrieveContact: ContactResponseObject;
  retrieveDashboardTransaction: DashboardTransactionObjectGZmKbmr6;
  retrieveDonorCommand: CommandObject3YbnCwb3;
  retrieveDonorDashboardSubscription: CommandSubscriptionResponseObject;
  retrieveDonorDashboardTransaction: DashboardTransactionObjecta60KBo8K;
  retrieveDonorReceipt: ReceiptResponseObject;
  retrieveForm: FrontendFormObjectResponseObject;
  retrieveOrganization: OrganizationPublicResponseObject;
  retrieveProductTicketAnswers: AnswerObjectliR5yogE;
  retrieveProductTicketAnswersForCheckin: AnswerObjectWrDao3sL;
  retrieveReceipt: ReceiptResponseObject;
  retrieveTransactionReceipts: ReceiptObjectG7VpRuAk;
  retrieveUnsubscribeContactInformations: ContactResponseObject;
  searchNonprofit: NewNonprofitObjectWsYiwEJs;
  searchUnsplashImages: SearchUnsplashImagesResponseObject;
  shouldDisplayPostOccurrenceThankYouEmail: BooleanfVa5Os8F;
  ticketingRatesAsMultiselectValues: MultiselectValueObjectLf4Gy4Gf;
};


export type QueryCheckIfResetPasswordIsValidArgs = {
  checkIfResetPasswordIsValidInput: CheckIfResetPasswordIsValidInput;
};


export type QueryDonationFormArgs = {
  donationFormIdOrUrlPath: Scalars['String'];
};


export type QueryDonationFormWithRestrictedAccessArgs = {
  donationFormIdInput: UuidInput;
};


export type QueryGenerateCheckinManagerUrlArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetActiveTicketingOccurrenceIdsArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateProductTicketStatusCountPerTicketingPerOccurrenceArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetAggregateRateFieldsByCommandArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetBalanceTransactionDetailsArgs = {
  balanceTransactionId: Scalars['String'];
  type: BalanceTransactionType;
};


export type QueryGetCampaignFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignParticipantContactsArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignSubFormsCountArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCampaignWithVolumeArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetCardVolumeArgs = {
  parameters?: InputMaybe<HomeMetricsInput>;
};


export type QueryGetCommandAnswersArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryGetContactCountByTagArgs = {
  tagId: Scalars['String'];
};


export type QueryGetContactDetailsArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactKeyInfoArgs = {
  contactId: Scalars['String'];
};


export type QueryGetContactsFormRecipientIdsArgs = {
  recipientIds: Array<Scalars['String']>;
};


export type QueryGetCurrentlyActiveOrganizationsArgs = {
  input?: InputMaybe<Scalars['String']>;
};


export type QueryGetDiscountArgs = {
  id: Scalars['String'];
};


export type QueryGetDonationFormForManualDonationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormQuestionsArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetDonationFormWithFundraiserAccessArgs = {
  donationFormIdInput: UuidInput;
  token: Scalars['String'];
};


export type QueryGetDonationFormsFromCampaignArgs = {
  campaignId: Scalars['String'];
  filters: DonationFormCampaignFilterInput;
};


export type QueryGetDonationFormsListArgs = {
  withArchived: Scalars['Boolean'];
};


export type QueryGetEmailDeliveryArgs = {
  emailDeliveryIdInput: UuidInput;
};


export type QueryGetEmailsCountByStatusArgs = {
  emailDeliveryId: Scalars['String'];
};


export type QueryGetEphemeralKeyArgs = {
  input: GetEphemeralKeyInput;
};


export type QueryGetFormVolumeArgs = {
  formInput: FormInput;
};


export type QueryGetFundraisingOrganizationArgs = {
  donationFormId: Scalars['String'];
};


export type QueryGetHomeGraphDataArgs = {
  parameters: HomeGraphInput;
};


export type QueryGetImportStatusArgs = {
  importId: Scalars['String'];
};


export type QueryGetMarkerHoverDetailsArgs = {
  getMarkerHoverDetailsInput: GetMarkerHoverDetailsInput;
};


export type QueryGetMarkerPositionsArgs = {
  getMarkerInput: GetMarkersInput;
};


export type QueryGetNotesArgs = {
  userId: Scalars['String'];
};


export type QueryGetOrganizationAdminArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationFormsArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationListByInputArgs = {
  searchOragnizationInput: Scalars['String'];
};


export type QueryGetOrganizationOwnerArgs = {
  organizationId: Scalars['String'];
};


export type QueryGetOrganizationSummaryArgs = {
  locale: Locales;
  organizationId: Scalars['String'];
};


export type QueryGetPageViewsSummaryArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetPayoutArgs = {
  id: Scalars['String'];
};


export type QueryGetProductTicketCountsForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetProductTicketsCountArgs = {
  commandId: Scalars['String'];
};


export type QueryGetReferredOrganizationArgs = {
  params: GetReferredOrganizationInput;
};


export type QueryGetSentryIssuesArgs = {
  slot: SentrySlot;
};


export type QueryGetTagsArgs = {
  userId: Scalars['String'];
};


export type QueryGetTagsByContactIdsArgs = {
  contactIds: Array<Scalars['String']>;
};


export type QueryGetTeamMembersArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamWithVolumeArgs = {
  teamId: Scalars['String'];
};


export type QueryGetTeamsFromCampaignIdArgs = {
  campaignId: Scalars['String'];
};


export type QueryGetTicketingArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingCategoryArgs = {
  params: UuidInput;
};


export type QueryGetTicketingForManualOrderArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
};


export type QueryGetTicketingInfoForCheckinArgs = {
  token: Scalars['String'];
};


export type QueryGetTicketingReminderDataArgs = {
  ticketingId: Scalars['String'];
};


export type QueryGetTipSuggestionsArgs = {
  getTipSuggestionsInput: GetTipSuggestionsInput;
};


export type QueryGetTransactionArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetTransactionWithCommandArgs = {
  id: Scalars['String'];
};


export type QueryGetTransactionsForIssuingBalanceTransferArgs = {
  input: ProcessBalanceTransferInput;
};


export type QueryGetVolumeAtDateArgs = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};


export type QueryGetWebAnalyticsArgs = {
  filters: OverTimeFilterInput;
};


export type QueryGetWebhooksCountOverTimeArgs = {
  filters: OverTimeFilterInput;
};


export type QueryHasAnOccurrenceBeforeArgs = {
  days: Scalars['Int'];
  ticketingId: Scalars['String'];
};


export type QueryIsAnExistingUserArgs = {
  email: Scalars['String'];
};


export type QueryListCampaignFormTitlesArgs = {
  campaignId: Scalars['String'];
};


export type QueryListCommandsFromByOccurrenceIdArgs = {
  occurrenceId: Scalars['String'];
};


export type QueryListDashboardCommandBidRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListDashboardCommandRatesArgs = {
  commandIds: Array<Scalars['String']>;
  locale: Locales;
};


export type QueryListDashboardContactTransactionsCountArgs = {
  userId: Scalars['String'];
};


export type QueryListDashboardTransactionsRatesArgs = {
  locale: Locales;
  transactionIds: Array<Scalars['String']>;
};


export type QueryListDonorDashboardCommandFormsArgs = {
  commandIds: Array<Scalars['String']>;
};


export type QueryListExpiringTransactionTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryListQuestionsWithChoicesArgs = {
  occurrenceId?: InputMaybe<Scalars['String']>;
  questionIds: Array<Scalars['String']>;
};


export type QueryListTransactionBidsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionTicketsArgs = {
  transactionId: Scalars['String'];
};


export type QueryListTransactionsWithFullyCanceledTicketsArgs = {
  transactionIds: Array<Scalars['String']>;
};


export type QueryOrganizationOccurrencesAsMultiselectValuesArgs = {
  formIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryRetrieveCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveCommandAnswersArgs = {
  commandId: Scalars['String'];
};


export type QueryRetrieveContactArgs = {
  contactId: Scalars['String'];
};


export type QueryRetrieveDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorCommandArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardSubscriptionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorDashboardTransactionArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveDonorReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveFormArgs = {
  formInput: FormInput;
};


export type QueryRetrieveOrganizationArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveProductTicketAnswersArgs = {
  productTicketIds: Array<Scalars['String']>;
};


export type QueryRetrieveProductTicketAnswersForCheckinArgs = {
  productTicketIds: Array<Scalars['String']>;
  token: Scalars['String'];
};


export type QueryRetrieveReceiptArgs = {
  id: Scalars['String'];
};


export type QueryRetrieveTransactionReceiptsArgs = {
  transactionId: Scalars['String'];
};


export type QueryRetrieveUnsubscribeContactInformationsArgs = {
  contactId: Scalars['String'];
};


export type QuerySearchNonprofitArgs = {
  filters: SearchNonprofitFilterInput;
  query: Scalars['String'];
};


export type QuerySearchUnsplashImagesArgs = {
  searchUnsplashImagesInput: SearchUnsplashImagesInput;
};


export type QueryShouldDisplayPostOccurrenceThankYouEmailArgs = {
  occurrenceIdInput: UuidInput;
};


export type QueryTicketingRatesAsMultiselectValuesArgs = {
  formId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldInput = {
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  questionId?: InputMaybe<Scalars['String']>;
};

export type QuestionFieldObject = {
  __typename?: 'QuestionFieldObject';
  body: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
};

export type QuestionInput = {
  choices: Array<ChoiceInput>;
  donationFormId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  questionFields: Array<QuestionFieldInput>;
  ratesAssigned?: InputMaybe<Array<Scalars['String']>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  ticketingId?: InputMaybe<Scalars['String']>;
  type: QuestionType;
};

export type QuestionListResponseObject = {
  __typename?: 'QuestionListResponseObject';
  error?: Maybe<QuestionListResponseObjectErrorObject>;
  items?: Maybe<Array<QuestionObject>>;
};

export type QuestionListResponseObjectErrorObject = {
  __typename?: 'QuestionListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type QuestionObject = {
  __typename?: 'QuestionObject';
  answers?: Maybe<Array<AnswerObject>>;
  choices: Array<ChoiceObject>;
  countAlreadyAnswered: Scalars['Float'];
  hasRate: Scalars['Boolean'];
  id: Scalars['String'];
  questionFields: Array<QuestionFieldObject>;
  rateQuestions?: Maybe<Array<RateQuestionObject>>;
  required: Scalars['Boolean'];
  sortIndex: Scalars['Float'];
  type: QuestionType;
};

export enum QuestionType {
  Checkbox = 'Checkbox',
  Date = 'Date',
  Email = 'Email',
  Multiple = 'Multiple',
  MultipleCheckboxes = 'MultipleCheckboxes',
  Name = 'Name',
  PhoneNumber = 'PhoneNumber',
  Simple = 'Simple',
  Utm = 'Utm'
}

export type RateByOccurrenceObject = {
  __typename?: 'RateByOccurrenceObject';
  rateId: Scalars['String'];
  remainingTickets?: Maybe<Scalars['Float']>;
  soldTicketsCount?: Maybe<Scalars['Float']>;
};

export type RateFieldObject = {
  __typename?: 'RateFieldObject';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  title: Scalars['String'];
};

export type RateFieldObjectWithCount = {
  __typename?: 'RateFieldObjectWithCount';
  attendeesByTicket?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  locale: Locales;
  rateFieldCount: Scalars['Float'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type RateListResponseObjectErrorObject = {
  __typename?: 'RateListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RateObject = {
  __typename?: 'RateObject';
  allowAutomaticRenewal?: Maybe<Scalars['Boolean']>;
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  attendeesByTicket?: Maybe<Scalars['Float']>;
  bidIncrement?: Maybe<Scalars['Float']>;
  earlyBirdCloseDate?: Maybe<Scalars['Date']>;
  earlyBirdCloseDateTimezone?: Maybe<Scalars['String']>;
  eligibleAmount?: Maybe<Scalars['Float']>;
  id: Scalars['String'];
  isRateFull?: Maybe<Scalars['Boolean']>;
  maximumToBuy: Scalars['Float'];
  membershipValidityPeriod?: Maybe<MembershipValidityPeriod>;
  membershipValidityPeriodAtDate?: Maybe<Scalars['Date']>;
  minimumToBuy?: Maybe<Scalars['Float']>;
  photoUrls?: Maybe<Array<Scalars['String']>>;
  rateFields?: Maybe<Array<RateFieldObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  sortIndex: Scalars['Float'];
  tickets?: Maybe<Array<TicketObject>>;
};

export type RateQuestionObject = {
  __typename?: 'RateQuestionObject';
  id: Scalars['String'];
  rateId: Scalars['String'];
};

export type RatesWithQuantityObject = {
  __typename?: 'RatesWithQuantityObject';
  quantity: Scalars['Int'];
  rateId: Scalars['String'];
  title: Scalars['String'];
};

export type ReceiptListResponseObjectErrorObject = {
  __typename?: 'ReceiptListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObject = {
  __typename?: 'ReceiptObject';
  address?: Maybe<Scalars['String']>;
  advantageAmount?: Maybe<Scalars['Float']>;
  advantageDescription?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  charityNumber: Scalars['String'];
  city?: Maybe<Scalars['String']>;
  commandId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactObject>;
  country: Scalars['String'];
  createdAtUtc: Scalars['Date'];
  donationDate: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  fileUrl?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  fullName: Scalars['String'];
  id: Scalars['String'];
  isImported: Scalars['Boolean'];
  isSample: Scalars['Boolean'];
  issuingAtUtc: Scalars['Date'];
  lastName?: Maybe<Scalars['String']>;
  organizationAddress: Scalars['String'];
  organizationCity: Scalars['String'];
  organizationCountry: OrganizationCountry;
  organizationName: Scalars['String'];
  organizationPostalCode: Scalars['String'];
  organizationRegion: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  receiptNumber: Scalars['String'];
  region?: Maybe<Scalars['String']>;
  status: ReceiptStatus;
  transactions?: Maybe<Array<TransactionObject>>;
  userId?: Maybe<Scalars['String']>;
};

export type ReceiptObjectG7VpRuAk = {
  __typename?: 'ReceiptObjectG7VpRuAK';
  error?: Maybe<ReceiptObjectG7VpRuAkErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectG7VpRuAkErrorObject = {
  __typename?: 'ReceiptObjectG7VpRuAKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptObjectT3UqEUzT = {
  __typename?: 'ReceiptObjectT3UqEUzT';
  error?: Maybe<ReceiptObjectT3UqEUzTErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptObjectT3UqEUzTErrorObject = {
  __typename?: 'ReceiptObjectT3UqEUzTErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ReceiptResponseObject = {
  __typename?: 'ReceiptResponseObject';
  error?: Maybe<ReceiptResponseObjectErrorObject>;
  object?: Maybe<ReceiptObject>;
};

export type ReceiptResponseObjectErrorObject = {
  __typename?: 'ReceiptResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum ReceiptStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Pending = 'Pending'
}

export type ReceiptTableListResponseObjectErrorObject = {
  __typename?: 'ReceiptTableListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RecipientObject = {
  __typename?: 'RecipientObject';
  emailAddress: Scalars['String'];
  id: Scalars['String'];
};

export type RedirectToUrlObject = {
  __typename?: 'RedirectToUrlObject';
  returnUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum ReferralQuestionChoice {
  AsDonor = 'AsDonor',
  Capterra = 'Capterra',
  Nbctc = 'Nbctc',
  OrganicSearch = 'OrganicSearch',
  Other = 'Other',
  ReferralFromDonor = 'ReferralFromDonor',
  ReferralFromOrganization = 'ReferralFromOrganization',
  SocialNetwork = 'SocialNetwork'
}

export type RefundObject = {
  __typename?: 'RefundObject';
  id: Scalars['String'];
};

export type RefundResponseObject = {
  __typename?: 'RefundResponseObject';
  error?: Maybe<RefundResponseObjectErrorObject>;
  object?: Maybe<RefundObject>;
};

export type RefundResponseObjectErrorObject = {
  __typename?: 'RefundResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  verifyEmailToken: Scalars['String'];
};

export enum ResetPasswordLinkError {
  EmailDoesNotExist = 'EmailDoesNotExist'
}

export type ResetPasswordLinkObject = {
  __typename?: 'ResetPasswordLinkObject';
  user?: Maybe<UserObject>;
};

export type ResetPasswordLinkResponseObject = {
  __typename?: 'ResetPasswordLinkResponseObject';
  error?: Maybe<ResetPasswordLinkResponseObjectErrorObject>;
  object?: Maybe<ResetPasswordLinkObject>;
};

export type ResetPasswordLinkResponseObjectErrorObject = {
  __typename?: 'ResetPasswordLinkResponseObjectErrorObject';
  code?: Maybe<ResetPasswordLinkError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type RetroActiveReferralInput = {
  orgReferredEmail: Scalars['String'];
  orgReferringEmail: Scalars['String'];
};

export type ScheduledPayoutObject = {
  __typename?: 'ScheduledPayoutObject';
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type SearchNonprofitFilterInput = {
  country?: InputMaybe<OrganizationCountry>;
};

export type SearchUnsplashImagesInput = {
  orderBy: UnsplashOrderBy;
  orientation?: InputMaybe<UnsplashOrientation>;
  page: Scalars['Float'];
  query?: InputMaybe<Scalars['String']>;
};

export type SearchUnsplashImagesObject = {
  __typename?: 'SearchUnsplashImagesObject';
  nextPage: Scalars['Float'];
  results: Array<UnsplashImageObject>;
  total: Scalars['Float'];
  total_pages: Scalars['Float'];
};

export type SearchUnsplashImagesResponseObject = {
  __typename?: 'SearchUnsplashImagesResponseObject';
  error?: Maybe<SearchUnsplashImagesResponseObjectErrorObject>;
  object?: Maybe<SearchUnsplashImagesObject>;
};

export type SearchUnsplashImagesResponseObjectErrorObject = {
  __typename?: 'SearchUnsplashImagesResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SendEmailDeliveryError {
  DesignConfigMissing = 'DesignConfigMissing',
  EmailDeliveryNotFound = 'EmailDeliveryNotFound',
  FailedToExportUnlayerHtml = 'FailedToExportUnlayerHtml',
  FailedToGetOwnerEmail = 'FailedToGetOwnerEmail',
  FailedToGetSmartInviteRecipients = 'FailedToGetSmartInviteRecipients',
  LimitExceeded = 'LimitExceeded',
  ManyRecipientsInPreview = 'ManyRecipientsInPreview',
  SenderNoEmail = 'SenderNoEmail',
  SendingFailed = 'SendingFailed',
  StripeAccountNotActive = 'StripeAccountNotActive',
  UnexpectedError = 'UnexpectedError'
}

export type SendEmailDeliveryResponseObjectErrorObject = {
  __typename?: 'SendEmailDeliveryResponseObjectErrorObject';
  code?: Maybe<SendEmailDeliveryError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendMergeUsersRequestResponseObject = {
  __typename?: 'SendMergeUsersRequestResponseObject';
  error?: Maybe<SendMergeUsersRequestResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type SendMergeUsersRequestResponseObjectErrorObject = {
  __typename?: 'SendMergeUsersRequestResponseObjectErrorObject';
  code?: Maybe<MergeUsersError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SendResetPasswordRequestInput = {
  email: Scalars['String'];
  locale: Locales;
};

export type SentryIssueListResponseObject = {
  __typename?: 'SentryIssueListResponseObject';
  error?: Maybe<SentryIssueListResponseObjectErrorObject>;
  items?: Maybe<Array<SentryIssueObject>>;
};

export type SentryIssueListResponseObjectErrorObject = {
  __typename?: 'SentryIssueListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SentryIssueObject = {
  __typename?: 'SentryIssueObject';
  count: Scalars['Float'];
  culprit?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  permalink?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userCount: Scalars['Float'];
  value?: Maybe<Scalars['String']>;
};

export type SentryIssueResponseObjectErrorObject = {
  __typename?: 'SentryIssueResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum SentrySlot {
  OneHour = 'OneHour',
  SevenDays = 'SevenDays',
  SeventyTwoHours = 'SeventyTwoHours',
  TwentyFourHours = 'TwentyFourHours'
}

export type SessionObject = {
  __typename?: 'SessionObject';
  amount?: Maybe<Scalars['Float']>;
  createdAtUtc: Scalars['Date'];
  id: Scalars['String'];
};

export type SessionResponseObject = {
  __typename?: 'SessionResponseObject';
  error?: Maybe<SessionResponseObjectErrorObject>;
  object?: Maybe<SessionObject>;
};

export type SessionResponseObjectErrorObject = {
  __typename?: 'SessionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type SignInAsAdministratorInput = {
  email: Scalars['String'];
  emailTarget: Scalars['String'];
  password: Scalars['String'];
};

export type SignInFrontendInput = {
  email?: InputMaybe<Scalars['String']>;
  googleToken?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  smsCode?: InputMaybe<Scalars['String']>;
  targetOrganizationId?: InputMaybe<Scalars['String']>;
  telemetryId?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};

export type SignInInput = {
  email: Scalars['String'];
  encryptPassword?: InputMaybe<Scalars['String']>;
  locale: Locales;
  organizationId?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type SignUpInput = {
  charityNumber?: InputMaybe<Scalars['String']>;
  country: OrganizationCountry;
  email: Scalars['String'];
  firstName: Scalars['String'];
  googleToken?: InputMaybe<Scalars['String']>;
  hubspotCompanyId?: InputMaybe<Scalars['String']>;
  isMobile?: InputMaybe<Scalars['Boolean']>;
  isNonprofitSearchSuccessful?: InputMaybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locale: Locales;
  npoStarterDealId?: InputMaybe<Scalars['String']>;
  organizationName: Scalars['String'];
  password: Scalars['String'];
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  referredByOrganizationId?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<ReferralQuestionChoice>;
  ssoStytchData?: InputMaybe<SsoStytchData>;
  type?: InputMaybe<OrganizationType>;
  websiteUrl?: InputMaybe<Scalars['String']>;
};

export type SsoStytchData = {
  provider: Scalars['String'];
  stytchOrganizationId: Scalars['String'];
  stytchSessionJwt: Scalars['String'];
  stytchUserId: Scalars['String'];
};

export type String5tQrqfGp = {
  __typename?: 'String5tQRQFGp';
  error?: Maybe<String5tQrqfGpErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type String5tQrqfGpErrorObject = {
  __typename?: 'String5tQRQFGpErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type String6RlPapQt = {
  __typename?: 'String6RlPapQt';
  error?: Maybe<String6RlPapQtErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type String6RlPapQtErrorObject = {
  __typename?: 'String6RlPapQtErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringLdTQt2Sx = {
  __typename?: 'StringLdTQt2Sx';
  error?: Maybe<StringLdTQt2SxErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringLdTQt2SxErrorObject = {
  __typename?: 'StringLdTQt2SxErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringListResponseObject = {
  __typename?: 'StringListResponseObject';
  error?: Maybe<StringListResponseObjectErrorObject>;
  items?: Maybe<Array<Scalars['String']>>;
};

export type StringListResponseObjectErrorObject = {
  __typename?: 'StringListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringNtAz8ud1 = {
  __typename?: 'StringNtAz8ud1';
  error?: Maybe<StringNtAz8ud1ErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringNtAz8ud1ErrorObject = {
  __typename?: 'StringNtAz8ud1ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringResponseObject = {
  __typename?: 'StringResponseObject';
  error?: Maybe<StringResponseObjectErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringResponseObjectErrorObject = {
  __typename?: 'StringResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringVrhNiTsB = {
  __typename?: 'StringVrhNiTsB';
  error?: Maybe<StringVrhNiTsBErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringVrhNiTsBErrorObject = {
  __typename?: 'StringVrhNiTsBErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringZjBQhSn7 = {
  __typename?: 'StringZjBQhSN7';
  error?: Maybe<StringZjBQhSn7ErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringZjBQhSn7ErrorObject = {
  __typename?: 'StringZjBQhSN7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringqjmoC9hM = {
  __typename?: 'StringqjmoC9hM';
  error?: Maybe<StringqjmoC9hMErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringqjmoC9hMErrorObject = {
  __typename?: 'StringqjmoC9hMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringtxiVhXd7 = {
  __typename?: 'StringtxiVhXD7';
  error?: Maybe<StringtxiVhXd7ErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringtxiVhXd7ErrorObject = {
  __typename?: 'StringtxiVhXD7ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StringvJNh2EhY = {
  __typename?: 'StringvJNh2EhY';
  error?: Maybe<StringvJNh2EhYErrorObject>;
  object?: Maybe<Scalars['String']>;
};

export type StringvJNh2EhYErrorObject = {
  __typename?: 'StringvJNh2EhYErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeAccountObject = {
  __typename?: 'StripeAccountObject';
  capabilities: StripeCapabilities;
  country: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  externalAccount?: Maybe<ExternalAccountObject>;
  future_requirements?: Maybe<StripeRequirements>;
  hasAtLeastOnePayout?: Maybe<Scalars['Boolean']>;
  hasOnlyOnePayout?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isBankConnected: Scalars['Boolean'];
  isRejectedByStripe?: Maybe<Scalars['Boolean']>;
  isRejectedByZeffy?: Maybe<Scalars['Boolean']>;
  lastPayout?: Maybe<PayoutObject>;
  payoutInterval: PayoutInterval;
  requirements?: Maybe<StripeRequirements>;
  type?: Maybe<Scalars['String']>;
};

export type StripeAccountResponseObject = {
  __typename?: 'StripeAccountResponseObject';
  error?: Maybe<StripeAccountResponseObjectErrorObject>;
  object?: Maybe<StripeAccountObject>;
};

export type StripeAccountResponseObjectErrorObject = {
  __typename?: 'StripeAccountResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type StripeCapabilities = {
  __typename?: 'StripeCapabilities';
  card_issuing?: Maybe<StripeCapabilityStatus>;
  transfers?: Maybe<StripeCapabilityStatus>;
  treasury?: Maybe<StripeCapabilityStatus>;
};

export enum StripeCapabilityStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Pending = 'Pending'
}

export type StripeCustomerObject = {
  __typename?: 'StripeCustomerObject';
  country: OrganizationCountry;
  id: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  userId: Scalars['String'];
};

export enum StripePayoutStatus {
  canceled = 'canceled',
  failed = 'failed',
  inTransit = 'inTransit',
  paid = 'paid',
  pending = 'pending'
}

export type StripeRequirements = {
  __typename?: 'StripeRequirements';
  current_deadline?: Maybe<Scalars['Float']>;
  currently_due?: Maybe<Array<Scalars['String']>>;
  pending_verification?: Maybe<Array<Scalars['String']>>;
};

export enum SubscriptionRecurrenceInterval {
  Monthly = 'Monthly',
  Yearly = 'Yearly'
}

export enum SubscriptionStatus {
  Cancelled = 'Cancelled',
  InProgress = 'InProgress'
}

export type SwitchOrgAsAdministratorInput = {
  emailTarget: Scalars['String'];
};

export type TagListResponseObject = {
  __typename?: 'TagListResponseObject';
  error?: Maybe<TagListResponseObjectErrorObject>;
  items?: Maybe<Array<TagObject>>;
};

export type TagListResponseObjectErrorObject = {
  __typename?: 'TagListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TagObject = {
  __typename?: 'TagObject';
  id: Scalars['String'];
  value: Scalars['String'];
};

export type TicketListResponseObjectErrorObject = {
  __typename?: 'TicketListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketObject = {
  __typename?: 'TicketObject';
  amount: Scalars['Float'];
  annotation?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<AnswerObject>>;
  automaticRenewal: Scalars['Boolean'];
  cancelled: Scalars['Boolean'];
  expirationDate?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  order: OrderObject;
  orderId: Scalars['String'];
  rate: RateObject;
  rateId: Scalars['String'];
  scanDates?: Maybe<Array<Scalars['Date']>>;
  ticketingIndex?: Maybe<Scalars['Float']>;
};

export type TicketResponseObjectErrorObject = {
  __typename?: 'TicketResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingCategoryObject = {
  __typename?: 'TicketingCategoryObject';
  formCategory: TicketingFormCategory;
  id: Scalars['String'];
};

export type TicketingCategoryObjectUUgjHHrd = {
  __typename?: 'TicketingCategoryObjectUUgjHHrd';
  error?: Maybe<TicketingCategoryObjectUUgjHHrdErrorObject>;
  object?: Maybe<TicketingCategoryObject>;
};

export type TicketingCategoryObjectUUgjHHrdErrorObject = {
  __typename?: 'TicketingCategoryObjectUUgjHHrdErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingDetailsRate = {
  __typename?: 'TicketingDetailsRate';
  alreadyBoughtTicketsCount?: Maybe<Scalars['Float']>;
  fields: Array<RateFieldObject>;
  id: Scalars['String'];
  seats?: Maybe<Scalars['Int']>;
};

export type TicketingDonationFormObject = {
  __typename?: 'TicketingDonationFormObject';
  allowFundraiserCreation?: Maybe<Scalars['Boolean']>;
  allowTeamCreation?: Maybe<Scalars['Boolean']>;
  campaignId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  registrationIncreaseThermometer: Scalars['Boolean'];
};

export type TicketingFeatureFlagObject = {
  __typename?: 'TicketingFeatureFlagObject';
  isPaymentByPadAllowed: Scalars['Boolean'];
};

export type TicketingFieldObject = {
  __typename?: 'TicketingFieldObject';
  bidLoserEmailContent?: Maybe<Scalars['String']>;
  bidLoserEmailObject?: Maybe<Scalars['String']>;
  chequeDescription?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  emailAttachmentUrl?: Maybe<Scalars['String']>;
  emailContent: Scalars['String'];
  emailObject: Scalars['String'];
  id: Scalars['String'];
  locale: Locales;
  offlinePaymentWording?: Maybe<Scalars['String']>;
  postEventBody?: Maybe<Scalars['String']>;
  postEventSubject?: Maybe<Scalars['String']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  reminderBody?: Maybe<Scalars['String']>;
  reminderSubject?: Maybe<Scalars['String']>;
  sanitizedDescription?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type TicketingFieldObjectZmCfTdRr = {
  __typename?: 'TicketingFieldObjectZmCFTdRr';
  error?: Maybe<TicketingFieldObjectZmCfTdRrErrorObject>;
  object?: Maybe<TicketingFieldObject>;
};

export type TicketingFieldObjectZmCfTdRrErrorObject = {
  __typename?: 'TicketingFieldObjectZmCFTdRrErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingFieldResponseObjectErrorObject = {
  __typename?: 'TicketingFieldResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum TicketingFormCategory {
  Auction = 'Auction',
  Custom = 'Custom',
  Event = 'Event',
  Lottery = 'Lottery',
  Membership = 'Membership',
  MembershipV2 = 'MembershipV2',
  Shop = 'Shop'
}

export type TicketingListResponseObjectErrorObject = {
  __typename?: 'TicketingListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObject = {
  __typename?: 'TicketingObject';
  address?: Maybe<Scalars['String']>;
  allowCheque: Scalars['Boolean'];
  askForAddress: Scalars['Boolean'];
  auctionSoldDate?: Maybe<Scalars['Date']>;
  bannerPdfUrl?: Maybe<Scalars['String']>;
  bannerUrl?: Maybe<Scalars['String']>;
  bannerVideoUrl?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  color: Scalars['String'];
  coordinates: CoordinatesObject;
  discounts?: Maybe<Array<DiscountObject>>;
  displayAddressQuestion: Scalars['Boolean'];
  donationForm?: Maybe<TicketingDonationFormObject>;
  eventFollowupEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailContentId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryId?: Maybe<Scalars['String']>;
  eventInvitationEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  eventReminderEmailContentId?: Maybe<Scalars['String']>;
  eventTimezone?: Maybe<Scalars['String']>;
  extraDonation: Scalars['Boolean'];
  formCategory: TicketingFormCategory;
  generateExtraDonationReceipt: Scalars['Boolean'];
  generateQrCode: Scalars['Boolean'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isTicketingFull: Scalars['Boolean'];
  liveFormEditorStep?: Maybe<Scalars['String']>;
  locale: Locales;
  logoUrl?: Maybe<Scalars['String']>;
  moneyGathered?: Maybe<Scalars['Float']>;
  notificationEmails?: Maybe<Scalars['NotificationEmailsScalar']>;
  occurrences?: Maybe<Array<TicketingOccurrenceObject>>;
  occurrencesWithRates?: Maybe<Array<OccurrenceWithRatesObject>>;
  orders?: Maybe<Array<OrderObject>>;
  organization?: Maybe<OrganizationObject>;
  organizationId?: Maybe<Scalars['String']>;
  path: Scalars['String'];
  postEventEmailContentId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryId?: Maybe<Scalars['String']>;
  postEventEmailDeliveryScheduledFor?: Maybe<Scalars['Date']>;
  postEventSendDateOffset?: Maybe<Scalars['Float']>;
  postTransactionUrl?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<QuestionObject>>;
  rates?: Maybe<Array<RateObject>>;
  recurrences?: Maybe<Array<TicketingRecurrenceObject>>;
  remainingTickets?: Maybe<Scalars['Float']>;
  reminderSendDateOffset?: Maybe<Scalars['Float']>;
  seats?: Maybe<Scalars['Float']>;
  soldTicketsCount: Scalars['Float'];
  status?: Maybe<FormStatus>;
  stripeProductId?: Maybe<Scalars['String']>;
  thermometerTarget?: Maybe<Scalars['Float']>;
  ticketingFeatureFlagTreatment?: Maybe<TicketingFeatureFlagObject>;
  ticketingFields: Array<TicketingFieldObject>;
  withoutMap: Scalars['Boolean'];
};

export type TicketingObjectBoVxggEn = {
  __typename?: 'TicketingObjectBoVXGGEn';
  error?: Maybe<TicketingObjectBoVxggEnErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectBoVxggEnErrorObject = {
  __typename?: 'TicketingObjectBoVXGGEnErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectDmLu9yBy = {
  __typename?: 'TicketingObjectDmLU9yBy';
  error?: Maybe<TicketingObjectDmLu9yByErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectDmLu9yByErrorObject = {
  __typename?: 'TicketingObjectDmLU9yByErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectFoRLfNa6 = {
  __typename?: 'TicketingObjectFoRLfNa6';
  error?: Maybe<TicketingObjectFoRLfNa6ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectFoRLfNa6ErrorObject = {
  __typename?: 'TicketingObjectFoRLfNa6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectG3Zc1Qix = {
  __typename?: 'TicketingObjectG3ZC1QIX';
  error?: Maybe<TicketingObjectG3Zc1QixErrorObject>;
  items?: Maybe<Array<TicketingObject>>;
};

export type TicketingObjectG3Zc1QixErrorObject = {
  __typename?: 'TicketingObjectG3ZC1QIXErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectPtVpH1Qj = {
  __typename?: 'TicketingObjectPTVpH1Qj';
  error?: Maybe<TicketingObjectPtVpH1QjErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectPtVpH1QjErrorObject = {
  __typename?: 'TicketingObjectPTVpH1QjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectgKfUdqtj = {
  __typename?: 'TicketingObjectgKfUdqtj';
  error?: Maybe<TicketingObjectgKfUdqtjErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectgKfUdqtjErrorObject = {
  __typename?: 'TicketingObjectgKfUdqtjErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectndLe5Xbm = {
  __typename?: 'TicketingObjectndLE5XBM';
  error?: Maybe<TicketingObjectndLe5XbmErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectndLe5XbmErrorObject = {
  __typename?: 'TicketingObjectndLE5XBMErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectoTDm4M9U = {
  __typename?: 'TicketingObjectoTDm4M9U';
  error?: Maybe<TicketingObjectoTDm4M9UErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectoTDm4M9UErrorObject = {
  __typename?: 'TicketingObjectoTDm4M9UErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjecttbAv9Pi9 = {
  __typename?: 'TicketingObjecttbAV9PI9';
  error?: Maybe<TicketingObjecttbAv9Pi9ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjecttbAv9Pi9ErrorObject = {
  __typename?: 'TicketingObjecttbAV9PI9ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectv87CScqe = {
  __typename?: 'TicketingObjectv87CScqe';
  error?: Maybe<TicketingObjectv87CScqeErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectv87CScqeErrorObject = {
  __typename?: 'TicketingObjectv87CScqeErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingObjectvKFvsdP4 = {
  __typename?: 'TicketingObjectvKFvsdP4';
  error?: Maybe<TicketingObjectvKFvsdP4ErrorObject>;
  object?: Maybe<TicketingObject>;
};

export type TicketingObjectvKFvsdP4ErrorObject = {
  __typename?: 'TicketingObjectvKFvsdP4ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingOccurrenceObject = {
  __typename?: 'TicketingOccurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  closed?: Maybe<Scalars['Boolean']>;
  endUtc: Scalars['Date'];
  eventReminderEmailDelivery?: Maybe<EmailDeliveryObject>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  startUtc: Scalars['Date'];
  ticketingId: Scalars['String'];
};

export type TicketingPaymentIntentInput = {
  amount: Scalars['Float'];
  commandId?: InputMaybe<Scalars['String']>;
  connectedAccountId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  ticketingId: Scalars['String'];
  tip: Scalars['Float'];
  ttpoi?: InputMaybe<Scalars['Boolean']>;
};

export type TicketingRecurrenceInput = {
  closeDate?: InputMaybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  repeatUntilDate?: InputMaybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  type: TicketingRecurrenceType;
};

export type TicketingRecurrenceObject = {
  __typename?: 'TicketingRecurrenceObject';
  closeDate?: Maybe<Scalars['Date']>;
  endUtc: Scalars['Date'];
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  repeatUntilDate?: Maybe<Scalars['Date']>;
  startUtc: Scalars['Date'];
  ticketingId?: Maybe<Scalars['String']>;
  type: TicketingRecurrenceType;
};

export enum TicketingRecurrenceType {
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  WeekDays = 'WeekDays',
  Weekly = 'Weekly'
}

export type TicketingResponseObjectErrorObject = {
  __typename?: 'TicketingResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TicketingSummaryObject = {
  __typename?: 'TicketingSummaryObject';
  description: Scalars['String'];
  end?: Maybe<Scalars['Date']>;
  id: Scalars['String'];
  start?: Maybe<Scalars['Date']>;
};

export enum TipModelExperience {
  Model1 = 'Model1',
  ModelA = 'ModelA',
  ModelB = 'ModelB',
  ModelC = 'ModelC',
  ModelD = 'ModelD',
  ModelE = 'ModelE'
}

export type TipNetVolumeObject = {
  __typename?: 'TipNetVolumeObject';
  volumeFee: Scalars['Float'];
  volumeTip: Scalars['Float'];
};

export type TipNetVolumeResponseObject = {
  __typename?: 'TipNetVolumeResponseObject';
  error?: Maybe<TipNetVolumeResponseObjectErrorObject>;
  object?: Maybe<TipNetVolumeObject>;
};

export type TipNetVolumeResponseObjectErrorObject = {
  __typename?: 'TipNetVolumeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TipSuggestionObject = {
  __typename?: 'TipSuggestionObject';
  amount: Scalars['Int'];
  percentage: Scalars['Int'];
};

export type TipSuggestionResponseListObjectErrorObject = {
  __typename?: 'TipSuggestionResponseListObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionExportFilter = {
  endDate?: InputMaybe<Scalars['Date']>;
  startDate?: InputMaybe<Scalars['Date']>;
  stripePayoutId?: InputMaybe<Scalars['String']>;
};

export type TransactionObject = {
  __typename?: 'TransactionObject';
  command?: Maybe<CommandObject>;
  contact?: Maybe<ContactObject>;
  country: OrganizationCountry;
  createdAtUtc: Scalars['Date'];
  currency: AvailablePaymentCurrency;
  donation?: Maybe<DonationObject>;
  donationId?: Maybe<Scalars['String']>;
  hasBeenThanked: Scalars['Boolean'];
  hasRadarWarning: Scalars['Boolean'];
  id: Scalars['String'];
  inProgressRefundAmount: Scalars['Int'];
  isImported: Scalars['Boolean'];
  isSample?: Maybe<Scalars['Boolean']>;
  last4?: Maybe<Scalars['String']>;
  nextPaymentAmount?: Maybe<Scalars['Int']>;
  order?: Maybe<OrderObject>;
  orderId?: Maybe<Scalars['String']>;
  organization?: Maybe<OrganizationObject>;
  paymentMethod: PaymentMethod;
  receipt?: Maybe<ReceiptObject>;
  receiptId?: Maybe<Scalars['String']>;
  refundCreatedAtUtc?: Maybe<Scalars['Date']>;
  refundableAmount?: Maybe<Scalars['Int']>;
  refundableTipAmount?: Maybe<Scalars['Int']>;
  refundedAmount?: Maybe<Scalars['Int']>;
  refundedTipAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<PaymentStatus>;
  stripeChargeId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  tip: Scalars['Int'];
  totalAmount: Scalars['Int'];
  transferGroup?: Maybe<Scalars['String']>;
  transferrableAmount: Scalars['Float'];
};

export type TransactionObject4PaaEapc = {
  __typename?: 'TransactionObject4PaaEAPC';
  error?: Maybe<TransactionObject4PaaEapcErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObject4PaaEapcErrorObject = {
  __typename?: 'TransactionObject4PaaEAPCErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionObjecthEGc9Bs6 = {
  __typename?: 'TransactionObjecthEGc9Bs6';
  error?: Maybe<TransactionObjecthEGc9Bs6ErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionObjecthEGc9Bs6ErrorObject = {
  __typename?: 'TransactionObjecthEGc9Bs6ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionRatesWithQuantityObject = {
  __typename?: 'TransactionRatesWithQuantityObject';
  id: Scalars['String'];
  rates?: Maybe<Array<RatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectkfnpXpcW = {
  __typename?: 'TransactionRatesWithQuantityObjectkfnpXpcW';
  error?: Maybe<TransactionRatesWithQuantityObjectkfnpXpcWErrorObject>;
  items?: Maybe<Array<TransactionRatesWithQuantityObject>>;
};

export type TransactionRatesWithQuantityObjectkfnpXpcWErrorObject = {
  __typename?: 'TransactionRatesWithQuantityObjectkfnpXpcWErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionResponseObject = {
  __typename?: 'TransactionResponseObject';
  error?: Maybe<TransactionResponseObjectErrorObject>;
  object?: Maybe<TransactionObject>;
};

export type TransactionResponseObjectErrorObject = {
  __typename?: 'TransactionResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeListResponseObject = {
  __typename?: 'TransactionsOverTimeListResponseObject';
  error?: Maybe<TransactionsOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<TransactionsOverTimeObject>>;
};

export type TransactionsOverTimeListResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TransactionsOverTimeObject = {
  __typename?: 'TransactionsOverTimeObject';
  created: Scalars['Float'];
  failed: Scalars['Float'];
  processing: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type TransactionsOverTimeResponseObjectErrorObject = {
  __typename?: 'TransactionsOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type TranslationsDonationFormFieldInput = {
  chequeDescription?: InputMaybe<Scalars['String']>;
  choiceFields?: InputMaybe<Array<ChoiceFieldInput>>;
  description: Scalars['String'];
  donationFormAmountFields: Array<EditDonationFormAmountTranslationInput>;
  emailAttachmentUrl?: InputMaybe<Scalars['String']>;
  emailContent?: InputMaybe<Scalars['String']>;
  emailObject?: InputMaybe<Scalars['String']>;
  fundraiserEmailBody?: InputMaybe<Scalars['String']>;
  fundraisingRegistrationWording?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  locale: Locales;
  message?: InputMaybe<Scalars['String']>;
  questionsFields?: InputMaybe<Array<QuestionFieldInput>>;
  ticketingRegistrationWording?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  transferDescription?: InputMaybe<Scalars['String']>;
};

export type UnsplashImageObject = {
  __typename?: 'UnsplashImageObject';
  alt_description?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  height: Scalars['Float'];
  id: Scalars['String'];
  links: UnsplashLinks;
  urls: UnsplashUrls;
  user: UnsplashUser;
  width: Scalars['Float'];
};

export type UnsplashLinks = {
  __typename?: 'UnsplashLinks';
  download_location: Scalars['String'];
};

export enum UnsplashOrderBy {
  latest = 'latest',
  relevant = 'relevant'
}

export enum UnsplashOrientation {
  landscape = 'landscape',
  portrait = 'portrait',
  squarish = 'squarish'
}

export type UnsplashUrls = {
  __typename?: 'UnsplashUrls';
  full: Scalars['String'];
  raw: Scalars['String'];
  regular: Scalars['String'];
  small: Scalars['String'];
  thumb: Scalars['String'];
};

export type UnsplashUser = {
  __typename?: 'UnsplashUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type UnsubscribePreferenceInput = {
  unsubscribedFromEmailTypes?: InputMaybe<Array<EmailType>>;
};

export type UnsubscribePreferencesObject = {
  __typename?: 'UnsubscribePreferencesObject';
  id: Scalars['String'];
  unsubscribedFromEmailTypes: Array<EmailType>;
};

export type UnsubscribePreferencesObject0GqqFGw8 = {
  __typename?: 'UnsubscribePreferencesObject0GqqFGw8';
  error?: Maybe<UnsubscribePreferencesObject0GqqFGw8ErrorObject>;
  object?: Maybe<UnsubscribePreferencesObject>;
};

export type UnsubscribePreferencesObject0GqqFGw8ErrorObject = {
  __typename?: 'UnsubscribePreferencesObject0GqqFGw8ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdateAdministrativeInformationInput = {
  address?: InputMaybe<Scalars['String']>;
  bankDescriptor?: InputMaybe<Scalars['String']>;
  charityNumber?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  hideFromZeffyMap?: InputMaybe<Scalars['Boolean']>;
  isPoBoxAddress: Scalars['Boolean'];
  logoUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  propublicaCCode?: InputMaybe<Scalars['Float']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  signatureUrl?: InputMaybe<Scalars['String']>;
  type: OrganizationType;
  website?: InputMaybe<Scalars['String']>;
};

export type UpdateAdministrativeMoreInformationInput = {
  category: OrganizationCategory;
  hasFinishZeffyOnboarding?: InputMaybe<Scalars['Boolean']>;
  referralQuestion?: InputMaybe<ReferralQuestionChoice>;
  referralQuestionOrganizationId?: InputMaybe<Scalars['String']>;
  referralQuestionOrganizationName?: InputMaybe<Scalars['String']>;
  referralQuestionOther?: InputMaybe<Scalars['String']>;
};

export type UpdateDonationFormAmountInput = {
  amount: Scalars['Float'];
  donationFormFieldId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isRecurrent: Scalars['Boolean'];
  message?: InputMaybe<Scalars['String']>;
  recurrenceInterval: ProductDonationRecurrenceInterval;
  sortIndex: Scalars['Float'];
};

export type UpdateOrganizationEngagementFlagsInput = {
  engagementAddContactTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactNotesTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactPaymentsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactTagsTour?: InputMaybe<Scalars['Boolean']>;
  engagementContactsBulkTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailRecipientsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailStatsTour?: InputMaybe<Scalars['Boolean']>;
  engagementEmailTemplatesTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentDetailsTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentTour?: InputMaybe<Scalars['Boolean']>;
  engagementPaymentsExportTour?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
};

export type UpdateOrganizationHubspotPropertyInput = {
  property: Scalars['String'];
  value: Scalars['String'];
};

export type UpdatePaymentIntentInput = {
  billedAmount: Scalars['Int'];
  donationId?: InputMaybe<Scalars['String']>;
  orderId?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  stripePaymentIntentId: Scalars['String'];
  transferAmount: Scalars['Int'];
};

export type UpdatePaymentIntentResponse = {
  __typename?: 'UpdatePaymentIntentResponse';
  error?: Maybe<UpdatePaymentIntentResponseErrorObject>;
  object?: Maybe<UpdatePaymentIntentResponseObject>;
};

export type UpdatePaymentIntentResponseErrorObject = {
  __typename?: 'UpdatePaymentIntentResponseErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdatePaymentIntentResponseObject = {
  __typename?: 'UpdatePaymentIntentResponseObject';
  stripePaymentIntentId: Scalars['String'];
};

export type UpdatePayoutInterval = {
  schedule: PayoutInterval;
};

export type UpdatePersonalInformationInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale: Locales;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type UpdatePersonalInformationResponseObject = {
  __typename?: 'UpdatePersonalInformationResponseObject';
  error?: Maybe<UpdatePersonalInformationResponseObjectErrorObject>;
  object?: Maybe<UserObject>;
};

export type UpdatePersonalInformationResponseObjectErrorObject = {
  __typename?: 'UpdatePersonalInformationResponseObjectErrorObject';
  code?: Maybe<EditMyProfileError>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UpdateVolumeThresholdReached = {
  volumeThresholdReached: Scalars['Float'];
};

export type UpsertSessionInput = {
  amount?: InputMaybe<Scalars['Float']>;
  appCodeName: Scalars['String'];
  appName: Scalars['String'];
  appVersion: Scalars['String'];
  defaultSuggestedTip?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['String']>;
  formType?: InputMaybe<FormType>;
  id: Scalars['String'];
  isEmbed: Scalars['Boolean'];
  isSuggestedTipsAmount?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  suggestedTips?: InputMaybe<Array<Scalars['Int']>>;
  tipExperimentValue?: InputMaybe<FeatureFlagValue>;
  url: Scalars['String'];
};

export type UserDetailsObject = {
  __typename?: 'UserDetailsObject';
  firstPaymentDate?: Maybe<Scalars['Date']>;
  lastPaymentDate?: Maybe<Scalars['Date']>;
  totalContribution: Scalars['Float'];
};

export type UserListResponseObject = {
  __typename?: 'UserListResponseObject';
  error?: Maybe<UserListResponseObjectErrorObject>;
  items?: Maybe<Array<UserObject>>;
};

export type UserListResponseObjectErrorObject = {
  __typename?: 'UserListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObject = {
  __typename?: 'UserObject';
  contact?: Maybe<ContactObject>;
  contacts?: Maybe<Array<ContactObject>>;
  deprecatedMongoId?: Maybe<Scalars['String']>;
  details: UserDetailsObject;
  deviceIds?: Maybe<Array<Scalars['String']>>;
  donations?: Maybe<Array<DonationObject>>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  hasPassword: Scalars['Boolean'];
  id: Scalars['String'];
  isAuthenticatedWithGoogleSSO: Scalars['Boolean'];
  isContactOfAnotherOrganization: Scalars['Boolean'];
  isImported: Scalars['Boolean'];
  lastName: Scalars['String'];
  locale: Locales;
  maskedPhoneNumber?: Maybe<Scalars['String']>;
  orders?: Maybe<Array<OrderObject>>;
  organizationGroups?: Maybe<Array<OrganizationGroupObject>>;
  refreshToken?: Maybe<Scalars['String']>;
  stripeCustomers?: Maybe<Array<StripeCustomerObject>>;
  tags?: Maybe<Array<TagObject>>;
};

export type UserObject6cnkquHs = {
  __typename?: 'UserObject6cnkquHs';
  error?: Maybe<UserObject6cnkquHsErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObject6cnkquHsErrorObject = {
  __typename?: 'UserObject6cnkquHsErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectQyOkCm40 = {
  __typename?: 'UserObjectQYOkCm40';
  error?: Maybe<UserObjectQyOkCm40ErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectQyOkCm40ErrorObject = {
  __typename?: 'UserObjectQYOkCm40ErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectbNrxFwOf = {
  __typename?: 'UserObjectbNrxFWOf';
  error?: Maybe<UserObjectbNrxFwOfErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectbNrxFwOfErrorObject = {
  __typename?: 'UserObjectbNrxFWOfErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type UserObjectmItv4Guk = {
  __typename?: 'UserObjectmITV4GUK';
  error?: Maybe<UserObjectmItv4GukErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserObjectmItv4GukErrorObject = {
  __typename?: 'UserObjectmITV4GUKErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserPermissions {
  Bank = 'Bank',
  Contacts = 'Contacts',
  Donation = 'Donation',
  Receipt = 'Receipt',
  Ticketing = 'Ticketing',
  UserManagement = 'UserManagement'
}

export enum UserRegistrationStatus {
  Registered = 'Registered',
  Unknown = 'Unknown'
}

export type UserResponseObject = {
  __typename?: 'UserResponseObject';
  error?: Maybe<UserResponseObjectErrorObject>;
  object?: Maybe<UserObject>;
};

export type UserResponseObjectErrorObject = {
  __typename?: 'UserResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export enum UserRoles {
  Donor = 'Donor',
  Member = 'Member',
  Owner = 'Owner'
}

export type UuidInput = {
  id: Scalars['String'];
};

export type VerifyEmailObject = {
  __typename?: 'VerifyEmailObject';
  accessToken?: Maybe<Scalars['String']>;
  locale?: Maybe<Locales>;
  role?: Maybe<UserRoles>;
};

export type VerifyEmailObjectTjjN5j3w = {
  __typename?: 'VerifyEmailObjectTjjN5j3w';
  error?: Maybe<VerifyEmailObjectTjjN5j3wErrorObject>;
  object?: Maybe<VerifyEmailObject>;
};

export type VerifyEmailObjectTjjN5j3wErrorObject = {
  __typename?: 'VerifyEmailObjectTjjN5j3wErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebAnalyticsItemObject = {
  __typename?: 'WebAnalyticsItemObject';
  connectionTime: Scalars['Float'];
  count: Scalars['Float'];
  dnsTime: Scalars['Float'];
  firstContentfulPaint: Scalars['Float'];
  firstPaint: Scalars['Float'];
  loadEventTime: Scalars['Float'];
  pageLoadTime: Scalars['Float'];
  pageRenderTime: Scalars['Float'];
  requestTime: Scalars['Float'];
  responseTime: Scalars['Float'];
  time: Scalars['String'];
};

export type WebAnalyticsObject = {
  __typename?: 'WebAnalyticsObject';
  data?: Maybe<Array<WebAnalyticsItemObject>>;
};

export type WebAnalyticsResponseObject = {
  __typename?: 'WebAnalyticsResponseObject';
  error?: Maybe<WebAnalyticsResponseObjectErrorObject>;
  object?: Maybe<WebAnalyticsObject>;
};

export type WebAnalyticsResponseObjectErrorObject = {
  __typename?: 'WebAnalyticsResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeListResponseObject = {
  __typename?: 'WebhooksOverTimeListResponseObject';
  error?: Maybe<WebhooksOverTimeListResponseObjectErrorObject>;
  items?: Maybe<Array<WebhooksOverTimeObject>>;
};

export type WebhooksOverTimeListResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeListResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type WebhooksOverTimeObject = {
  __typename?: 'WebhooksOverTimeObject';
  failed: Scalars['Float'];
  queued: Scalars['Float'];
  succeeded: Scalars['Float'];
  time: Scalars['Date'];
};

export type WebhooksOverTimeResponseObjectErrorObject = {
  __typename?: 'WebhooksOverTimeResponseObjectErrorObject';
  code?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  isHandled?: Maybe<Scalars['Boolean']>;
  message: Scalars['String'];
};

export type ZeffyImportObject = {
  __typename?: 'ZeffyImportObject';
  id: Scalars['String'];
};
